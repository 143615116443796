define('ceag-lot3-front/components/formulaire/saisie/objets/i-picture-objet', ['exports', 'ceag-lot3-front/config/environment', 'ceag-lot3-front/misc/image-resize'], function (exports, _environment, _imageResize) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        platform: Ember.inject.service('ember-cordova/platform'),
        store: Ember.inject.service(),
        intl: Ember.inject.service(),

        classNames: ['i_picture-objet'],

        compteurPhoto: null,
        nbPhotoRestante: Ember.computed.alias('compteurPhoto.restant'),
        disablePhoto: Ember.computed.equal('nbPhotoRestante', 0),

        init() {
            this._super(...arguments);

            //On récupère le compteur photo
            let compteurPhoto = this.get('store').peekRecord('compteur', "photo");
            this.set('compteurPhoto', compteurPhoto);
        },

        onUpdate: function () {},

        /**
         * On met à jour notre donnée avec l'image (encodée en base64)
         */
        fileReaderLoad: async function (evt) {

            let maxSize = _environment.default.CEAG.maxImageSize;

            //On redimensionne l'image
            try {
                let imageResizeData = await (0, _imageResize.default)(evt.target.result, maxSize.width, maxSize.height, maxSize.quality);
                this.get('objet').set('donnee_longue', imageResizeData);
                this.get('onUpdate')(imageResizeData);
            } catch (e) {
                console.error(e);
                this.fileReaderError().then(() => {
                    this.resetImage();
                });
            }
        },

        fileReaderError: function () /*evt*/{
            return this.get('confirmationService').alert(this.get('intl').t('misc.image_input.error.titre'), this.get('intl').t('misc.image_input.error.contenu'));
        },

        checkFileType: function (file) {
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                return false;
            }
            return true;
        },

        resetImage: function () {
            this.get('objet').set('donnee_longue', null);
            //Reset le input
            if (!this.get('platform.isCordova')) {
                this.$('input[type="file"]').get(0).value = "";
            }
        },

        actions: {

            fileChange: function () {

                if (this.get('disablePhoto')) {
                    let msg = Ember.String.htmlSafe(this.get('intl').t('misc.jeton_image_alert', { libelle: this.get('compteurPhoto.libelle') }));
                    this.get('confirmationService').alert(this.get('intl').t('misc.jeton_alert_titre'), msg);
                    this.resetImage();
                    return;
                }

                var fileInput = this.$('input[type="file"]').get(0);
                if (fileInput.files.length) {
                    var file = fileInput.files[0];

                    if (this.checkFileType(file)) {

                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = this.fileReaderLoad.bind(this);
                        reader.onerror = this.fileReaderError.bind(this);
                    } else {
                        this.get('objet').set('donnee_longue', null);
                        this.get('onUpdate')(null);
                        return this.get('confirmationService').alert(this.get('intl').t('misc.image_input.format.titre'), this.get('intl').t('misc.image_input.format.contenu'));
                    }
                } else {
                    this.get('objet').set('donnee_longue', null);
                    this.get('onUpdate')(null);
                }
            },

            resetImageAction: function () {
                this.resetImage();
            },

            mobileCameraAction: function (type) {

                if (this.get('disablePhoto')) {
                    this.resetImage();
                    let msg = Ember.String.htmlSafe(this.get('intl').t('misc.jeton_image_alert', { libelle: this.get('compteurPhoto.libelle') }));
                    return this.get('confirmationService').alert(this.get('intl').t('misc.jeton_alert_titre'), msg);
                }

                if (this.get('disablePhoto')) {
                    let msg = Ember.String.htmlSafe(this.get('intl').t('misc.jeton_image_alert', { libelle: this.get('compteurPhoto.libelle') }));
                    this.get('confirmationService').alert(this.get('intl').t('misc.jeton_alert_titre'), msg);
                    this.resetImage();
                    return;
                }

                return new Ember.RSVP.Promise((resolve, reject) => {

                    /*global Camera*/

                    let maxSize = _environment.default.CEAG.maxImageSize;

                    let options = {
                        quality: maxSize.quality * 100,
                        targetWidth: maxSize.width,
                        targetHeight: maxSize.height,

                        destinationType: Camera.DestinationType.DATA_URL,
                        sourceType: type == 'library' ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA, //Camera
                        encodingType: Camera.EncodingType.JPEG,
                        mediaType: Camera.MediaType.PICTURE,
                        allowEdit: false,
                        correctOrientation: true //Corrects Android orientation quirks
                    };

                    navigator.camera.getPicture(imageUri => {
                        this.get('objet').set('donnee_longue', "data:image/jpeg;base64," + imageUri);
                        this.get('onUpdate')("data:image/jpeg;base64," + imageUri);
                        resolve();
                    }, error => {
                        this.get('objet').set('donnee_longue', null);
                        this.get('onUpdate')(null);
                        this.get('confirmationService').alert(this.get('intl').t('misc.image_input.camera.titre'), this.get('intl').t('misc.image_input.camera.contenu', { type }));
                        reject(error);
                    }, options);
                });
            }

        }
    });
});