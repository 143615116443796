define('ceag-lot3-front/mixins/custom-loading-slider', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        loadingSlider: Ember.inject.service(),

        actions: {

            /**
             * Modification du mixins de base cas parfois le loader est toujours actif alors que le chargement est terminé.
             * ("loadingSliderService.endLoading" jamais appelé)
             * @author jmestres
             */
            loading(transition) {

                let loadingSliderService = this.get('loadingSlider');
                loadingSliderService.startLoading();
                transition.promise.finally(() => {
                    loadingSliderService.endLoading();
                });

                if (this.get('bubbleLoadingSlider')) {
                    return true;
                }
            }
        }
    });
});