define('ceag-lot3-front/components/abonnement-liste', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_pendingObject.default, {

        actions: {

            editMail(abonnement) {
                abonnement.set('isEditing', true);
            },

            cancelMailEdit(abonnement) {
                abonnement.set('isEditing', false);
                abonnement.rollbackAttributes();
            },

            saveMail(abonnement) {

                if (!abonnement.get('validations.isValid')) {
                    return;
                }

                this.goPending(abonnement.save()).then(() => {
                    abonnement.set('isEditing', false);
                }).catch(reason => {
                    console.error('Une erreur est survenue lors de la sauvegarde', reason);
                });
            }
        }

    });
});