define('ceag-lot3-front/services/task-runner', ['exports', 'offline-store-sync/services/task-runner'], function (exports, _taskRunner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _taskRunner.default;
    }
  });
});