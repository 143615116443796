define('ceag-lot3-front/routes/device', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service(),

        beforeModel: function () {
            this._super(...arguments);

            //As t-on un token de device ?
            let tokenDevice = this.get('offlineInfosService.tokenDevice');

            if (!Ember.isEmpty(tokenDevice)) {
                //Oui => redirige vers 'index'
                this.transitionTo('index');
            }

            //Non popup d'alerte et déclaration du device.
        }

    });
});