define('ceag-lot3-front/controllers/in/formulaire/saisie', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.myQueryParams = undefined;
    const myQueryParams = exports.myQueryParams = new _emberParachute.default({

        autocomplete_from_saisie: {
            defaultValue: null,
            refresh: true,
            replace: false
        }
    });
    // import { alias } from '@ember/object/computed';

    exports.default = Ember.Controller.extend(myQueryParams.Mixin, {

        actions: {

            resetNewSaisie: function () {
                this.get('model.saisieFormulaire').rollbackAttributes();
            }
        }
    });
});