define('ceag-lot3-front/components/saisie/demande-reedition-popup', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_pendingObject.default, {

        actions: {
            onSubmitAction: function () {
                this.goPending(this.get('onSubmit')());
            }
        }
    });
});