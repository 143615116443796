define("ceag-lot3-front/adapters/offline/application", ["exports", "offline-store-sync/adapters/offline", "ember-cli-uuid"], function (exports, _offline, _emberCliUuid) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offline.default.extend({

        generateIdForRecord() {
            return (0, _emberCliUuid.uuid)();
        },

        ajaxCall() {
            return null;
        },

        /**
         * Vérifie qu'une propriété d'un model correspond ou non à la valeur et propriété de recherche ("query") demandée
         * @param  {Model} record le model à tester
         * @param  {String} queryProperty La propriété
         * @param  {Mixed} queryValue    La valeur de "filtre"
         * @return {boolean} true si la propriété du model match, false sinon.
         * @author jmestres
         */
        modelPropertyMatchQueryValue(record, queryProperty, queryValue) {
            let isMatching = false;

            //Demande de filtre sur une propriété inconnue ca ne matche pas
            if (record[queryProperty] == undefined) {
                return false;
            }

            if (queryValue instanceof RegExp) {
                isMatching = queryValue.test(record[queryProperty]);
            } else if (queryValue instanceof Array) {
                //Plusieurs valeur, on fait l'équivalent d'un "IN (xx,yy,zzz)"
                isMatching = queryValue.indexOf(record[queryProperty]) >= 0;
            } else {
                isMatching = record[queryProperty] === queryValue;
            }

            return isMatching;
        },

        /**
         * Vérifie qu'un model donné correspond ou non à la "query" demandé
         * @param  {Model} record le model à tester
         * @param  {Objet} query  Les critère de la recherche(requête)
         * @return {boolean} true si le model match, false sinon.
         * @author jmestres
         */
        modelMatchQuery(record, query) {

            let isMatching = true;
            //On parcours chaque propriété de la query et on vérifie si match avec le record demandé
            for (let queryProperty in query) {

                if (!this.modelPropertyMatchQueryValue(record, queryProperty, query[queryProperty])) {
                    isMatching = false;
                    break; // all criteria should pass
                }
            }

            return isMatching;
        },

        /**
         * Surcharge de la méthode pour permettre des critère de recherche plus précise et spécifique dans chaque adapter
         * @param  {Array} records     Tableau de model à filtrer
         * @param  {Objet} query      Les critère de la recherche(requête)
         * @param  {boolean} singleMatch Si true, ne retourne que le premier enregistrement
         * @return {Array} les objets filtrés
         * @author jmestres
         */
        _query(records, query, singleMatch) {
            const results = [];

            for (let id in records) {

                let isMatching = this.modelMatchQuery(records[id], query);

                if (isMatching) {
                    results.push(records[id]);
                }

                if (singleMatch) {
                    return results[0];
                }
            }

            return results;
        }
    });
});