define('ceag-lot3-front/controllers/in/formulaire/action', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        intl: Ember.inject.service(),

        connectionStatus: Ember.inject.service('connection-status'),
        isOffline: Ember.computed.alias('connectionStatus.isOffline'),

        index: 0,

        actions: {
            setIndex: function (index) {
                this.set('index', index);
            },

            creationAction: function () {

                let intl = this.get('intl');

                if (!this.get('model.abonnements').filterBy('hasExpire', false).length) {
                    return this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
                }

                if (this.get('model.autoRemplissage') === true) {

                    this.get('confirmationService').confirm(intl.t('saisie.use_archive.titre'), intl.t('saisie.use_archive.contenu'), {
                        okLabel: intl.t('global.oui'),
                        cancelLabel: intl.t('global.non')
                    })
                    //Oui
                    .then(() => {
                        this.transitionToRoute('in.saisies', 'form', this.get('model.id'), 'autocom');
                    })
                    //non
                    .catch(() => {
                        this.transitionToRoute('in.formulaire.saisie', 'new', { queryParams: { autocomplete_from_saisie: null } });
                    });
                } else {
                    this.transitionToRoute('in.formulaire.saisie', 'new', { queryParams: { autocomplete_from_saisie: null } });
                }
            }

        }

    });
});