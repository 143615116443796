define('ceag-lot3-front/helpers/version-widget', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function versionBlock(formulaireVersion) {

        //On enlève les "0" dans le numéro de version
        var versionTab = formulaireVersion.get('version').split('.').map(n => parseInt(n));
        var versionFormatee = `${versionTab[0]}.${versionTab[1]}.${versionTab[2]}`;

        //Le span en display none et utilisé pour le "title" de la page HTML, ne pas enlever....
        return Ember.String.htmlSafe(`<span style="display: none;"> - </span><span class="form_version">Version : ${versionFormatee}</span>`);
    }

    exports.default = versionBlock;
});