define('ceag-lot3-front/models/questionnaire-version', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        description: _emberData.default.attr('string'),

        destinataire: _emberData.default.attr('string'),
        //type: DS.attr('string'),

        categorie: _emberData.default.belongsTo('categorie'),

        questionnaire: _emberData.default.belongsTo('questionnaire'),
        choix_questionnaire: _emberData.default.hasMany('choix-questionnaire'),

        version: _emberData.default.attr('string'),
        statut: _emberData.default.attr('string'),

        date_creation: _emberData.default.attr('date'),
        date_mise_a_jour: _emberData.default.attr('date')
    });
});