define('ceag-lot3-front/initializers/confirmation-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  /**
   * Inject le service de "popup de confirmation" dans les routes, controller et composant de l'application
   * @author jmestres
   */

  function initialize(application) {
    application.inject('route', 'confirmationService', 'service:confirmation');
    application.inject('controller', 'confirmationService', 'service:confirmation');
    application.inject('component', 'confirmationService', 'service:confirmation');
  }

  exports.default = {
    name: 'confirmation-service',
    initialize: initialize
  };
});