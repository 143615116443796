define('ceag-lot3-front/models/saisie-objet-formulaire', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/saisie-objet-formulaire', 'moment', 'ceag-lot3-front/config/ceag-config-lot2', 'offline-store-sync/offline-model/offline-editable-model'], function (exports, _emberData, _saisieObjetFormulaire, _moment, _ceagConfigLot, _offlineEditableModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend(_saisieObjetFormulaire.default, {

        saisie_formulaire: _emberData.default.belongsTo('saisie-formulaire'),
        saisie_super_calque_formulaire: _emberData.default.belongsTo('saisie-super-calque-formulaire'),

        objet_formulaire: _emberData.default.belongsTo('objet-formulaire'),

        ordre: Ember.computed.alias('objet_formulaire.ordre'),

        donnee_courte: _emberData.default.attr('string'),
        donnee_longue: _emberData.default.attr('string'),

        isSignature: Ember.computed.equal('objet_formulaire.type', 'i_signature'),

        isSignatureCoContractant: Ember.computed('isSignature', 'objet_formulaire.rubrique.libelle', function () {
            if (!this.get('isSignature')) {
                return false;
            }
            let rubrique = this.get('objet_formulaire.rubrique.libelle');
            return rubrique !== undefined && rubrique.startsWith(_ceagConfigLot.default.signatureRubriqueGeneriqueCoContractant);
        }),

        isChampAutoRemplissage: Ember.computed('objet_formulaire.type', function () {
            let type = this.get('objet_formulaire.type');
            return _ceagConfigLot.default.champsAutoRemplisage.indexOf(type) >= 0;
        }),

        isHidden: Ember.computed('isChampAutoRemplissage', 'isSignature', function () {
            return this.get('isSignature') || this.get('isChampAutoRemplissage');
        }),

        donnee_date: Ember.computed('donnee_courte', {
            get() /*key*/{

                let d = this.get('donnee_courte');
                if (Ember.isEmpty(d)) {
                    return "";
                } else if (typeof d == "string") {
                    d = new Date(d);
                }
                return (0, _moment.default)(d).format('YYYY-MM-DD');
            },
            set(key, value) {

                this.set('donnee_courte', !Ember.isEmpty(value) ? new Date(value) : null);

                return value;
            }
        })

    });
});