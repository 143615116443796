define('ceag-lot3-front/routes/in/processus/saisie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        saisie: Ember.inject.service(),
        intl: Ember.inject.service(),

        model: async function () {

            //Nouvelle saisie
            return Ember.RSVP.hash({

                //On créer une nouvelle saisieProcessus
                saisieProcessus: this.get('store').createRecord('saisie-processus', {
                    processus: this.modelFor('in.processus'),
                    processus_version: this.modelFor('in.processus').get('last_version'),
                    editeur: this.modelFor('in').utilisateur
                }),

                utilisateurs: this.modelFor('in').utilisateurs
            });
        },

        actions: {
            /**
             * Sauvegarde la saisie, puis s'occupe de créer
             * et sauvegarder les "sous-éléments" de saisie (page, super-calque, objet)
             *
             * @return {[type]}
             * @author jmestres
             */
            onSubmitNewSaisie: async function () {
                try {

                    let saisieProcessus = this.modelFor(this.routeName).saisieProcessus;
                    //On sauvegarde la saisie, puis on créer les saisie formulaire/page/super_calque
                    await saisieProcessus.save();
                    await this.get('saisie').createSaisieProcessusChild(saisieProcessus);

                    //Redirige vers la saisie du 1er formulaire
                    let saisieFormulaire = saisieProcessus.get('saisies_formulaire_ordered.firstObject');
                    let formulaire = saisieFormulaire.get('formulaire');

                    this.replaceWith('in.formulaire.saisie', formulaire, saisieFormulaire.get('id'), { queryParams: { page: 0, super_calque: 0 } });
                } catch (error) {
                    console.error('Erreur de sauvegarde du model saisieProcessus', error);
                    this.get('messageService').error(this.get('intl').t('saisie.creation.erreur'));
                }
            }

        }
    });
});