define('ceag-lot3-front/components/bs-datetimepicker', ['exports', 'ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker'], function (exports, _bsDatetimepicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _bsDatetimepicker.default.extend({

        didInsertElement() {
            this._super(...arguments);

            //On ajout un comportement custom sur le "enter" du datePicker
            let keyBind = this.$().data('DateTimePicker').keyBinds();

            keyBind.enter = this.onEnter.bind(this);

            let datePicker = this.$().data('DateTimePicker');
            datePicker.keyBinds(keyBind);

            //GEstion de l'ajout d'une classe sur le body pour gérer du css particulier quand DT ouvert
            this.$().on('dp.show', () => {
                document.body.classList.add('bs-datetimepicker-open');
            });
            this.$().on('dp.hide', () => {
                document.body.classList.remove('bs-datetimepicker-open');
            });
        },

        onEnter: function () {

            this.$().data('DateTimePicker').hide();
            if (this.get('onSubmit')) {
                this.get('onSubmit')();
            }
        }
    });
});