define('ceag-lot3-front/models/saisie-formulaire', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/saisie-formulaire', 'ceag-lot3-front/mixins/computed-etat-saisie', 'offline-store-sync/offline-model/offline-editable-model', 'ceag-lot3-front/config/ceag-config-lot2'], function (exports, _emberData, _saisieFormulaire, _computedEtatSaisie, _offlineEditableModel, _ceagConfigLot) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend(_saisieFormulaire.default, _computedEtatSaisie.default, {

        date_creation: _emberData.default.attr('date', { defaultValue: function () {
                return new Date();
            } }), //RO
        date_mise_a_jour: _emberData.default.attr('date'), //RO
        createur: _emberData.default.belongsTo('utilisateur'), //RO

        editeur: _emberData.default.belongsTo('utilisateur'),

        /*
            ATTENTION : le "formulaire" n'est pas forcément le parent de "formulaire_version".
            Dans le cas d'un formulaire fictif, le champ "saisie.formulaire" est lié au formulaire fictif,
            alos que "saisie.formulaire_version" correspond à la "formulaire-version" non fictif, choisie pour la saisie (après passage par le questionnaire)
         */
        formulaire: _emberData.default.belongsTo('formulaire'),
        formulaire_version: _emberData.default.belongsTo('formulaire-version'),

        complement_saisie: _emberData.default.attr('string'),
        date_intervention: _emberData.default.attr('date'),

        complete: _emberData.default.attr('boolean', { defaultValue: false }),
        etat_saisie: _emberData.default.attr('string', { defaultValue: 'new' }),

        index: Ember.computed.alias('etape_processus.index'),
        saisie_processus: _emberData.default.belongsTo('saisie-processus', { inverse: 'saisies_formulaire' }),

        etape_processus: _emberData.default.belongsTo('etape-processus'),

        saisies_page_formulaire: _emberData.default.hasMany('saisie-page-formulaire'),

        //Les pages à saisir (non automatique/hidden)
        saisies_page_formulaire_not_hidden: Ember.computed.filterBy('saisies_page_formulaire', 'isHidden', false),
        isHidden: Ember.computed.empty('saisies_page_formulaire_not_hidden'),

        //Pour avoir les "étapes" de saisie toujours dans le bon ordre
        saisieSortin: ['numero:asc'],
        saisies_page_formulaire_ordered: Ember.computed.sort('saisies_page_formulaire_not_hidden', 'saisieSortin'),

        // test calque_formulaire.super_calque.ordre
        saisieCCSortin: ['calque_formulaire.super_calque.ordre:asc'],
        saisies_co_contractant: _emberData.default.hasMany('saisie-co-contractant'),
        saisies_co_contractant_ordered: Ember.computed.sort('saisies_co_contractant', 'saisieCCSortin'),

        //saisies_calque: DS.hasMany('calque_formulaire'),

        //Pour chemin de fer
        libelle: Ember.computed.alias('formulaire.libelle'),
        libelleCourt: Ember.computed.alias('formulaire.proprietes')['short-label'],

        calque_formulaire: _emberData.default.hasMany('calque-formulaire'),

        // Nouvelle version de la récupération du libellé du 'premier' co-contractant
        // d'une saisie, ce libellé est utilisé 'templates/in/saisie.hbs'
        // logiquement, le premier co-contractant est affiché dans la liste, cependant
        // les co-contractants étant triés par 'id' dans la bdd, et les 'id' étant des alpha 
        // numérique aléatoires, le résultat etait plutôt chaotique, cette nouvelle version
        // corrige (logiquement) le problème
        libelleClientV2: Ember.computed('saisies_co_contractant', function () {

            // La liste des coContractants de cette saisie, dans le désordre (triés par id alphanumérique)
            let saisieCoContractants = this.get('saisies_co_contractant');

            // La liste des co-contractants indexé dans un premier temps puis trié
            let saisieCoContractantsOrdered = {};

            // lecture de tous les co-contractants de la liste
            for (let i = 0; i < saisieCoContractants.content.length; i++) {

                // pas trop compliqué, c'est l'un des co-contractants de la liste
                let currentCoContractant = saisieCoContractants.content.objectAt(i);

                // A présent, le libelle du calque (qui contient les infos de co-contractant)
                // devrait être présente dans le co-contractant, ce qui devrait aider
                let calqueFormulaireLibelle = currentCoContractant.get('libelle_calque');

                // Si le libellé n'est pas détecté alors ne pas continuer cette itération
                if (typeof calqueFormulaireLibelle === 'undefined') {
                    continue;
                }

                // Récupération de l'index du co-contractant
                let index = parseInt(calqueFormulaireLibelle.substr(_ceagConfigLot.default.nomCalqueCoContractant.length + 1));

                // Ajout du cocontractant au tableau avec son index
                saisieCoContractantsOrdered[index] = currentCoContractant;
            }

            // Récupération d'un tableau contenant les index
            let indexTable = Object.keys(saisieCoContractantsOrdered);

            // si le tableau est vide (pas de co-contractants trouvé) ne rien retourner
            if (indexTable.length == 0) {
                return 'Non renseigné';
            }

            // Récupération du libellé et de l'adresse du co-contractant sur le premier élement du tableau
            let firstCoContractant = saisieCoContractantsOrdered[indexTable[0]];
            let libelle = firstCoContractant.get('libelleClient');
            let adresse = firstCoContractant.get('adresse');

            // Il faut que les données obtenues soient renseignées
            if (Ember.isEmpty(libelle) && (Ember.isEmpty(adresse) || Ember.isEmpty(adresse.String))) {
                return 'Non renseigné';
            }

            // Construction de la chaine qui sera affichée dans le tableau
            let lib = '';
            lib += Ember.isEmpty(libelle) ? '' : libelle;
            lib += Ember.isEmpty(adresse) ? '' : '<br>' + adresse;

            return Ember.String.htmlSafe(lib);
        }),

        // ancienne version de la récupération des libellé co-contractant, conservés
        // par soucis de compatibilité dans un premier temps, elle est buggé et ne devrait 
        // plus être utilisé
        // problème connu : 
        // - le libellé de co-contractant retournée est un libellé random choisi sur la liste des co-contractants
        //   et pas le premier comme cela devrait être le cas
        libelleClient: Ember.computed('saisies_co_contractant.firstObject.{libelleClient,adresse}', function () {

            /**
             * -------------------------------------------------------------------------
             * START : Code original (ne fonctionne pas)
             */
            let firstCoContractant = this.get('saisies_co_contractant.firstObject');

            if (Ember.isEmpty(firstCoContractant)) {
                return 'Non renseigné';
            }

            let libelle = firstCoContractant.get('libelleClient');
            let adresse = firstCoContractant.get('adresse');

            if (Ember.isEmpty(libelle) && (Ember.isEmpty(adresse) || Ember.isEmpty(adresse.String))) {
                return 'Non renseigné';
            }

            let lib = '';
            lib += Ember.isEmpty(libelle) ? '' : libelle;
            lib += Ember.isEmpty(adresse) ? '' : '<br>' + adresse;

            return Ember.String.htmlSafe(lib);
        })

    });
});