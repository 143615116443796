define('ceag-lot3-front/controllers/in/formulaire/saisie/signature', ['exports', 'ceag-lot3-front/misc/promise-array', 'ceag-lot3-front/config/ceag-config-lot2'], function (exports, _promiseArray, _ceagConfigLot) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        // la recherche s'effectue sur l'index virtuel
        // 'index_signature',
        queryParams: ['index_signature', 'v_index_signature'],

        signaturesAll: Ember.computed.alias('model.saisies_objet_formulaire_signature'),

        signatureCommand: null,

        index_signature: 0,

        // Un formulaire peut disposer de plusieurs co-contractants
        // chacun disposant ou non d'une signature, et pour chacune des signaturs,
        // elle peut être obligatoire ou non du coup nous travaillerrons sur un index virtuel
        // exemple
        //      | Nom    | Signature   | Index | Index virtuel |
        // -----|--------|-------------|-------|---------------|
        // cc 1 | Nom Ok | Obligatoire |     0 |             0 | Obligatoire
        // cc 2 | Nom Ko | Obligatoire |     1 |             1 | Obligatoire
        // cc 3 | Nom Ok | Facultatif  |     2 |             2 | Nom Ok
        // cc 4 | Nom Ko | Facultatif  |     3 |               |
        // cc 3 | Nom Ok | Facultatif  |     4 |             3 | Nom Ok
        // cc 4 | Nom Ko | Facultatif  |     5 |               |
        v_index_signature: 0,

        // Tableau contenant le mappring entre l'index virtuel et l'index reel
        // index_virtuel => index
        // Est ce que cette méthode est pérène en terme de performane ? cette variable
        // est appellée plusieurs fois lors de chaque traitement d'affichage, du coup est
        // elle réévaluée à tous les coups ? a creuser.
        virtual_index_mapping: Ember.computed('model.saisies_co_contractant', 'signaturesAll', function () {

            let virtual_index_mapping = [];
            let signaturesAll = this.get('signaturesAll');
            // let saisiesCoContractant = this.get('model.saisies_co_contractant');
            let orderedSaisieCoContractant = this.get('getOrderedCoContractants');

            // Nous bouclons sur les signatures et nous cherchons le sous contractant associé
            for (let i = 0; i < signaturesAll.length; i++) {

                let currentDonneeSignature = signaturesAll.objectAt(i);
                let nom = '';

                // Recherche du co-contractant à partir de la rubrique de la signature
                let index = -1;
                if (currentDonneeSignature.get('isSignatureCoContractant')) {
                    index = parseInt(currentDonneeSignature.get('objet_formulaire.rubrique.libelle').substr(_ceagConfigLot.default.signatureRubriqueGeneriqueCoContractant.length + 1));
                    index--;
                }

                // Si un co-contractant a été trouvé, nous determinons son nom
                if (index >= 0) {

                    // nom = (saisiesCoContractant.objectAt(index) && saisiesCoContractant.objectAt(index).get('nom'))
                    // ? saisiesCoContractant.objectAt(index).get('nom').trim()
                    // : '';
                    nom = orderedSaisieCoContractant[index] && orderedSaisieCoContractant[index].get('nom') ? orderedSaisieCoContractant[index].get('nom').trim() : '';
                    // si la signature est obligatoire ou que le nom est renseignée, alors elle fait partie de la liste
                    if (currentDonneeSignature.get('objet_formulaire.is_obligatoire') || nom != '') {
                        virtual_index_mapping.push(i);
                    }
                } else {
                    // Cette signature est une signature générique, il faut l'afficher
                    virtual_index_mapping.push(i);
                }
            }

            // Retourne le tableau contenant en clé l'index virtuel correspondant aux
            // signature devant être affichés et en valeur l'index réel pour le cocontractant
            return virtual_index_mapping;
        }),

        // Direction d'animation par défaut
        liquidFireDirection: 'toLeft',

        // Récupération des données de la signature courante, retourne la signature à afficher sur la base de sa position
        // dans l'index virtuel
        currentDonneeSignature: Ember.computed('signaturesAll', 'v_index_signature', 'virtual_index_mapping', function () {
            // Décomposition pour lisibilité
            let v_index_signature = this.get('v_index_signature');
            let virtual_index_mapping = this.get('virtual_index_mapping');
            let index_signature = virtual_index_mapping[v_index_signature];
            let signaturesAll = this.get('signaturesAll');
            return Ember.isEmpty(signaturesAll) ? null : signaturesAll.objectAt(index_signature);
        }),

        //On récupère l'objet "saisie_co_contractant" à partir du n° de co-contractant de la rubrique de la signature
        //Si l'objet signature courant n'est pas lié à un CoContractant on retourne null
        currentSaisieCoContractant: Ember.computed('model.saisies_co_contractant', 'currentDonneeSignature', function () {

            let saisiesCoContractant = this.get('model.saisies_co_contractant');
            let currentDonneeSignature = this.get('currentDonneeSignature');

            // le champ 'index' correspond au numéro du sous contractant obtenu depuis la rubrique de la signature
            let index = -1;
            if (currentDonneeSignature.get('isSignatureCoContractant')) {
                index = parseInt(currentDonneeSignature.get('objet_formulaire.rubrique.libelle').substr(_ceagConfigLot.default.signatureRubriqueGeneriqueCoContractant.length + 1));
            }

            // Si la signature n'est pas assignée à un co-contractant, ou si le
            // co-contractant n'est pas trouvé, alors il ne sert a rien de continuer
            if (index < 0 || Ember.isEmpty(saisiesCoContractant)) {
                return null;
            }

            // Récupération du co-contractant correspondant à cet index
            let coContractant = null;
            for (let i = 0; i <= saisiesCoContractant.length; i++) {

                // la récupération du numéro de sous contractant est effectué depuis le libellé du calque
                let calqueName = saisiesCoContractant.objectAt(i).get('calque_formulaire').get('libelle');
                // let calqueCode = saisiesCoContractant.objectAt(i).get('calque_formulaire').get('code');
                // let coContractantIndex = parseInt(calqueCode.substr(ConfigLot2.codeCalqueCoContractant.length+1));
                // La recherche est basée sur le libellée et non le code, puisque le code est variable dans les templates
                // Ex : le nom _CC_COCONTRACTANT01 peux avoir les codes _CC01 et _CCA01
                let coContractantIndex = parseInt(calqueName.substr(_ceagConfigLot.default.nomCalqueCoContractant.length + 1));

                if (coContractantIndex == index) {
                    // Le le numero de la signature et le numéro du calque match, alors nous récupérons le cocontractant
                    // et quittons la boucle
                    coContractant = saisiesCoContractant.objectAt(i);
                    break;
                }
            }

            // Est ce que la signature est obligatoire ?
            // Si oui, aucun changement sur le fonctionnement, alors la signature est affichée quoi qu'il en soit.
            if (!currentDonneeSignature.get('objet_formulaire.is_obligatoire')) {

                // Si le nom du co-contractant n'est pas renseigné, alors pas d'affichage de la signature
                // TODO voir a ajouter le prénom (en testant sa présence) pour prendre en charge convenablement professionnel et particuliers
                if (coContractant.get('nom').trim() == '') {
                    return null;
                }
            }

            return coContractant;
        }),

        /**
         * Retourne une liste des co contractants dans l'ordre de saisie !!!
         * @deprecated
         * Cette fonction n'a plus de sens, elle remettait les choses dans l'ordre mais
         * ne comblait pas certaines lacunes et présentait des faiblesses en terme de
         * performances
         */
        getOrderedCoContractants: Ember.computed('model.saisies_co_contractant', function () {

            // Récupérons dejà la liste des co-contractants à notre disposition, c'est trié par
            // saisie_co_contractant.id, ce qui n'est pas simple quand on sais comment est produit l'id
            // ex:
            // 7301ffdc-8ae0-4d3a-a764-27249cb4f169 = co contractant 1 (ok, coup de bol)
            // b9b32029-196f-48e4-b195-4f46053c9178 = co contractant 3 (ha... non, ça ne dure pas)
            // cd3f76e9-99d5-4ba5-b347-db8274d6f623 = co contractant 2 (forcement, ça fait tâche)
            let saisiesCoContractants = this.get('model.saisies_co_contractant');

            // ok... un peu de menage, pas de co-crontractant ?? pas la peine de rester.
            if (saisiesCoContractants.length === 0) {
                return null;
            }

            // Bon, attention les yeux, c'est du super bricolage, et si vous avez un doute quand à
            // ce que j'entends par là : https://fr.wikipedia.org/wiki/Bricole_(arme)
            // Il nous faus les sous contractants dans l'ordre suivant
            // saisieFormulaire > saisiePages > saisieSuperCalques
            // Il faut donc remonter à la page, construire un index en y allant dans l'ordre puis
            // faire matcher nous co-contractant avec, et retourner une liste bien triée
            // Pour avoir la page, comme nous ne l'avons pas en ligne sur cet objet et que mes connaissance
            // en ember son perfectible... je suis la piste à l'envers..
            let firstSaisieCoContractant = saisiesCoContractants.objectAt(0);
            let saisieFormulaire = firstSaisieCoContractant.get('saisie_formulaire');

            // Préparation de l'index contenant l'ordre des supers calques => et des calques correspondant
            let calqueIndex = []; // index => idCalque
            let indexCalque = {}; // Original comme nom, non ? c'est le même mais idCalque => index
            // Lecture des pages de saisie du formulaire
            saisieFormulaire.get('saisies_page_formulaire').sortBy('numero').forEach(saisiePageFormulaire => {

                // Lecture des supers calques de saisie du formulaire
                saisiePageFormulaire.get('saisies_super_calque_formulaire').sortBy('ordre').forEach(saisieSuperCalqueFormulaire => {

                    // Récupération du super calque (le model, pas la saisie)
                    // Nous avons donc la liste des identifiants de super calque ordonnée
                    let superCalqueFormulaire = saisieSuperCalqueFormulaire.get('super_calque_formulaire');

                    // Récupérons maintenant les calques
                    // Et construisons nos propre index
                    // Je reste convaincu que tout cela pourrait être traiter en amont :(
                    superCalqueFormulaire.get('calques').forEach(calque => {
                        calqueIndex.push(calque.get('id'));
                        indexCalque[calque.get('id')] = calqueIndex.length - 1;
                    });
                });
            });

            // Construction d'une liste des co-contractants ordonnée
            let orderedCoContractants = {};
            for (let i = 0; i < saisiesCoContractants.length; i++) {
                let saisieCoContractant = saisiesCoContractants.objectAt(i);
                let saisieCoContractantCalqueId = saisieCoContractant.get('calque_formulaire.id');
                orderedCoContractants[indexCalque[saisieCoContractantCalqueId]] = saisieCoContractant;
            }

            // hop, c'est moche, mais c'est trié, il faut maintenant réindexer le tout car l'index
            return orderedCoContractants;
        }),

        // Détermine si la signature courrante est la première, il ne faut plus se baser sur
        // l'index premier mais sur le virtuel
        // isFirstSignature: equal('index_signature', 0),
        isFirstSignature: Ember.computed.equal('v_index_signature', 0),

        // Même chose pour déterminer sur la signature affichée est la dernière, il faut se base sur
        // l'indexation virtuelle
        // isLastSignature: computed('index_signature', 'signaturesAll.length', function(){
        //    return this.get('index_signature') == this.get('signaturesAll.length')-1;
        // }),
        isLastSignature: Ember.computed('v_index_signature', 'virtual_index_mapping.length', function () {
            return this.get('v_index_signature') == this.get('virtual_index_mapping.length') - 1;
        }),

        objetWidth: Ember.computed('currentDonneeSignature.objet_formulaire.width', function () {
            let width = this.get('currentDonneeSignature.objet_formulaire.width') || 576;
            return width + "px";
        }),

        objetHeight: Ember.computed('currentDonneeSignature.objet_formulaire.height', function () {
            let height = this.get('currentDonneeSignature.objet_formulaire.height') || 326;
            return height + "px";
        }),

        // Si tab change, on met à jour la signature
        changeSignatureData: Ember.observer('index_signature', function () {
            this.updateSignatureData();
        }),

        // Met à jour la signature (le contenu) en fonction du co-contractant actuel et du contenu de sa signature
        updateSignatureData: function () {
            let data = [];
            try {
                data = JSON.parse(this.get('currentDonneeSignature.donnee_longue'));
            } catch (e) {
                console.log("donne_longue", this.get('currentDonneeSignature.donnee_longue'));
            }
            this.set('signatureCommand', { command: 'setData', args: [data, 'native'] });
        },

        // Traitement des actions
        actions: {

            onChange: function (datas) {
                this.get('currentDonneeSignature').set('donnee_longue', JSON.stringify(datas));
            },

            // Passer à la signature suivante
            goPrevStep: function () {

                // sens du scrolling
                this.set('liquidFireDirection', 'toRight');

                let v_index_signature = this.get('v_index_signature');
                let virtual_index_mapping = this.get('virtual_index_mapping');

                if (this.get('isFirstSignature')) {
                    // récupération de l'index réel de la signature à partir de l'index virtuel
                    this.set('v_index_signature', 0);
                    this.set('index_signature', virtual_index_mapping[0]);
                } else {
                    let new_virtual_index = v_index_signature - 1;
                    this.set('v_index_signature', new_virtual_index);
                    this.set('index_signature', virtual_index_mapping[new_virtual_index]);
                }
                return true;
            },

            // retourner à la signature précédente.
            goNextStep: async function () {

                this.set('liquidFireDirection', 'toLeft');

                let v_index_signature = this.get('v_index_signature');
                let virtual_index_mapping = this.get('virtual_index_mapping');

                if (!this.get('isLastSignature')) {

                    let new_virtual_index = v_index_signature + 1;
                    let currentDonneeSignature = this.get('currentDonneeSignature');

                    currentDonneeSignature.validate();

                    // Contrôle de la validité de la signature, si erreur afficher un message, sinon avancer la position
                    // local dans l'index
                    if (!currentDonneeSignature.get('validations.isValid')) {
                        return this.get('confirmationService').alert("Erreur", currentDonneeSignature.get('validations.attrs.donnee_longue.message'));
                    } else {
                        this.set('v_index_signature', new_virtual_index);
                        this.set('index_signature', virtual_index_mapping[new_virtual_index]);
                        return true;
                    }
                } else {

                    let saisieSignatureObjetFormulaire = this.get('signaturesAll').toArray();

                    //Déclancher les validation des signatures
                    let hasError = false;
                    let indexError = 0;

                    for (let i = 0; i < virtual_index_mapping.length; i++) {

                        // Récupération de l'index
                        let real_index_signature = virtual_index_mapping[i];

                        // validation du formulaire correspondant à l'index récupéré
                        saisieSignatureObjetFormulaire[real_index_signature].validate();
                        if (!saisieSignatureObjetFormulaire[i].get('validations.isValid')) {
                            hasError = true;
                            indexError = i;
                            break;
                        }
                    }

                    // Traitement des erreurs si détecté
                    if (hasError) {
                        await this.get('confirmationService').alert("Erreur", saisieSignatureObjetFormulaire.objectAt(indexError).get('validations.attrs.donnee_longue.message'));
                        this.set('v_index_signature', indexError);
                        this.set('index_signature', virtual_index_mapping[indexError]);
                        return;
                    }

                    //On sauvegarde les saisies signature
                    await (0, _promiseArray.default)(saisieSignatureObjetFormulaire, async saisieSignature => {

                        await saisieSignature.save();

                        //Mise à jour des compteur avant envoi définitif de la saisie
                        await this.get('store').findAll('compteur', { reload: true });
                    }).then(() => {
                        //Goto next step
                        this.transitionToRoute('in.formulaire.saisie.envoi');
                    });
                }
            }

        }
    });
});