define('ceag-lot3-front/helpers/get-year', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getYear = getYear;
  function getYear() /*params, hash*/{
    return new Date().getFullYear();
  }

  exports.default = Ember.Helper.helper(getYear);
});