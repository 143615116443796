define('ceag-lot3-front/router', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    /**
     * Hack permettant d'avoir le "title" accèssible dans les composant via le service "global-title"
     * La construction du titre est géré par le module "ember-cli-document-title"
     * @author jmestres
     */
    globalTitle: Ember.inject.service(),
    setTitle(title) {
      document.title = ("" + title).replace(/(<([^>]+)>)/ig, "");
      this.get('globalTitle').set('title', title);
    }
  });

  Router.map(function () {
    this.route('login');
    this.route('reset', { path: '/reset/:mail/:token' });

    this.route('test');

    this.route('in', function () {

      //Résumé des droits (abonnement form/proc, compteur et options)
      this.route('abonnement');

      this.route('utilisateurs', function () {
        this.route('edit', { path: 'edit/:utilisateur_id' });
      });

      this.route('formulaires');
      this.route('formulaire', { path: 'formulaire/:formulaire_id' }, function () {
        this.route('action'); //"Index" d'un formulaire : aperçu et actions possibles
        this.route('saisie', { path: 'saisie/:saisie_id' }, function () {
          //Nouvelle saisie ou reprise
          this.route('signature'); //Etape signature
          this.route('envoi'); //Mode d'envoi
          this.route('preview'); //Aperçu du formulaire final
        });
      });

      this.route('processus', { path: 'processus/:processus_id' }, function () {
        this.route('action'); //"Index" d'un formulaire : aperçu et actions possibles
        this.route('saisie'); //Nouvelle saisie d'un processus (contrairement à un formulaire, cette route ne permet pas "directement la saisie d'un processus, car une sausie se fait sur un formulaire d'une saisie processus)
      });

      //Cette route permet la rechecher des saisie en cours ou validé pour un formulaire ou un pack donnée
      //ex :
      // - saisies/form/40/encours = saisie "en cours" de type "formulaire" pour le formulaire "40"
      // - saisies/form/40/older = saisie terminé "de - d'1 mois" de type "formulaire" pour le formulaire "40"
      // - saisies/form/40/done = saisie terminé "de + d'1 mois" de type "formulaire" pour le formulaire "40"
      // - saisies/proc/20/encours = saisie "en cours" de type "formulaire" pour tous les "formulaire lié au processus "20"
      //
      // - saisies/general/all/all = saisie terminée, filtré à la demande par l'utilisateur (page "archives génrale")
      //
      // Cas "un peux" particulier :
      // - saisies/form/30/autocom = saisie "terminé" de type "formulaire" pour
      //        tous les "formulaire lié au même processus libre que le formulaire "30" pour créer une nouvelle saisie.
      //    ex : "saisies/form/30/autocom"
      //            => Recherche une saisie terminée liée au même processus que le form "30", pour créer (autocompletion) une nouvelle saisie du form "30"

      this.route('saisies', { path: 'saisies/:type_saisie/:id/:period' });

      this.route('parametrage');

      this.route('statistiques');
    });

    this.route('device');
  });

  exports.default = Router;
});