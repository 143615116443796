define('ceag-lot3-front/models/saisie-page-formulaire', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-editable-model', 'ceag-lot3-front/mixins/computed-etat-saisie'], function (exports, _emberData, _offlineEditableModel, _computedEtatSaisie) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend(_computedEtatSaisie.default, {

        saisie_formulaire: _emberData.default.belongsTo('saisie-formulaire'),

        page_formulaire: _emberData.default.belongsTo('page-formulaire'),

        etat_saisie: _emberData.default.attr('string', { defaultValue: 'new' }),

        saisies_super_calque_formulaire: _emberData.default.hasMany('saisie-super-calque-formulaire'),

        saisies_super_calque_formulaire_not_hidden: Ember.computed.filterBy('saisies_super_calque_formulaire', 'isHidden', false),
        isHidden: Ember.computed.empty('saisies_super_calque_formulaire_not_hidden'),

        //Pour avoir les "étapes" de saisie toujours dans le bon ordre
        saisieSortin: ['ordre:asc'],
        saisies_super_calque_formulaire_ordered: Ember.computed.sort('saisies_super_calque_formulaire_not_hidden', 'saisieSortin'),

        numero: Ember.computed.alias('page_formulaire.numero'),

        //Pour le chemin de fer
        libelle: Ember.computed('numero', function () {
            return `Page ${this.get('numero')}`;
        }),

        libelleCourt: Ember.computed.alias('libelle')

    });
});