define('ceag-lot3-front/routes/in/formulaire', ['exports', 'ceag-lot3-front/helpers/version-widget'], function (exports, _versionWidget) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "",

        model: async function (params) {
            let formulaire = this.get('store').peekRecord('formulaire', params.formulaire_id);
            if (formulaire == null) {
                formulaire = await this.get('store').findRecord('formulaire', params.formulaire_id);
            }

            //Titre de page avec version du formulaire
            this.set('title', Ember.String.htmlSafe(formulaire.get('aboLibelle') + (0, _versionWidget.default)(formulaire.get('last_version'))));

            return formulaire;
        }
    });
});