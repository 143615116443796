define('ceag-lot3-front/components/formulaire/page-preview-img', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        platform: Ember.inject.service('ember-cordova/platform'),
        fileCache: Ember.inject.service('file-cache'),
        connectionStatus: Ember.inject.service('connection-status'),
        store: Ember.inject.service(),

        isOnline: Ember.computed.alias('connectionStatus.isOnline'),

        cacheFileUrl: null,

        /* TODO: Gérer le offline */
        src: Ember.computed('page', 'formulaire', 'cacheFileUrl', function () {
            let form_id = this.get('formulaire.id');

            if (this.get('formulaire.formulaire.form_fictif')) {
                form_id = this.get('formulaire.questionnaire.last_version.choix_questionnaire.firstObject.formulaire.last_version.id');
            }

            let page = this.get('page');

            if (this.get('platform.isCordova')) {
                return this.get('cacheFileUrl');
            } else if (this.get('isOnline')) {
                let adapter = this.get('store').adapterFor('formulaire-version');
                return adapter.getUrlForPagePreview(form_id, page);
            }
        }),

        alt: Ember.computed('page', function () {
            let page = this.get('page');
            return `Page n° ${page}`;
        }),

        didReceiveAttrs: function () {
            if (this.get('platform.isCordova')) {
                try {

                    let form_id = this.get('formulaire.id');
                    let page = this.get('page');

                    // this.set('cacheFileUrl', 'cdvfile://localhost/persistent/form-preview/101_1.png');

                    this.get('fileCache').getFormPreviewUrl(form_id, page).then(cacheURL => {
                        this.set('cacheFileUrl', cacheURL);
                    });
                } catch (e) {
                    this.set('cacheFileUrl', null);
                }
            }
        }
    });
});