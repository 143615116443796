define('ceag-lot3-front/models/validations/abonnement', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        mail_archive: [(0, _emberCpValidations.validator)('format', {
            type: 'email',
            allowBlank: true
        })]

    });
});