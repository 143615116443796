define('ceag-lot3-front/services/offline-store', ['exports', 'offline-store-sync/services/offline-store'], function (exports, _offlineStore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _offlineStore.default;
    }
  });
});