define('ceag-lot3-front/components/bs-modal', ['exports', 'ember-bootstrap/components/bs-modal'], function (exports, _bsModal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _bsModal.default.extend({

        keepModalOpenClass: false,

        show: function () {

            //Le problème évoqué ci-dessus vient du fait que la class "modal-open" est supprimé du body à la fermeture
            //du popup sans tenir compte d'un éventuel autre popup ouvert
            this.set('keepModalOpenClass', document.body.classList.contains('modal-open'));

            this._super(...arguments);
        },

        hideModal() {
            if (this.get('isDestroyed')) {
                return;
            }

            this.handleBackdrop(() => {

                if (!this.get('keepModalOpenClass')) {
                    document.body.classList.remove('modal-open');
                }

                this.resetAdjustments();
                this.resetScrollbar();
                this.set('inDom', false);
                this.get('onHidden')();
            });
        },

        willDestroyElement() {
            this._super(...arguments);
            if (this.get('keepModalOpenClass')) {
                document.body.classList.add('modal-open');
            }
        }

    });
});