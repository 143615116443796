define('ceag-lot3-front/serializers/online/formulaire', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            versions: { embedded: 'always' },
            etapes: { embedded: 'always' }
        }
    });
});