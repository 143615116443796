define('ceag-lot3-front/config/ceag-config-lot2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    /**
     * Les nom de rubrique particulière.
     * Ex Signature : "CC_Signature"   => Les rubriques correspondantes à la signature d'un co-contractant commence par "CC_Signature"
     *                                 => ex: "CC_Signature_01" et "CC_Signature_02" pour le co-contractant 1 et 2.
     *
     * @type {Object}
     */
    nomRubriqueCoContractant: {

      //LibelleDeLaRubriqueSansCC : 'attribut de la saisie_co_contractant à alimenter'
      // ex si entrée du type :
      //         RubriqueDeTest : 'description_co_contractant'
      // alors :
      //         Un champ de saisie, lié à la rubrique "CC_RubriqueDeTest_002", qui est rempli en lot3,
      //         sa valeur va alimenter l'attribut "description_co_contractant" du 2eme objet "saisie_co_contractant" de la "saisie_formulaire"

      Email: 'mail',
      Civilite: 'civilite',
      Nom: 'nom',
      Prenom: 'prenom',
      Societe: 'societe',
      Siret: 'siret',
      Adresse1: 'adresse1',
      Adresse2: 'adresse2',
      Adresse3: 'adresse3',
      Adresse4: 'adresse4',
      CodePostal: 'code_postal',
      Ville: 'ville',
      Signature: 'signature',
      Numero: 'numero_client',
      Telephone1: 'telephone1',
      Telephone2: 'telephone2'

    },

    //Chaine générique pour déterminer si la rubrique est une rubrique lié à une info coo-contractant
    nomRubriqueGeneriqueCoContractant: 'CC_',

    // chaine générique pour déterminer si le calque est un calque coContractant
    nomCalqueCoContractant: '_CC_COCONTRACTANT',
    codeCalqueCoContractant: '_CC',

    //Chaine générique pour déterminer si la rubrique est une signature co-contractant
    signatureRubriqueGeneriqueCoContractant: 'CC_Signature',

    //Les champs qui ne sont pas à remplir par l'utilisateur lot3
    champsAutoRemplisage: ['i_uid', 'i_date_auto', 'i_localisation', 'i_device_id']
  };
});