define('ceag-lot3-front/serializers/online/abonnement', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            formulaire: {
                serialize: false,
                deserialize: 'id'
            },
            processus: {
                serialize: false,
                deserialize: 'id'
            },

            from: { serialize: false, deserialize: true },
            to: { serialize: false, deserialize: true },
            libelle: { serialize: false, deserialize: true },
            is_pack: { serialize: false, deserialize: true },
            actif: { serialize: false, deserialize: true },
            nb_device: { serialize: false, deserialize: true },
            nb_jeton: { serialize: false, deserialize: true },
            utilisateur: { serialize: false, deserialize: true },

            mail_archive: true
        }
    });
});