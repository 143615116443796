define('ceag-lot3-front/adapters/offline/sync-task-error', ['exports', 'offline-store-sync/adapters/offline/sync-task-error'], function (exports, _syncTaskError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _syncTaskError.default;
    }
  });
});