define('ceag-lot3-front/components/formulaire/saisie/objets/i-select-objet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        platform: Ember.inject.service('ember-cordova/platform'),

        onUpdate: function () {},

        optionsList: Ember.computed('objet.objet_formulaire.proprietes.content', function () {
            return this.get('objet.objet_formulaire.proprietes.content').split(',');
        })
    });
});