define('ceag-lot3-front/models/processus-version', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        processus: _emberData.default.belongsTo('processus', { inverse: 'versions' }),

        version: _emberData.default.attr('string'),

        ordonnancement: _emberData.default.attr('string'),
        destinataire: _emberData.default.attr('string'),
        //type: DS.attr('string'),
        numero_client: _emberData.default.attr('string'),

        categorie: _emberData.default.belongsTo('categorie'),

        etapes: _emberData.default.hasMany('etape-processus'),

        date_creation: _emberData.default.attr('date'),
        date_mise_a_jour: _emberData.default.attr('date')

    });
});