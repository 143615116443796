define('ceag-lot3-front/helpers/pluriel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.pluriel = pluriel;
    function pluriel(params) {
        let size = 0;
        try {
            size = typeof params[0] == "number" ? params[0] : params[0].length;
            let word = params[1] ? params[1] : 's';
            return size > 1 ? word : '';
        } catch (e) {
            return '';
        }
    }

    exports.default = Ember.Helper.helper(pluriel);
});