define('ceag-lot3-front/services/file-cache', ['exports', 'ceag-lot3-front/misc/promise-array'], function (exports, _promiseArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        store: Ember.inject.service(),
        confirmationService: Ember.inject.service('confirmation'),
        platform: Ember.inject.service('ember-cordova/platform'),
        connectionStatus: Ember.inject.service('connection-status'),

        directories: {
            formPreview: 'form-preview',
            saisiePreview: 'saisie-preview',
            pdfArchive: 'pdf-archive'
        },

        isInit: false,

        /**
         * Initialisation des répertoires utiles à l'applications
         * @author jmestres
         */
        initDirectory: async function () {

            return new Ember.RSVP.Promise((resolve, reject) => {

                if (!this.get('platform.isCordova') || this.get('isInit')) {
                    resolve();
                } else {
                    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, rootDirEntry => {

                        rootDirEntry.getDirectory(this.get('directories.formPreview'), { create: true }, subDirEntry => {

                            this.set('directories.formPreview', subDirEntry);

                            rootDirEntry.getDirectory(this.get('directories.pdfArchive'), { create: true }, subDirEntry => {
                                this.set('directories.pdfArchive', subDirEntry);

                                rootDirEntry.getDirectory(this.get('directories.saisiePreview'), { create: true }, subDirEntry => {

                                    this.set('directories.saisiePreview', subDirEntry);

                                    this.set('isInit', true);

                                    resolve();
                                }, () => {
                                    this.folderCreationError();
                                    reject(this);
                                });
                            }, () => {
                                this.folderCreationError();
                                reject(this);
                            });
                        }, () => {
                            this.folderCreationError();
                            reject(this);
                        });
                    }, () => {
                        this.folderCreationError();
                        reject(this);
                    });
                }
            });
        },

        folderCreationError: function () {
            console.error('folderCreationError.');
            this.get('confirmationService').alert('Erreur', "Impossible de créer les repertoires nécessaires au fonctionnement hors-ligne de l'application. L'application peut ne pas fonctionner correctement sans connexion.", { typeAlert: 'warning' });
        },
        genericError: function () {
            console.error('genericError.');
            this.get('confirmationService').alert('Erreur', "Une erreur est survenue lors de l'écriture/lecture d'un fichier de cache. L'application peut ne pas fonctionner correctement en mode déconnecté", { typeAlert: 'warning' });
        },

        /**
         * Télécharge et met en cache les préview des formulaireVersion liées aux abonnements.
         * @param  {abonnement} abonnements Les abonnements de l'utilisateur
         * @return {RSVP.Promise}
         * @author jmestres
         */
        processFormulairePreviewForAbonnements: async function (abonnements) {

            await (0, _promiseArray.default)(abonnements, async abonnement => {

                //Processus
                if (abonnement.get('is_pack')) {

                    //On traite tout les formulaire liés aux étapes du processus...
                    let etapesProcessus = abonnement.get('processus.last_version.etapes');
                    await (0, _promiseArray.default)(etapesProcessus, async etape => {
                        await this.processFormulairePreview(etape.get('formulaire'));
                    });
                }
                //Formulaire
                else {
                        await this.processFormulairePreview(abonnement.get('formulaire'));
                    }
            });
        },

        processFormulairePreview: async function (formulaire) {

            //FormFictif = Questionnaire
            if (formulaire.get('form_fictif')) {

                let choixQuestionnaire = formulaire.get('last_version.questionnaire.last_version.choix_questionnaire');
                //On traite récursivement tous les formulaires du questionnaire
                //Note : Normalement, seul l'aperçu du 1er formulair du questionnaire sera afficher
                await (0, _promiseArray.default)(choixQuestionnaire, async choix => {
                    await this.processFormulairePreview(choix.get('formulaire'));
                });
            } else {

                let versionFormulaire = formulaire.get('last_version');
                await this.processFormulaireVersionPreview(versionFormulaire);
            }
        },

        processFormulaireVersionPreview: async function (formulaireVersion) {

            //Pour chaque page, on va chercher le fichier aperçu
            let pageCount = formulaireVersion.get('pages.length');
            let idForm = formulaireVersion.get('id');

            let adapter = this.get('store').adapterFor('formulaire-version');

            for (let i = 1; i <= pageCount; i++) {

                let pagePreviewUrl = adapter.getUrlForPagePreview(idForm, i);
                let pagePreviewFileName = `${idForm}_${i}.png`;

                let fileExist = await this.fileExist(this.get('directories.formPreview'), pagePreviewFileName);

                if (!fileExist) {

                    await adapter.ajaxCall(pagePreviewUrl, {
                        xhrFields: { responseType: 'blob' },
                        dataType: 'binary'
                    }, true).then(async previewDatasBlob => {
                        await this.saveFile(this.get('directories.formPreview'), previewDatasBlob, pagePreviewFileName);
                    });
                }
            }
        },

        /**
         * Retourne une URL interne pour un fichier preview en cache
         * @param  {integer} idForm Id de la version Formulaire
         * @param  {integer} page   Numéro de page
         * @return {string} URL de l'image en cache
         * @author jmestres
         */
        getFormPreviewUrl: function (idForm, page) {
            let fileName = `${idForm}_${page}.png`;

            return new Ember.RSVP.Promise((resolve, reject) => {

                this.get('directories.formPreview').getFile(fileName, { create: false, exclusive: false }, fileEntry => {

                    resolveLocalFileSystemURL(fileEntry.toURL(), entry => {

                        //https://github.com/cordova-plugin-camera-preview/cordova-plugin-camera-preview/issues/109
                        if (this.get('platform.isIOS')) {

                            entry.file(function (file) {
                                var reader = new FileReader();
                                reader.onloadend = function (event) {
                                    resolve(event.target.result);
                                };
                                reader.readAsDataURL(file);
                            });
                        } else {
                            resolve(entry.toInternalURL());
                        }
                    });
                }, () => {
                    reject();
                });
            });
        },

        /**
         * Déclanche la génération d'un aperçu de saisie et met en cache si mobile
         * @param  {saisie_formulaire} saisieFormulaire La saisie formulaire pour laquelle on souhaite générer un aperçu
         * @return {Promise}
         * @author jmestres
         */
        processSaisieFormulairePreview: function (saisieFormulaire) {

            if (this.get('connectionStatus.isOnline')) {
                let adapter = this.get('store').adapterFor('saisie-formulaire');
                let previewUrl = adapter.getPdfUrlForPreviewSaisie(saisieFormulaire);

                return adapter.ajaxCall(previewUrl, {
                    xhrFields: { responseType: 'blob' },
                    dataType: 'binary'
                }, true).then(previewDatasBlob => {

                    //Si mobile on met en cache
                    if (this.get('platform.isCordova')) {
                        let saisiePreviewFileName = `${saisieFormulaire.id}.png`;
                        return this.saveFile(this.get('directories.saisiePreview'), previewDatasBlob, saisiePreviewFileName);
                    }

                    return previewDatasBlob;
                });
            }

            return Ember.RSVP.Promise.resolve();
        },

        /**
         * Retourne une URL interne pour un fichier preview d'une saisie en cache
         * @param  {integer} idSaisie Id de la saisie Formulaire
         * @return {string} URL de l'image en cache
         * @author jmestres
         */
        getSaisiePreviewUrl: function (idSaisie) {
            let fileName = `${idSaisie}.png`;

            return new Ember.RSVP.Promise((resolve, reject) => {

                this.get('directories.saisiePreview').getFile(fileName, { create: false, exclusive: false }, fileEntry => {

                    resolveLocalFileSystemURL(fileEntry.toURL(), entry => {

                        //https://github.com/cordova-plugin-camera-preview/cordova-plugin-camera-preview/issues/109
                        // bugfix activé tout le temps car sinon l'url cdvfile://... peut afficher une ancienne version d'une image

                        // le test if true provoque une erreur lors de la compilation, si l'on doit toujours passer dedans, autant supprimer le test
                        // if(true || this.get('platform.isIOS') ){

                        entry.file(function (file) {
                            var reader = new FileReader();
                            reader.onloadend = function (event) {
                                resolve(event.target.result);
                            };
                            reader.readAsDataURL(file);
                        });
                        /*}
                        
                        else{
                            resolve(entry.toInternalURL());
                        }*/
                    });
                }, () => {
                    reject();
                });
            });
        },

        saveFile: function (dirEntry, fileData, fileName) {

            return new Ember.RSVP.Promise((resolve, reject) => {

                dirEntry.getFile(fileName, { create: true, exclusive: false }, fileEntry => {
                    resolve(this.writeFile(fileEntry, fileData));
                }, () => {
                    this.genericError();
                    reject(this);
                });
            });
        },

        writeFile: function (fileEntry, dataObj) {

            return new Ember.RSVP.Promise(function (resolve, reject) {
                fileEntry.createWriter(function (fileWriter) {

                    fileWriter.onwriteend = function () /*e*/{
                        resolve(dataObj);
                    };

                    fileWriter.onerror = function (e) {
                        reject(e);
                    };

                    fileWriter.write(dataObj);
                });
            });
        },

        /**
         * Test l'existance d'un fichier
         * @param  {[type]} dirEntry [description]
         * @param  {[type]} fileName [description]
         * @return {[type]}
         * @author jmestres
         */
        fileExist: function (dirEntry, fileName) {

            return new Ember.RSVP.Promise((resolve /*, reject*/) => {

                dirEntry.getFile(fileName, { create: false, exclusive: false }, fileEntry => {
                    fileEntry.createWriter(function (fileWriter) {
                        try {
                            fileWriter.seek(fileWriter.length);
                            resolve(true);
                        } catch (e) {
                            resolve(false);
                        }
                    });
                }, () => {
                    resolve(false);
                });
            });
        }

    });
});