define('ceag-lot3-front/components/formulaire/saisie/objets/i-radio-objet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['form-group', 'radio-group'],

        //Class "dynamique"
        classNameBindings: ['isRequired', 'hasError', 'hasFeedback'],

        isRequired: Ember.computed.alias('objets.firstObject.objet_formulaire.is_obligatoire'),
        hasFeedback: Ember.computed.alias('showError'),
        notValid: Ember.computed.not('objets.firstObject.validations.isValid'),
        hasError: Ember.computed.and('hasFeedback', 'notValid'),

        actions: {

            activeRadioAction: function (saisieFormulaire) {
                this.get('objets').forEach(saisie => {
                    let value = saisie.get('id') == saisieFormulaire.get('id');
                    saisie.set('donnee_courte', value);

                    let onUpdate = this.get('onUpdate');

                    if (!Ember.isEmpty(onUpdate)) {
                        onUpdate(saisie, value);
                    }
                });
            }
        }

    });
});