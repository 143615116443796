define('ceag-lot3-front/offline-model/offline-editable-model', ['exports', 'offline-store-sync/offline-model/offline-editable-model'], function (exports, _offlineEditableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _offlineEditableModel.default;
    }
  });
});