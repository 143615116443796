define('ceag-lot3-front/config/ceag-config-lot1', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {

        /**
         * Les produits de type "options" et leur code.
         * @type {Array}
         */
        optionsLot1: [{
            code: 'OPT_SIGN', //Référence du lot1
            libelle: 'Signature éléctronique', //Libellé dans le lot3
            configName: 'signature', //Nom du paramètre dans le lot3, peux être récupérer via : "this.get('offlineInfosService').options.signature"
            enableForParticulier: true //Option active pour un particulier
        }, {
            code: 'OPT_LOGOTERM',
            libelle: 'Logo sur terminal',
            configName: 'logoTerminal',
            enableForParticulier: false
        }, {
            code: 'OPT_LOGOPAGE',
            libelle: 'Logo sur toutes les pages de l’appli',
            configName: 'logoPage',
            enableForParticulier: false
        }, {
            code: 'OPT_REPCSV',
            libelle: 'Reprise Fichier CSV des données',
            configName: 'csv',
            enableForParticulier: true
        }],

        /**
         * Rubrique pour auto-completion eCommerce.
         * Ex : Si "EC_Societe" alors si un champs de saisie est lié à la rubrique "EC_Societe" il sera auto-compélété
         * avec l'attribut "societe" du model "utilisateur-prestashop-informations" de l'utilisateur courant.
         * @type {Object}
         */
        nomRubriqueLot1eCommerce: {
            EC_Societe: 'societe',
            EC_Mail: 'mail',
            EC_MobilePro: 'pro_phone_mobile',
            EC_FaxPro: 'pro_fax',
            EC_Telephone: 'pro_phone',
            EC_Siret: 'siret',
            EC_APE: 'pro_ape',
            EC_Adresse: 'adresse',
            EC_BoitePostale: 'boite_postale',
            EC_CodePostal: 'code_postal',
            EC_Localite: 'localite',
            EC_StatutJuridique: 'statut_juridique',
            EC_NumeroAppartement: 'numero_appartement',
            EC_Batiment: 'batiment',
            EC_Civilite: 'civilite',
            EC_Nom: 'nom',
            EC_Prenom: 'prenom',
            EC_TVA: 'pro_tva'
        }
    };
});