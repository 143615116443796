define('ceag-lot3-front/adapters/online/saisie-formulaire', ['exports', 'ceag-lot3-front/adapters/online/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({

        getPdfUrlForSaisieFormulaire: function (saisieFormulaire) {
            let url = this.urlForFindRecord(saisieFormulaire.get('id'), 'saisie-formulaire');
            return url + '/pdf';
        },

        getPdfUrlForPreviewSaisie: function (saisieFormulaire) {

            let url = this.urlForFindRecord(saisieFormulaire.get('id'), 'saisie-formulaire');

            //On rajoute un timestamp dans l'URL pour rafraîchir lors de la mise à jour d'une saisie
            let dateUpate = saisieFormulaire.get('date_mise_a_jour');
            let ts = Ember.isEmpty(dateUpate) ? '' : dateUpate.getTime();

            return url + '/preview?ts=' + ts;
        }

    });
});