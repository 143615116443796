define('ceag-lot3-front/models/reedition-saisie', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/reedition-saisie'], function (exports, _emberData, _reeditionSaisie) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_reeditionSaisie.default, {

        date_creation: _emberData.default.attr('date'),
        createur: _emberData.default.belongsTo('utilisateur'),

        etat_demande: _emberData.default.attr('string'),

        mail: _emberData.default.attr('string'),

        saisie_formulaire: _emberData.default.belongsTo('saisie-formulaire')
    });
});