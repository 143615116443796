define('ceag-lot3-front/components/th-sortable', ['exports', 'ember-data-table/components/th-sortable'], function (exports, _thSortable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _thSortable.default.extend({

        sortType: 'alpha'
    });
});