define('ceag-lot3-front/routes/in/formulaire/saisie/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        intl: Ember.inject.service(),

        actions: {
            willTransition: function (transition) {

                //Si sortie de la saisie, on check si modification non sauvegardée
                if (transition.targetName !== 'in.formulaire.saisie.signature') {
                    let controller = this.controllerFor(this.routeName);
                    let superCalque = controller.get('saisieSuperCalqueCourant');

                    if (superCalque.hasDeepDirtyAttributes()) {

                        transition.abort();
                        let msg = this.get('intl').t('saisie.unsaved.contenu');
                        let opt = {
                            okLabel: this.get('intl').t('saisie.unsaved.ok'),
                            okType: 'success',
                            cancelLabel: this.get('intl').t('saisie.unsaved.cancel'),
                            cancelType: 'danger',
                            autoClose: false //On gère manuellement un état pending sur notre popup de conf
                        };

                        this.get('confirmationService').confirm(this.get('intl').t('saisie.unsaved.titre'), msg, opt).then(async () => {

                            this.get('confirmationService').setOKPending(true);
                            try {
                                await controller.checkErrorOnCurrentSuperCalque();
                                await controller.saveCurrentSuperCalque();
                                transition.retry();
                            }
                            //Erreur de validation
                            catch (e) {
                                console.error("WTF", e);
                            }

                            this.get('confirmationService').close();
                        }, () => {

                            //Rollback
                            superCalque.get('saisies_objet_formulaire').forEach(saisieObjet => {
                                // ça manque d'éleguance, mais dans le cas des dessins (signatures)
                                // l'attribut 'dirty' ne veux pas se mettre à jour :-(
                                // Du coup c'est quand même couillon puisque les données sont déjà sauvegardées.
                                if (saisieObjet.get('objet_formulaire.type') == 'i_dessin') {
                                    saisieObjet.set('hasDirtyAttributes', false);
                                }
                                saisieObjet.rollbackAttributes();
                            });
                            superCalque.rollbackAttributes();

                            this.get('confirmationService').close();
                            transition.retry();
                        });
                    }
                }
            }
        }
    });
});