define('ceag-lot3-front/components/ui/switch-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['bs-switch-btn-group'],
        size: null
    });
});