define('ceag-lot3-front/misc/forgot-password', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Objet permettant de valider les information de login
     * @author jmestres
     */
    const Validations = (0, _emberCpValidations.buildValidations)({
        username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })]

    });

    exports.default = Ember.Object.extend(Validations, {
        username: null
    });
});