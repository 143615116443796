define('ceag-lot3-front/misc/login', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Objet permettant de valider les information de login
     * @author jmestres
     */
    const Validations = (0, _emberCpValidations.buildValidations)({
        identification: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
        password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 8
        })],
        condition: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
            return model.get('condition') === true ? true : "Veuillez accepter nos CGV";
        })]

    });

    exports.default = Ember.Object.extend(Validations, {
        identification: null,
        password: null,
        condition: false
    });
});