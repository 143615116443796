define('ceag-lot3-front/models/compteur', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        libelle: _emberData.default.attr('string'),
        use: _emberData.default.attr('number'),
        total: _emberData.default.attr('number'),
        restant: _emberData.default.attr('number')
    });
});