define('ceag-lot3-front/models/super-calque-formulaire', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        libelle: _emberData.default.attr('string'),
        ordre: _emberData.default.attr('number'),

        proprietes: _emberData.default.attr('ceag-object-props'),

        is_coo_contractant: _emberData.default.attr('boolean'),

        objets: _emberData.default.hasMany('objet-formulaire'),
        calques: _emberData.default.hasMany('calque-formulaire'),

        page: _emberData.default.belongsTo('page-formulaire')
    });
});