define('ceag-lot3-front/routes/in/parametrage', ['exports', 'ceag-lot3-front/misc/admin-route', 'ceag-lot3-front/mixins/online-route'], function (exports, _adminRoute, _onlineRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adminRoute.default.extend(_onlineRoute.default, {

        rolesAllowed: ['ROLE_PARTICULIER', 'ROLE_ADMIN_PROFESSIONNEL'],
        intl: Ember.inject.service(),

        model: function () {

            return Ember.RSVP.hash({
                packs: this.modelFor('in').abonnements.processus,
                formulaires: this.modelFor('in').abonnements.formulaires
            });
        },

        actions: {
            willTransition: function (transition) {
                let modifNotSaved = false;

                this.modelFor('in').abonnements.all.forEach(abo => {
                    if (abo.get('hasDirtyAttributes')) {
                        modifNotSaved = true;
                    }
                });

                if (modifNotSaved) {

                    transition.abort();

                    let intl = this.get('intl');

                    this.get('confirmationService').confirm(intl.t('parametre.unsaved.titre'), intl.t('parametre.unsaved.contenu'), {
                        okLabel: intl.t('global.oui'),
                        cancelLabel: intl.t('global.non')
                    }).then(() => {
                        this.modelFor('in').abonnements.all.forEach(abo => {
                            abo.rollbackAttributes();
                        });
                        transition.retry();
                    });
                }
            }
        }

    });
});