define('ceag-lot3-front/components/ui/j-signature', ['exports', 'ember-jsignature/components/ember-jsignature'], function (exports, _emberJsignature) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberJsignature.default.extend({

        intl: Ember.inject.service(),

        resizeWindow: function () {
            let canvas = this.$('canvas.jSignature');
            if (canvas == undefined) {
                if (this.$(window) != undefined) this.$(window).off("resize");
                return;
            }
            let width = canvas.attr('width');
            let height = canvas.attr('height');
            canvas.css('transform-origin', 'top left');
            let modalContent = canvas.closest('.modal');
            let inModal = !Ember.isEmpty(modalContent);

            if (Ember.isEmpty(modalContent)) {
                modalContent = canvas.closest('div.row.scaler');
            }
            //console.log(modalContent.prop('offsetWidth') + "x" + modalContent.prop('offsetHeight'));
            let viewWidth = modalContent.prop('offsetWidth') - 30;
            if (inModal) {
                viewWidth = modalContent.prop('offsetWidth') * 95 / 100 - 30;
            }

            let zoom = viewWidth / width;
            let viewHeight = height * zoom;
            if (viewHeight > modalContent.prop('offsetHeight')) {
                // ça ne rentre pas en hauteur
                if (inModal) {
                    viewHeight = modalContent.prop('offsetHeight') * 95 / 100 - 20;
                } else {
                    viewHeight = modalContent.prop('offsetHeight') - 20;
                }
                zoom = viewHeight / height;
                viewWidth = width * zoom;
            }

            if (viewWidth > modalContent.prop('offsetWidth')) {
                console.log("scaling error");
            }

            canvas.css('transform', 'scale(' + zoom + ')');
            canvas.closest('.ember-jsignature').css('height', viewHeight + 'px');
            canvas.closest('.ember-jsignature').css('width', viewWidth + 'px');

            let ctx = canvas[0].getContext('2d'); // on recupère l'élement DOM depuis l'object Jquery
            ctx.setTransform(1, 0, 0, 1, 0, 0);

            let invZoom = 1.0 / zoom;
            ctx.scale(invZoom, invZoom);
            canvas.data("scale-factor", invZoom);
        },

        /**
         * Hack pour corriger un bug qui décalle la signature (le canvas à une taille fixe, ex "width=1500px" mais à un style="width : 100%" ).
         * On va appliquer au style la même taille que celle du width="xxx"
         *
         * @author jmestres
         */
        didInsertElement: function () {
            this._super(...arguments);
            this.$(window).off("resize");
            this.$(window).on('resize', Ember.run.bind(this, this.resizeWindow));

            this.resizeWindow();

            //"Hack" pour pourvoir initiliser les données à partir d'un controller
            const command = this.get('command');
            //Si une commande est demandée dès l'initialisation on l'exécute (permet de "pré-lancer" une commande "setData")
            if (!Ember.isEmpty(command) && !Ember.isEmpty(command.command)) {
                const args = command.hasOwnProperty('args') ? command.args : [];
                const cb = command.hasOwnProperty('cb') ? command.cb : function () {};
                cb(this.jSignature(command.command, ...args));
            }

            if (this.get('showUndoButton')) {
                let btn = this.$('input[type="button"]');
                btn.attr('value', this.get('intl').t('global.effacer'));
                btn.addClass('btn btn-primary');
                if (Ember.$('.i-signature-fullscreen-actions').length) {
                    btn.css('left', 'auto');
                    btn.css('right', '20%');
                    btn.css('bottom', '10px');
                    btn.detach().appendTo(Ember.$('.i-signature-fullscreen-actions')[0]);
                } else {
                    btn.css('margin', '0 auto 0 auto');
                    btn.css('left', '0');
                    btn.css('right', '0');
                    btn.css('bottom', '-16px');
                    btn.parent().css('bottom', '0');
                    btn.parent().css('position', 'fixed');
                }
            }
        },
        /**
         *
         * @return {[type]} [description]
         */
        didUpdateAttrs: function () {
            this._super(...arguments);
            this.$(window).off("resize");
            this.$(window).on('resize', Ember.run.bind(this, this.resizeWindow));

            this.resizeWindow();
        }
    });
});