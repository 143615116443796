define('ceag-lot3-front/serializers/offline/utilisateur-prestashop-informations', ['exports', 'ceag-lot3-front/serializers/offline/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        // attrs: {
        //     infos_prestashop: {
        //         serialize: false,
        //         deserialize: 'records'
        //     },
        //     abonnements_pro: {
        //         serialize: false,
        //         deserialize: false,
        //     },
        //     role: {
        //         serialize: false,
        //         deserialize: true
        //     },
        // }
    });
});