define('ceag-lot3-front/controllers/login', ['exports', 'ceag-lot3-front/mixins/pending-object', 'ceag-lot3-front/config/environment'], function (exports, _pendingObject, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_pendingObject.default, {

        session: Ember.inject.service(),
        platform: Ember.inject.service('ember-cordova/platform'),
        offlineStore: Ember.inject.service('offline-store'),

        intl: Ember.inject.service(),

        forgetPasswordOpen: false,

        actions: {

            openForgotPassword: function () {
                this.set('forgetPasswordOpen', true);
            },

            login: async function () {

                //Avant de se logger, on va controller, si des tâches de synchro sont à faire
                //et que le dernier login (email) utilisé n'est pas le même que celui avec lequel
                //l'utilisateur tente de se logger, on alert que les datas seront perdues !
                let lastLoginUsed = localStorage.getItem('ceag_last_login_used');
                let currentLogin = this.get('model.loginModel.identification');

                if (_environment.default['offline-store-sync'].enableOfflineStoreSync) {

                    let syncTasks = await this.get('offlineStore').findAll('sync-task');

                    if (currentLogin != lastLoginUsed && syncTasks.get('length') > 0) {
                        try {

                            await this.get('confirmationService').confirm(this.get('intl').t('synchro.alert_titre'), Ember.String.htmlSafe(this.get('intl').t('synchro.alert_content', { currentLogin, lastLoginUsed })), {

                                okLabel: Ember.String.htmlSafe(this.get('intl').t('synchro.alert_ok')),
                                cancelLabel: Ember.String.htmlSafe(this.get('intl').t('synchro.alert_cancel')),

                                okType: 'danger',
                                cancelType: 'primary',

                                typeAlert: 'warning'
                            });

                            //OK => On supprime les tâches offline
                            syncTasks = syncTasks.toArray();
                            let oTask;
                            while (!Ember.isEmpty(oTask = syncTasks.shiftObject())) {
                                await oTask.destroyRecord();
                            }
                        }
                        //Annuler => on ne se log pas !
                        catch (e) {
                            return false;
                        }
                    }
                }

                this.goPending(this.get('session').authenticate('authenticator:myjwt', this.get('model.loginModel')).then(() => {
                    this.get('messageService').reset();

                    //Si mobile on mémorise les infos de connexion
                    if (this.get('platform.isCordova')) {
                        this.get('offlineInfosService').storeCredential(this.get('model.loginModel'));
                    }

                    //On garde l'email de dernier log
                    localStorage.setItem('ceag_last_login_used', this.get('model.loginModel.identification'));

                    this.transitionToRoute(this.get('session.data.authenticated.is_particulier') ? 'in.index' : 'device');
                }).catch(reason => {
                    console.error('Erreur de login', reason);
                    this.get('messageService').error(this.get('intl').t('login.erreur'));
                }));
            },

            reset: function () {

                const adapter = this.get('store').adapterFor('application');

                let resetPassPromise = adapter.apiCall('request_reset_password', {
                    data: {
                        _username: this.get('model.forgotPasswordModel.username')
                    },
                    method: 'POST'
                });

                resetPassPromise.then(() => {
                    this.set('forgetPasswordOpen');
                    this.get('messageService').success(this.get('intl').t('resetpwd.demande_succes'));
                }).catch(error => {
                    console.error("reset-confirm failed !", error);
                    this.get('messageService').error(this.get('intl').t('resetpwd.demande_erreur'));
                });

                this.goPending(resetPassPromise);
            }

        }

    });
});