define('ceag-lot3-front/services/ecommerce-infos', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        session: Ember.inject.service(),
        store: Ember.inject.service(),

        currentUser: Ember.computed('session.data.authenticated.id_utilisateur', function () {
            return this.get('store').peekRecord('utilisateur', this.get('session.data.authenticated.id_utilisateur'));
        }),
        prestashopInfo: Ember.computed.alias('currentUser.infos_prestashop'),

        getInfoPrestashop: function (fieldName) {
            return this.get('prestashopInfo').get(fieldName);
        }

    });
});