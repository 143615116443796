define('ceag-lot3-front/controllers/in/utilisateurs', ['exports', 'ceag-lot3-front/mixins/filtered-list'], function (exports, _filteredList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_filteredList.default, {

        intl: Ember.inject.service(),
        session: Ember.inject.service(),

        queryParams: ['sort', 'search'],

        /////////////////////////////
        // FilteredList param

        stringSearchType: 'contain',
        dataListKeyname: 'model',
        //Les filtres
        listFilters: [{ name: 'search', type: 'string', dataKey: ['mail', 'nom', 'prenom'] }],
        search: '',

        // FilteredList param
        /////////////////////////////


        sort: '',

        filter: null,

        sortDefinition: Ember.computed('sort', function () {
            let sort = this.get('sort');
            if (sort && sort.charAt(0) != '-') {
                return [sort + ':asc'];
            } else if (sort) {
                return [sort.substring(1) + ':desc'];
            }
            return ['id:asc'];
        }),
        sortedUsers: Ember.computed.sort('filteredList', 'sortDefinition'),

        actions: {
            editUser: function (utilisateur) {
                this.transitionToRoute('in.utilisateurs.edit', utilisateur.get('id'));
            },
            deleteUser: function (user) {

                if (user.get('id') == this.get('session.data.authenticated.id_utilisateur')) {
                    return this.get('confirmationService').alert(this.get('intl').t('utilisateur.suppression_admin.titre'), this.get('intl').t('utilisateur.suppression_admin.contenu'));
                }
                return this.get('confirmationService').confirm(this.get('intl').t('utilisateur.suppression.titre'), this.get('intl').t('utilisateur.suppression.contenu')).then(() => {
                    return user.destroyRecord();
                });
            }
        }
    });
});