define('ceag-lot3-front/controllers/in/index', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        lot1MonLogoURL: '',

        init() {
            this._super(...arguments);
            this.set('lot1MonLogoURL', `${_environment.default.CEAG.urlECommerce}professionnel/module/ceaglogo/showLogo`);
        }

    });
});