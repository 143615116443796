define('ceag-lot3-front/components/ui/chemin-fer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        serviceScreen: Ember.inject.service('screen'),
        intl: Ember.inject.service(),

        link: true, //Les éléments du chemin de fer doivent-ils être lié (fléchés)

        action: function () {},

        actions: {
            goToStep: function (step) {
                this.get('action')(step);
            },
            goToDisabled: function () {
                if (this.get('disabled')) {
                    this.get('confirmationService').alert(this.get('intl').t('misc.chemin_fer.disabled.titre'), this.get('intl').t('misc.chemin_fer.disabled.contenu'));
                } else {
                    this.get('confirmationService').alert(this.get('intl').t('misc.chemin_fer.notallow.titre'), this.get('intl').t('misc.chemin_fer.notallow.contenu'));
                }
            }
        }
    });
});