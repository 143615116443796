define('ceag-lot3-front/models/validations/saisie-objet-formulaire', ['exports', 'ember-cp-validations', 'ember-moment/computeds/moment'], function (exports, _emberCpValidations, _moment2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({

        donnee_courte: [(0, _emberCpValidations.validator)('presence', {
            presence: true,

            disabled: Ember.computed('model.objet_formulaire.{type,is_obligatoire}', function () {
                let type = this.get('model.objet_formulaire.type');
                //Pas actif si pas obligatoire ou champ type signature/photo/textarea
                return !this.get('model.objet_formulaire.is_obligatoire') || type == 'i_signature' || type == 'i_dessin' || type == 'i_textarea' || type == 'i_picture';
            }),
            message: function () {

                let typeChamp = this.get('model.objet_formulaire.type');

                if (typeChamp == 'i_checkbox') return "Vous devez cocher cette case pour continuer";else if (typeChamp == 'i_radio') return "Veuillez sélectionner une valeur";else return null; //Message par defaut
            }
        }),

        //Taille Min
        (0, _emberCpValidations.validator)('length', {
            min: Ember.computed.alias('model.objet_formulaire.proprietes.min'),
            allowBlank: true, //Si obligatoire la validation "presence" fera le job
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.min}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_text' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.min')));
            })
        }),

        //Taille Max
        (0, _emberCpValidations.validator)('length', {
            max: Ember.computed.alias('model.objet_formulaire.proprietes.max'),
            allowBlank: true, //Si obligatoire la validation "presence" fera le job
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.max}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_text' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.max')));
            })
        }),

        //Pattern
        (0, _emberCpValidations.validator)('format', {
            regex: Ember.computed.alias('model.objet_formulaire.proprietes.pattern'),
            message: 'La saisie n\'est pas conforme au format attendu',
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.pattern}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_text' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.pattern')));
            })
        }),

        //Champ nombre
        (0, _emberCpValidations.validator)('number', {
            allowString: true,
            integer: true,
            allowBlank: true, //Si obligatoire, la première validation 'presence' fera le job
            disabled: Ember.computed('model.objet_formulaire.type', function () {
                return this.get('model.objet_formulaire.type') !== 'i_number';
            })
        }),
        //Min
        (0, _emberCpValidations.validator)('number', {
            allowBlank: true, //Si obligatoire, la première validation 'presence' fera le job
            allowString: true,
            gte: Ember.computed.alias('model.objet_formulaire.proprietes.min'),
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.min}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_number' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.min')));
            })
        }),
        //Max
        (0, _emberCpValidations.validator)('number', {
            allowBlank: true, //Si obligatoire, la première validation 'presence' fera le job
            allowString: true,
            lte: Ember.computed.alias('model.objet_formulaire.proprietes.max'),
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.max}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_number' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.max')));
            })
        }),

        //Champ date (format)
        (0, _emberCpValidations.validator)('date', {
            errorFormat: 'DD/MM/YYYY',
            disabled: Ember.computed('model.objet_formulaire.type', function () {
                return this.get('model.objet_formulaire.type') !== 'i_date';
            })
        }),
        //Date min
        (0, _emberCpValidations.validator)('date', {
            onOrAfter: (0, _moment2.default)('model.objet_formulaire.proprietes.min', 'DD/MM/YYYY'),
            precision: 'day',
            errorFormat: 'DD/MM/YYYY',
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.min}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_date' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.min')));
            })
        }),
        //Date max
        (0, _emberCpValidations.validator)('date', {
            onOrBefore: (0, _moment2.default)('model.objet_formulaire.proprietes.max', 'DD/MM/YYYY'),
            precision: 'day',
            errorFormat: 'DD/MM/YYYY',
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.max}', function () {
                return !(this.get('model.objet_formulaire.type') == 'i_date' && !Ember.isEmpty(this.get('model.objet_formulaire.proprietes.max')));
            })
        }),

        //Email, cas particulier de l'email co-contractant, on force une validation "email"
        (0, _emberCpValidations.validator)('format', {
            type: 'email',
            allowNonTld: false,
            allowBlank: true,
            disabled: Ember.computed('model.objet_formulaire.{type,proprietes.max}', function () {
                let rub = this.get('model.objet_formulaire.rubrique.libelle');
                return Ember.isEmpty(rub) || !rub.startsWith('CC_Email');
            })
        })],

        donnee_longue: [(0, _emberCpValidations.validator)('presence', {
            presence: true,

            disabled: Ember.computed('model.objet_formulaire.{type,is_obligatoire}', 'model.isSignature', function () {
                let type = this.get('model.objet_formulaire.type');

                //cas particulier de la signature co-contractant, forcement obligatoire
                // JM 2018-11-21 : Même comporement qu'un objet classique
                // if( this.get('model.isSignatureCoContractant') ){
                //     return false;
                // }

                //Pas actif si pas obligatoire et pas champ type signature/photo/textarea
                return !this.get('model.objet_formulaire.is_obligatoire') || type !== 'i_signature' && type !== 'i_dessin' && type != 'i_picture' && type != 'i_textarea';
            }),
            message: function () {

                let typeChamp = this.get('model.objet_formulaire.type');

                if (typeChamp == 'i_signature' || typeChamp == 'i_dessin') return "Cette signature est obligatoire pour poursuivre votre saisie";else if (typeChamp == 'i_picture') return "Veuillez prendre ou sélectionner une photo";else return null; //Message par defaut
            }
        })]

    });
});