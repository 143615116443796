define('ceag-lot3-front/components/ember-jsignature', ['exports', 'ember-jsignature/components/ember-jsignature'], function (exports, _emberJsignature) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberJsignature.default;
    }
  });
});