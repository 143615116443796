define('ceag-lot3-front/services/message', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        message: '',
        iconMessage: '',
        typeMessage: 'warning',

        isMessage: Ember.computed.notEmpty('message'),

        isDismiss: false,
        notDismiss: Ember.computed.not('isDismiss'),

        isVisible: Ember.computed.and('isMessage', 'notDismiss'),

        _setMessage: function (msg, type, icon) {

            this.set('message', msg);
            this.set('typeMessage', type);
            this.set('iconMessage', icon);
            this.set('isDismiss', false);
        },

        error: function (msg, showIcon = true) {
            this._setMessage(msg, 'danger', showIcon ? 'exclamation-triangle' : '');
        },

        warning: function (msg, showIcon = true) {
            this._setMessage(msg, 'warning', showIcon ? 'exclamation-circle' : '');
        },

        info: function (msg, showIcon = true) {
            this._setMessage(msg, 'info', showIcon ? 'info-circle' : '');
        },

        success: function (msg, showIcon = true) {
            this._setMessage(msg, 'success', showIcon ? 'check-circle' : '');
        },

        reset: function () {
            this.set('message', null);
            this.set('isDismiss', false);
        },

        actions: {
            dismiss: function () {
                this.set('isDismiss', true);
                return false;
            }
        }
    });
});