define('ceag-lot3-front/misc/image-resize', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (imageData, width, height, quality = 1, mimeType = 'image/jpeg') {

        var canvas = document.createElement("canvas");

        return new Ember.RSVP.Promise(function (resolve, reject) {

            var img = document.createElement('img');

            img.onload = function () {

                //On détermine le % de scale
                let scale = Math.min(width / this.width, height / this.height);

                //On adapte la taille du canvas
                canvas.width = this.width * scale;
                canvas.height = this.height * scale;

                //Puis on convertit
                let context = canvas.getContext("2d");
                context.scale(scale, scale);
                context.drawImage(this, 0, 0);

                let resizedImage = canvas.toDataURL(mimeType, quality);

                canvas.remove();
                img.remove();

                resolve(resizedImage);
            };

            img.onerror = function () {
                canvas.remove();
                img.remove();
                reject(this);
            };

            img.src = imageData;
        });
    };
});