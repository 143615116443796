define('ceag-lot3-front/services/offline-infos', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        session: Ember.inject.service(),

        //Clé dépendante du compte avec lequel l'utilisateur est connecté, permettant de stocker "le token" en fonction
        //du compte et idem pour les options (offline).
        //1 compte = 1 serie d'info (token, option, logo...)
        accountKey: Ember.computed.alias('session.data.authenticated.id_admin'),
        accountKeyCHange: Ember.observer('accountKey', function () {
            this.loadDatas();
        }),

        //Infos sur les options lot1/lot3
        options: null,

        //Infos de logo
        logo: null,

        //Un ID unique pour autorisé la mise à diposition des formulaire pour le device "courant"
        tokenDevice: null,

        //Dernière informations de session connue
        lastSessionDatas: null,

        /**
         * On va chercher les options connu dans le localStorage  à l'initialisation.
         * Le options seront changée à la connexion, si possible (si online).
         *
         * @return {[type]}
         * @author jmestres
         */
        init: function () {
            this._super(...arguments);
            this.loadDatas();
        },

        loadDatas: function () {

            if (Ember.isEmpty(this.get('accountKey'))) {
                console.error('Erreur de récupération de la clé de compte ! On stop le chargement des datas "offline-info".', this.get('session.data.authenticated'));
                return;
            }

            try {
                let opt = JSON.parse(localStorage.getItem(this.entryName('ceag_option')));
                this.set('options', opt);
            } catch (e) {
                this.set('options', {});
            }

            try {
                let logo = localStorage.getItem(this.entryName('ceag_logo'));
                this.set('logo', logo);
            } catch (e) {
                this.set('logo', null);
            }

            try {
                let lastSessionDatas = localStorage.getItem(this.entryName('ceag_last_session_datas'));
                this.set('lastSessionDatas', lastSessionDatas === 'undefined' ? null : JSON.parse(lastSessionDatas));
            } catch (e) {
                this.set('lastSessionDatas', null);
            }

            try {
                let tokenDevice = localStorage.getItem(this.entryName('ceag_device_token'));
                this.set('tokenDevice', tokenDevice === 'undefined' ? null : tokenDevice);
            } catch (e) {
                this.set('tokenDevice', null);
            }
        },

        /**
         * Génére un nome d'entrée en fonction de la clé de compte.
         * ex : ceag_device_token => ceag_device_token[xx]
         * @author mestresj
         * @param  {[type]} name [description]
         * @return {[type]}      [description]
         */
        entryName: function (name) {
            let n = `${name}[${this.get('accountKey')}]`;
            return n;
        },

        initOptionsDatas: function (options) {
            localStorage.setItem(this.entryName('ceag_option'), JSON.stringify(options));
            this.set('options', options);
        },

        updateLogo: function (base64Logo) {
            localStorage.setItem(this.entryName('ceag_logo'), base64Logo);
            this.set('logo', base64Logo);
        },

        resetLogo: function () {
            localStorage.removeItem(this.entryName('ceag_logo'));
            this.set('logo', null);
        },

        updateSessionData: function (sessionDatas) {
            localStorage.setItem(this.entryName('ceag_last_session_datas'), JSON.stringify(sessionDatas));
            this.set('lastSessionDatas', sessionDatas);
        },

        resetSessionData: function () {
            localStorage.setItem(this.entryName('ceag_last_session_datas'), null);
            this.set('lastSessionDatas', null);
        },

        updateDeviceToken: function (token) {
            if (Ember.isEmpty(token)) {
                localStorage.removeItem(this.entryName('ceag_device_token'));
            } else {
                localStorage.setItem(this.entryName('ceag_device_token'), token);
            }

            this.set('tokenDevice', token);
        },

        /**
         * Mémorise les informations d'identification de manière sécurisé
         * @param  {login} loginModel Objet contenant les informations d'identification
         * @return {Promise} promise résolu si le sauvegarde est correctement effectuée, rejetée sinon
         * @author jmestres
         */
        storeCredential: function (loginModel) {

            return new Ember.RSVP.Promise((resolve, reject) => {

                //Si mobile on mémorise les infos de connexion
                cordova.plugins.SecureKeyStore.set(function () {
                    cordova.plugins.SecureKeyStore.set(function () {
                        resolve(loginModel);
                    }, function (error) {
                        console.error("Erreur de mémorisation des informations d'identitifation (2)", error);
                        reject(error);
                    }, "usr.password", loginModel.get('password'));
                }, function (error) {
                    console.error("Erreur de mémorisation des informations d'identitifation (1)", error);
                    reject(error);
                }, "usr.identification", loginModel.get('identification'));
            });
        },

        /**
         * Retourne les informations d'identification si existantes
         * @return {Promise} promise résolu avec l'Objet contenant les informations d'identification , rejetée si informations non trouvée
         * @author jmestres
         */
        getCredential: function () {

            return new Ember.RSVP.Promise((resolve, reject) => {

                cordova.plugins.SecureKeyStore.get(function (identification) {
                    cordova.plugins.SecureKeyStore.get(function (password) {

                        resolve({
                            identification,
                            password
                        });
                    }, function (error) {
                        console.error("Erreur de récupération des informations d'identitifation (2)", error);
                        reject(error);
                    }, "usr.password");
                }, function (error) {
                    console.error("Erreur de récupération des informations d'identitifation (1)", error);
                    reject(error);
                }, "usr.identification");
            });
        },

        /**
         * Supprime les informations d'identification si existantes
         * @return {Promise} promise
         * @author jmestres
         */
        removeCredential: function () {

            return new Ember.RSVP.Promise((resolve /*, reject*/) => {

                cordova.plugins.SecureKeyStore.remove(function () {
                    cordova.plugins.SecureKeyStore.remove(function () {
                        resolve();
                    }, function () {
                        resolve();
                    }, "usr.password");
                }, function () {
                    resolve();
                }, "usr.identification");
            });
        }

    });
});