define('ceag-lot3-front/components/modal/confirmation-popup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        dialogHeaderClass: Ember.computed('confirmationService.options.typeAlert', function () {
            let type = this.get('confirmationService.options.typeAlert');
            return Ember.isEmpty(type) ? null : `alert-modal alert-${type}`;
        }),

        actions: {

            closeAction: function () {
                return this.get('confirmationService').rejectPromise();
            },

            confirmAction: function () {
                return this.get('confirmationService').resolvePromise();
            }
        }
    });
});