define('ceag-lot3-front/services/fingerprint', ['exports', 'ember-fingerprintjs2'], function (exports, _emberFingerprintjs) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        platform: Ember.inject.service('ember-cordova/platform'),

        /**
         * Un "identifiant unique" pour notre device
         *
         * @type {[type]}
         */
        fingerprint: null,

        /**
         * Une promise permettant à l'application d'attendre l'initialisation du fingerPrint
         * @type {[type]}
         */
        fingerprintPromise: null,

        init() {

            this._super(...arguments);

            //Pour un mobile, on prend le UUID (? equivalent à un IMEI)
            if (this.get('platform.isCordova')) {

                let uuidPromise = new Ember.RSVP.Promise((resolve, reject) => {

                    document.addEventListener("deviceready", () => {
                        let uuid = device.uuid;
                        this.set('fingerprint', uuid);
                        resolve(uuid);
                    }, error => {
                        reject(error);
                    });
                });

                this.set('fingerprintPromise', uuidPromise);
            } else {

                this.set('fingerprintPromise', (0, _emberFingerprintjs.fingerprint)(this.get('options') || {}).then(fingerprint => {
                    this.set('fingerprint', fingerprint.result);
                }));
            }
        },

        //Options "custom" pas trop restrictive pour définir un ID unique de device via "fingerprint2.JS"...
        options: {
            // excludeColorDepth: false,
            excludeCpuClass: false,
            excludePlatform: false,
            // excludeWebGL: false,
            // excludeTouchSupport: false,
            excludeHardwareConcurrency: false,
            excludeDeviceMemory: false,

            swfContainerId: '',
            swfPath: '',
            userDefinedFonts: [],
            excludeUserAgent: true,
            excludeLanguage: true,
            excludeScreenResolution: true,
            excludeAvailableScreenResolution: true,
            excludeTimezoneOffset: true,
            excludeSessionStorage: true,
            excludeIndexedDB: true,
            excludeAddBehavior: true,
            excludeOpenDatabase: true,
            excludeDoNotTrack: true,
            excludeCanvas: true,
            excludeAdBlock: true,
            excludeHasLiedLanguages: true,
            excludeHasLiedResolution: true,
            excludeHasLiedOs: true,
            excludeHasLiedBrowser: true,
            excludeJsFonts: true,
            excludeFlashFonts: true,
            excludePlugins: true,
            excludeIEPlugins: true,
            excludePixelRatio: true,
            excludeWebGLVendorAndRenderer: true,
            excludeAudioFP: true
        }
    });
});