define('ceag-lot3-front/adapters/online/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ceag-lot3-front/config/environment', 'ember-inflector'], function (exports, _emberData, _dataAdapterMixin, _environment, _emberInflector) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {

        // Deprecated !!
        // authorizer: 'authorizer:token',

        authorize(xhr) {

            let { token } = this.get('session.data.authenticated');
            if (Ember.isPresent(token)) {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }
        },

        host: _environment.default.apiHost,
        namespace: _environment.default.apiNamespace,

        // /* par defaut, ember met au pluriel le nom du model pour construire l'url: echange => /api/echanges/3,
        //  la surchage de pathForType permet d'inhiber cela */
        pathForType: function (type) {
            return Ember.String.underscore((0, _emberInflector.pluralize)(type));
        },

        //Permet de faire des requête AJAX "perso" avec en retour une promise
        ajaxService: Ember.inject.service('ajax'),
        session: Ember.inject.service('session'),

        ajaxCall: function (path, opts, withAPIAuth = false) {
            var options = opts || {};

            if (withAPIAuth && !Ember.isEmpty(this.get('session.data.authenticated.token'))) options.headers = { 'Authorization': 'Bearer ' + this.get('session.data.authenticated.token') };

            return this.get('ajaxService').request(path, options);
        },

        apiCall: function (path, opts) {

            return this.ajaxCall(this.host + '/' + this.namespace + '/' + path, opts, true);
        }

    });
});