define('ceag-lot3-front/models/saisie-super-calque-formulaire', ['exports', 'ember-data', 'ceag-lot3-front/mixins/computed-etat-saisie', 'offline-store-sync/offline-model/offline-editable-model'], function (exports, _emberData, _computedEtatSaisie, _offlineEditableModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend(_computedEtatSaisie.default, {

        saisie_page_formulaire: _emberData.default.belongsTo('saisie-page-formulaire'),

        super_calque_formulaire: _emberData.default.belongsTo('super-calque-formulaire'),

        etat_saisie: _emberData.default.attr('string', { defaultValue: 'new' }),

        saisies_objet_formulaire: _emberData.default.hasMany('saisie-objet-formulaire'),

        //Les objets à saisir (non automatique/hidden)
        saisies_objet_formulaire_not_hidden: Ember.computed.filterBy('saisies_objet_formulaire', 'isHidden', false),
        isHidden: Ember.computed.empty('saisies_objet_formulaire_not_hidden'),

        //Pour avoir les "étapes" de saisie toujours dans le bon ordre
        saisieSortin: ['ordre:asc', 'objet_formulaire.id:asc'],
        saisies_objet_formulaire_ordered: Ember.computed.sort('saisies_objet_formulaire_not_hidden', 'saisieSortin'),

        ordre: Ember.computed.alias('super_calque_formulaire.ordre'),

        //Pour le chemin de fer
        libelle: Ember.computed.alias('super_calque_formulaire.libelle'),

        libelleCourt: Ember.computed.alias('super_calque_formulaire.proprietes.short-label'),

        /**
         * Le super calque ou ses objets de saisie sont-ils modifié et non sauvegardés
         * @author mestresj
         * @return {Boolean} [description]
         */
        hasDeepDirtyAttributes: function () {
            for (const saisieObjetFormulaire of this.get('saisies_objet_formulaire').toArray()) {
                if (saisieObjetFormulaire.get('hasDirtyAttributes')) {
                    return true;
                }
            }
            return this.get('hasDirtyAttributes');
        }
    });
});