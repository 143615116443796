define('ceag-lot3-front/components/formulaire/saisie/objets/misc/i-signature-popup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        //classNameBindings : ['isPaysage:i-signature-fullscreen-popup'],
        isPaysage: Ember.computed.alias('screenService.isPaysage'),

        signatureService: Ember.inject.service('signature'),
        screenService: Ember.inject.service('screen'),

        objet: Ember.computed.alias('signatureService.objet'),

        //Permet d'échanger des commandes sur la signaure
        signatureCommand: null,

        objetWidth: Ember.computed('objet.objet_formulaire.width', function () {
            let width = this.get('objet.objet_formulaire.width') || 576;
            return width + "px";
        }),

        objetHeight: Ember.computed('objet.objet_formulaire.height', function () {
            let height = this.get('objet.objet_formulaire.height') || 326;
            return height + "px";
        }),

        updateSignatureData: function () {
            let data = [];
            try {
                data = JSON.parse(this.get('objet.donnee_longue'));
            } catch (e) {
                console.log("donnee_longue", this.get('objet.donnee_longue'));
            }
            this.set('signatureCommand', { command: 'setData', args: [data, 'native'] });
        },

        willInsertElement: function () {
            this.updateSignatureData();
        },

        actions: {
            onChange: function (datas) {
                this.get('objet').set('donnee_longue', JSON.stringify(datas));
            }

            /*
            actions: {
                onChange: function(datas) {
                     // Récupération de l'objet courant une bonne fois pour
                    let currentSaisieObject = this.get('objet');
                    let donneeLongue = currentSaisieObject.get('donnee_longue');
                     // Première initialisation
                    if (!isEmpty(datas) && isEmpty(donneeLongue)) {
                        currentSaisieObject.set('donnee_longue', datas);
                    } else {
                        currentSaisieObject.notifyPropertyChange('donnee_longue');
                    }
                 },
            }
            */
        } });
});