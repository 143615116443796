define('ceag-lot3-front/models/option', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        libelle: _emberData.default.attr('string'),
        reference: _emberData.default.attr('string'),
        quantite: _emberData.default.attr('number')

    });
});