define('ceag-lot3-front/components/formulaire/saisie/objets/main-objet-saisie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            onUpdateAction: function (value) {

                let onUpdate = this.get('onUpdate');

                if (!Ember.isEmpty(onUpdate)) {
                    onUpdate(this.get('objet'), value);
                }
            }
        }
    });
});