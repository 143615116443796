define('ceag-lot3-front/controllers/device', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        session: Ember.inject.service(),
        store: Ember.inject.service(),
        fingerprint: Ember.inject.service(),
        intl: Ember.inject.service(),

        actions: {
            onSubmitAction: async function () {

                let adapter = this.get('store').adapterFor('application');
                let deviceId = this.get('fingerprint.fingerprint');

                try {
                    //On demande un token pour notre device
                    let registerDatas = await adapter.apiCall('device/register/' + deviceId);
                    //OK, on sauvegarde
                    this.get('offlineInfosService').updateDeviceToken(registerDatas.token_device);
                    //Puis direction l'appli
                    return this.transitionToRoute('in.index');
                } catch (e) {
                    let noJeton = !Ember.isEmpty(e.payload) && !Ember.isEmpty(e.payload.no_jeton) && e.payload.no_jeton;
                    let message = this.get('intl').t('activation_device.error.message', { comp: this.get('intl').t(noJeton ? 'activation_device.error.complement_no_jeton' : 'activation_device.error.complement_autre') });
                    this.get('confirmationService').alert('Erreur d\'activation', Ember.String.htmlSafe(message), { typeAlert: 'danger' });
                }
            },

            onHiddenAction: function () {
                this.get('session').invalidate();
            }
        }

    });
});