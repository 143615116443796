define('ceag-lot3-front/routes/in/saisies', ['exports', 'ceag-lot3-front/mixins/custom-loading-slider', 'moment'], function (exports, _customLoadingSlider, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_customLoadingSlider.default, {

        intl: Ember.inject.service(),
        fileCache: Ember.inject.service('file-cache'),

        model: function (params) {

            let query = {};
            let goSearch = false;
            let typeObjet = '';
            let objet = null;

            if (params.type_saisie != 'general') {

                typeObjet = params.type_saisie == 'form' ? 'formulaire' : 'processus';
                let objetId = params.id;

                //On récupère le Formulaire ou le Processus lié à la recherche
                objet = this.get('store').peekRecord(typeObjet, objetId);

                //Etat saisie
                query['etat_saisie'] = params.period == 'encours' ? ['new', 'pending'] : ['done'];

                //Quel critère de recherche ? (formulaire ou processus)
                query[typeObjet] = objetId;

                //Cas particulier, pour une auto-complétion, on recherche sur tous les ID de formulaire
                //qui sont liés au même processus
                if (params.period == 'autocom') {
                    let processus = this.get('store').peekRecord('processus', objet.get('autoRemplissageProcessusId'));
                    let formulairesIds = [];
                    processus.get('last_version.etapes').forEach(etape => {
                        formulairesIds.pushObject(etape.get('formulaire.id'));
                    });

                    query[typeObjet] = formulairesIds;
                }

                //Saisie de - d'un mois
                if (params.period == 'recent') {
                    //Today - 1 mois
                    query['updated_from'] = parseInt((0, _moment.default)().subtract(1, 'M').toDate().getTime() / 1000);
                    goSearch = true;
                }
            }
            //Recherche general
            else {
                    typeObjet = 'all';

                    query['etat_saisie'] = ['done'];

                    //Si un abonnement est sélectionné on ajoute aux filtres
                    if (!Ember.isEmpty(params.abonnement)) {
                        let abonnement = this.get('store').peekRecord('abonnement', params.abonnement);

                        //Processus
                        if (abonnement.get('is_pack')) {
                            query['processus'] = abonnement.get('processus.id');
                            typeObjet = 'processus';
                        }
                        //Formulaire
                        else {
                                query['formulaire'] = abonnement.get('formulaire.id');
                                typeObjet = 'formulaire';
                            }
                        goSearch = true;
                    }
                }

            if (params.period == 'done' || params.period == 'autocom' || params.period == 'all' || params.period == 'encours' || params.period == 'older') {

                if (!Ember.isEmpty(params.debut)) {
                    query[params.period == 'encours' ? 'intervention_from' : 'updated_from'] = parseInt(params.debut.getTime() / 1000);
                    goSearch = true;
                }
                if (!Ember.isEmpty(params.fin)) {
                    query[params.period == 'encours' ? 'intervention_to' : 'updated_to'] = parseInt(params.fin.getTime() / 1000);
                    goSearch = true;
                }

                if (!Ember.isEmpty(params.search)) {
                    query['search'] = params.search;
                    goSearch = true;
                }
            }

            if (!goSearch && params.period == 'encours') {
                goSearch = true;
            }

            let resultatRechercheSaisie = null;
            if (goSearch) {

                //Cas particulier de l'encours d'un processus
                let typeObjetRecherche = params.type_saisie == 'proc' && params.period == 'encours' ? 'saisie-processus' : 'saisie-formulaire';

                resultatRechercheSaisie = this.get('store').query(typeObjetRecherche, query, { reload: true }).then(saisies => {
                    if (['recent', 'encours'].indexOf(params.period) >= 0 && typeObjetRecherche == 'saisie-formulaire') {
                        saisies.forEach(saisie => {
                            /* this.get('fileCache').getSaisiePreviewUrl(saisie.id).then((cacheURL) => {
                                console.log("cacheURL for" + saisie.id, cacheURL);
                            }); */
                            this.get('fileCache').processSaisieFormulairePreview(saisie);
                        });
                    }
                    return saisies;
                });
            }

            return Ember.RSVP.hash({
                //Ici on récupère toujours des saisiesFormulair (même si on rechecher par processus)
                saisies: resultatRechercheSaisie,

                typeObjet: typeObjet,
                objet: objet,
                period: params.period,
                goSearch: goSearch,
                abonnements: this.modelFor('in').abonnements.all,
                utilisateur: this.modelFor('in').utilisateur

            }).then(this.updateTitle.bind(this));
        },

        updateTitle: function (datas) {

            let intl = this.get('intl');

            let periodeTitle = intl.t('recherche.titre.period.' + datas.period);
            let typeTitle = intl.t(datas.typeObjet == 'formulaire' ? 'global.formulaire' : 'global.pack');

            if (datas.period != 'all' && !Ember.isEmpty(datas.objet)) {
                datas.title = `${periodeTitle} : ${typeTitle} "${datas.objet.get('aboLibelle')}"`;
            } else {
                datas.title = `${periodeTitle}`;
            }

            this.set('title', datas.title);
            return datas;
        },

        actions: {
            refreshSearch: function () {
                this.refresh();
            }
        }
    });
});