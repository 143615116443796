define('ceag-lot3-front/routes/in/formulaire/saisie/envoi', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        beforeModel() {
            this._super(...arguments);

            if (this.modelFor('in.formulaire.saisie').saisieFormulaire.get('saisies_co_contractant.length') == 0) {
                this.transitionTo('in.formulaire.saisie.envoi');
            }
        }
    });
});