define('ceag-lot3-front/controllers/in/saisies', ['exports', 'ceag-lot3-front/misc/promise-array', 'ember-parachute', 'ceag-lot3-front/misc/period'], function (exports, _promiseArray, _emberParachute, _period) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.myQueryParams = undefined;
    const myQueryParams = exports.myQueryParams = new _emberParachute.default({
        sort: {
            defaultValue: null,
            refresh: false,
            replace: true
        },
        debut: {
            defaultValue: null,
            reset: true,
            refresh: false,
            replace: true,
            serialize(value) {
                return value == null ? '' : typeof value == "string" ? value : value.getTime();
            },
            deserialize(value = '') {
                return new Date(value / 1);
            }
        },
        fin: {
            defaultValue: null,
            refresh: false,
            replace: true,
            serialize(value) {
                return value == null ? '' : typeof value == "string" ? value : value.getTime();
            },
            deserialize(value = '') {
                return new Date(value / 1);
            }
        },
        search: {
            defaultValue: '',
            refresh: false,
            replace: true
        },
        abonnement: {
            defaultValue: null,
            refresh: false,
            replace: true
        }
    });

    /* Pour validation des query params date */
    exports.default = Ember.Controller.extend(_period.default, myQueryParams.Mixin, {

        store: Ember.inject.service(),
        saisie: Ember.inject.service(),
        export: Ember.inject.service(),
        session: Ember.inject.service(),
        intl: Ember.inject.service(),

        isProcessus: Ember.computed.equal('model.typeObjet', 'processus'),

        isEnCours: Ember.computed.equal('model.period', 'encours'),
        isArchives: Ember.computed.equal('model.period', 'older'),
        isAutoComplete: Ember.computed.equal('model.period', 'autocom'),
        isArchivesGeneral: Ember.computed.equal('model.period', 'all'),
        isRecent: Ember.computed.equal('model.period', 'recent'),

        connectionStatus: Ember.inject.service('connection-status'),
        isOffline: Ember.computed.alias('connectionStatus.isOffline'),
        isOnline: Ember.computed.alias('connectionStatus.isOnline'),

        pageClass: Ember.computed('model.period', function () {
            let period = this.get('model.period');
            if (period == 'all') {
                return "archives";
            } else if (period == 'autocom') {
                return "formulaires";
            }

            return "archives";
        }),

        activeFiltre: Ember.computed.or('{isArchives,isEnCours,isAutoComplete,isArchivesGeneral}'),

        sortDefinition: Ember.computed('sort', function () {
            let sort = this.get('sort');
            if (sort && sort.charAt(0) != '-') {
                return [sort + ':asc'];
            } else if (sort) {
                return [sort.substring(1) + ':desc'];
            }
            return ['id:asc'];
        }),

        sortedSaisies: Ember.computed.sort('model.saisies', 'sortDefinition'),

        showPreview: false,
        saisieFormulaireForPreview: null,

        showReedition: false,
        modelForReedition: null,

        optionCSV: Ember.computed.alias('offlineInfosService.options.csv'),

        /**
         * Permet de vérifier (et charger si besoin) la versionFormulaire associé à une saisie.
         * @return {Promise}
         * @author jmestres
         */
        checkFormulaireVersionForSaisie: function (formulaireVersionId) {

            let formVersion = this.get('store').peekRecord('formulaire_version', formulaireVersionId);
            if (Ember.isEmpty(formVersion) || Ember.isEmpty(formVersion.get('formulaire')) || formVersion.get('pages.length') == 0) {

                //Non on va chercher avant de lacner la saisie, sinon erreur.
                return this.get('store').findRecord('formulaire_version', formulaireVersionId, { reload: true });
            }
            return Ember.RSVP.resolve(formVersion);
        },

        actions: {

            clearFilter: function () {

                if (!Ember.isEmpty(this.get('search')) || !Ember.isEmpty(this.get('abonnement')) || !Ember.isEmpty(this.get('debut')) || !Ember.isEmpty(this.get('fin'))) {
                    this.set('debut', null);
                    this.set('fin', null);
                    this.set('abonnement', null);
                    this.set('search', '');
                    this.send('refreshSearch');
                }
            },

            updateAbonnementFilter: function (abonnement) {
                this.set('abonnement', Ember.isEmpty(abonnement) ? null : abonnement.get('id'));
            },

            goSearch: function () {
                this.send('refreshSearch');
            },

            showPreviewSaisie: function (saisie) {
                let saisieFormulaire = this.get('isEnCours') && this.get('isProcessus') ? saisie.get('saisie_en_cours') : saisie;

                // on force l'execution du didReceiveAttrs du component saisie-formulaire-preview:
                this.set('saisieFormulaireForPreview', undefined);
                this.set('saisieFormulaireForPreview', saisieFormulaire);
                this.set('showPreview', true);
            },

            reeditionSaisie: function (saisieFormulaire) {
                if (this.get('isOffline')) {
                    return this.get('confirmationService').alert(this.get('inl').t('recherche.reedition.offline.titre'), this.get('inl').t('recherche.reedition.offline.contenu'));
                } else {

                    let reedition = this.get('store').createRecord('reedition-saisie', {
                        saisie_formulaire: saisieFormulaire,
                        mail: this.get('model.utilisateur.mail')
                    });
                    this.set('showReedition', true);
                    this.set('modelForReedition', reedition);
                }
            },

            onValidReedition: async function () {
                try {
                    await this.get('modelForReedition').save();
                    this.get('store').findAll('compteur', { reload: true });
                    this.get('messageService').success(this.get('intl').t('recherche.reedition.succes'));
                } catch (e) {
                    this.get('modelForReedition').rollbackAttributes();
                    console.error('Erreur lors de la demande ré-édition', e);
                    this.get('confirmationService').alert(this.get('intl').t('recherche.reedition.error.titre'), this.get('intl').t('recherche.reedition.error.contenu'), { typeAlert: 'danger' });
                }

                this.set('showReedition', false);
                this.set('modelForReedition', null);
            },

            onCloseReedition: function () {
                this.get('modelForReedition').rollbackAttributes();
                this.set('showReedition', false);
                this.set('modelForReedition', null);
            },

            /**
             * Renvoi vers la création d'une nouvelle saisie avec auto-remplissage des rubriques à partir d'une saisie archivé (celle choisie ici)
             * @param  {saisieFormulaire} saisieFormulaire la saisie choisie pour le remplissage
             * @author jmestres
             */
            autoCompleteSaisie: function (saisieFormulaire) {
                let idVersionForm = saisieFormulaire.get('formulaire_version.id');
                this.checkFormulaireVersionForSaisie(idVersionForm).then(() => {

                    //On redirige vers nouvelle saisie avec auto-completion des rubriques
                    this.replaceRoute('in.formulaire.saisie', this.get('model.objet.id'), 'new', { queryParams: { autocomplete_from_saisie: saisieFormulaire.get('id') } });
                });
            },

            reprendreSaisie: function (saisie) {
                //Cas des "encours" processus, on à une saisieProcessus et on prend la dernière saisie à faire. Sinon saisieFormulaire=saisie
                let saisieFormulaire = this.get('isEnCours') && this.get('isProcessus') ? saisie.get('saisie_en_cours') : saisie;

                if (saisieFormulaire.get('etat_saisie') == 'done') {
                    return this.get('confirmationService').alert(this.get('intl').t('recherche.reprise_saisie_terminee'));
                }

                //A t-on la bonne version de formulaire ?
                let idVersionForm = saisieFormulaire.get('formulaire_version.id');
                this.checkFormulaireVersionForSaisie(idVersionForm).then(() => {

                    //Quel page/super_calque ?
                    let repriseIndexes = this.get('saisie').getFirstRepriseIndexes(saisieFormulaire);

                    this.transitionToRoute('in.formulaire.saisie.index', saisieFormulaire.get('formulaire.id'), saisieFormulaire.get('id'), { queryParams: repriseIndexes });
                });
            },

            deleteSaisie: function (saisie) {
                let intl = this.get('intl');

                if (this.get('isEnCours') && this.get('isProcessus') && saisie.get('canDelete') === false) {
                    return this.get('confirmationService').alert(intl.t('recherche.suppression.pack_start.titre'), intl.t('recherche.suppression.pack_start.contenu'), { typeAlert: 'warning' });
                }

                return this.get('confirmationService').confirm(intl.t('recherche.suppression.confirmation.titre'), intl.t('recherche.suppression.confirmation.contenu')).then(() => {
                    saisie.destroyRecord();
                });
            },

            exportExcel: async function () {
                let nbSaisie = this.get('model.saisies.length');

                return this.get('confirmationService').confirm(this.get('intl').t('recherche.export.titre'), this.get('intl').t('recherche.export.contenu', { num: nbSaisie })).then(async () => {

                    //On s'arrure d'avoir les "formulaire_version" des saisies à traiter
                    let formVersionIds = this.get('model.saisies').map(saisie => saisie.get('formulaire_version.id')).uniq();
                    await (0, _promiseArray.default)(formVersionIds, async formVersionId => {
                        await this.checkFormulaireVersionForSaisie(formVersionId);
                    });

                    return this.get('export').goExport(this.get('model.saisies'));
                });
            },

            cancelExport: function () {
                this.get('export').cancelExport();
            }

        },

        excel: Ember.inject.service()

    });
});