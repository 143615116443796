define('ceag-lot3-front/components/formulaire/saisie/objets/i-date-objet', ['exports', 'ceag-lot3-front/components/formulaire/saisie/objets/i-navigable-objet'], function (exports, _iNavigableObjet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _iNavigableObjet.default.extend({

        platform: Ember.inject.service('ember-cordova/platform'),

        onUpdate: function () {},

        dtPickerPos: { horizontal: 'auto', vertical: 'auto' }
    });
});