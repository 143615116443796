define('ceag-lot3-front/serializers/offline/saisie-processus', ['exports', 'ceag-lot3-front/serializers/offline/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        // Pour Rappel default :
        //  BelongsTo: { serialize: 'id', deserialize: 'id' }
        //  HasMany: { serialize: false, deserialize: 'ids' }
        attrs: {

            processus: { serialize: 'id', deserialize: 'records' },
            processus_version: { serialize: 'id', deserialize: 'records' },

            saisies_formulaire: { serialize: false, deserialize: 'records' },

            date_mise_a_jour: {
                serialize: false,
                deserialize: 'records'
            },
            date_creation: {
                serialize: false,
                deserialize: 'records'
            },
            complete: {
                serialize: false,
                deserialize: 'records'
            },
            createur: {
                serialize: false,
                deserialize: 'records'
            }

        }
    });
});