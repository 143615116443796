define('ceag-lot3-front/routes/in/processus', ['exports', 'ceag-lot3-front/misc/promise-array', 'ceag-lot3-front/helpers/version-widget'], function (exports, _promiseArray, _versionWidget) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "",

        model: async function (params) {
            let processus = this.get('store').peekRecord('processus', params.processus_id);
            if (processus == null) {
                processus = await this.get('store').findRecord('processus', params.processus_id);
            }

            //Dans le cas du offline, les formulaire liés aux étapes ne sont pas chargés
            await (0, _promiseArray.default)(processus.get('last_version.etapes'), async etape => {

                let formulaire_id = etape.get('formulaire.id');

                if (this.get('store').peekRecord('formulaire', formulaire_id) == null) {
                    await this.get('store').findRecord('formulaire', formulaire_id);
                }
            });

            this.set('title', Ember.String.htmlSafe(processus.get('aboLibelle') + (0, _versionWidget.default)(processus.get('last_version'))));
            return processus;
        }
    });
});