define('ceag-lot3-front/components/ui/infinite-loading-slider', ['exports', 'ceag-lot3-front/components/loading-slider'], function (exports, _loadingSlider) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _loadingSlider.default.extend({

        classNameBindings: ['expanding', 'inplace'],

        inplace: true,
        isLoading: true,
        color: ['#7aa4dc', '#19428a', '#a0d785', '#ce112d', '#f98e2c', '#f5b335', '#ffce00'],
        expanding: true,
        speed: 500,

        //Désactive la gestion du loader via loading evt
        _startLoading() {},
        _endLoading() {},

        //Démarre l'animation au moment du render du composant.
        didRender() {
            this.manage();
        }

    });
});