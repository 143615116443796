define('ceag-lot3-front/adapters/online/abonnement', ['exports', 'ceag-lot3-front/adapters/online/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_dataAdapterMixin.default, {

        // Deprecated !!
        // //On passe par un authorizer particulier pour les abonnements :
        // //Il inclut en plus du token classique l'ID de device et le "tokenDevice"
        // authorizer: 'authorizer:token-device',


        //Pour récupérer les abonnement, nous avons besoin de l'ID unique du device
        //permettant de valider le "token device"
        fingerprint: Ember.inject.service('fingerprint'),
        //Et également le token ("offlineInfosService.tokenDevice")
        offlineInfosService: Ember.inject.service('offline-infos'),

        authorize(xhr) {

            this._super(xhr);

            let identifier = this.get('fingerprint.fingerprint');
            xhr.setRequestHeader('ceag-device-id', identifier);

            //Et token du device
            let tokenDevice = this.get('offlineInfosService.tokenDevice');
            xhr.setRequestHeader('ceag-device-token', tokenDevice);
        }

    });
});