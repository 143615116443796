define('ceag-lot3-front/components/layout/menu-bar', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        urlECommerce: _environment.default.CEAG.urlECommerce,

        session: Ember.inject.service(),
        intl: Ember.inject.service(),
        connectionStatus: Ember.inject.service('connection-status'),
        platform: Ember.inject.service('ember-cordova/platform'),

        isAdminPro: Ember.computed.equal('session.data.authenticated.role.firstObject', 'ROLE_ADMIN_PROFESSIONNEL'),
        isParticulier: Ember.computed.equal('session.data.authenticated.role.firstObject', 'ROLE_PARTICULIER'),

        isAdmin: Ember.computed.or('isAdminPro', 'isParticulier'),
        isPro: Ember.computed.not('isParticulier'),

        //On tiens compte de l'état "Real" (càd sans tenir compte du offline forcé pour la saisie)
        isOnline: Ember.computed.alias('connectionStatus.isRealOnline'),
        isOffline: Ember.computed.alias('connectionStatus.isRealOffline'),

        isIOS: Ember.computed.and('platform.isIOS', 'platform.isCordova'),

        actions: {
            offlineAlert: function () {
                if (this.get('isOffline')) {
                    this.get('confirmationService').alert(this.get('intl').t('misc.menu_online.titre'), this.get('intl').t('misc.menu_online.contenu'), { typeAlert: 'warning' });
                }
            }
        }
    });
});