define('ceag-lot3-front/initializers/ember-cli-uuid', ['exports', 'ember-data', 'ceag-lot3-front/config/environment', 'ember-cli-uuid/mixins/adapters/uuid', 'ember-cli-uuid/configuration'], function (exports, _emberData, _environment, _uuid, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: 'ember-cli-uuid',

    initialize() {

      const config = _environment.default['ember-cli-uuid'] || {};
      _configuration.default.load(config);

      _emberData.default.Adapter.reopen({

        generateIdForRecord() {
          return _configuration.default.defaultUUID ? (0, _uuid.generateIdForRecord)(...arguments) : null;
        }

      });
    }

  };
});