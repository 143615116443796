define('ceag-lot3-front/mixins/online-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        connectionStatus: Ember.inject.service('connection-status'),
        intl: Ember.inject.service(),

        disableOfflineObserver: Ember.on('deactivate', function () {
            this.removeObserver('connectionStatus.state', this, 'offLineObserver');
        }),
        enableOfflineObserver: Ember.on('activate', function () {
            this.addObserver('connectionStatus.state', this, 'offLineObserver');

            //On déclanche le test à l'entrée dans la route au cas où (btn retour ou saisie d'URL)
            this.offLineObserver();
        }),

        offLineObserver: function () {
            if (this.get('connectionStatus.isOffline')) {
                this.showOfflineMessage();
            }
        },

        showOfflineMessage: function () {

            let intl = this.get('intl');

            this.get('confirmationService').confirm(intl.t('misc.online_route.titre'), Ember.String.htmlSafe(intl.t('misc.online_route.contenu')), {
                okLabel: intl.t('misc.online_route.ok'),
                cancelLabel: intl.t('misc.online_route.cancel'),

                okType: 'primary',
                cancelType: 'default',

                typeAlert: 'warning'
            }).then(() => {
                this.transitionTo('in.index');
            }, () => {
                if (this.get('connectionStatus.isOffline')) {
                    //"Later" car sinon le popup ne disparait pas et l'utilisateur va avoir l'impression que le boutton ne fonctionne pas.
                    Ember.run.later(this, 'showOfflineMessage', 200);
                }
            });
        }

    });
});