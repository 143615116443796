define('ceag-lot3-front/services/screen', ['exports', 'ember-screen'], function (exports, _emberScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberScreen.default.extend({

    isMobile: (0, _emberScreen.breakpoint)('(max-width: 768px)'),
    isDesktop: (0, _emberScreen.breakpoint)('(min-width: 769px)'),

    isPortrait: Ember.computed('width', 'height', function () {
      return this.get('width') < this.get('height');
    }),

    isPaysage: Ember.computed.not('isPortrait')
  });
});