define('ceag-lot3-front/services/export', ['exports', 'ember-concurrency', 'moment'], function (exports, _emberConcurrency, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        excel: Ember.inject.service(),

        exportData: null,

        exportPending: Ember.computed.alias('exportTask.isRunning'),

        saisieList: null, //Liste des saisies à exporter
        initialeSaisieSize: 0, //Taille initiale des saisie à exporter
        saisieDone: Ember.computed('initialeSaisieSize', 'saisieList.length', function () {
            return this.get('initialeSaisieSize') - this.get('saisieList.length');
        }),

        goExport: function (saisiesFormulaire) {

            if (saisiesFormulaire.get('length')) {

                this.set('saisieList', saisiesFormulaire.toArray());
                this.set('initialeSaisieSize', saisiesFormulaire.get('length'));
                this.set('exportData', Ember.A());

                return this.get('exportTask').perform().then(() => {

                    //OK, génération du fichier.
                    let fileName = 'DMFORMS-' + (0, _moment.default)().format('YYYYMMDD-HHmmss') + '.xlsx';

                    this.get('excel').export(this.get('exportData'), { multiSheet: true, fileName });

                    this.set('exportData', null);
                }).catch(e => {
                    if (!(0, _emberConcurrency.didCancel)(e)) {
                        // alert('error');
                        console.error(e);
                    }
                });
            }

            return Ember.RSVP.resolve();
        },

        cancelExport: function () {
            if (this.get('exportTask.isRunning')) {
                this.get('exportTask').cancelAll();
            }
        },

        /**
         * Créer la structure pour le service excel avec une feuille par formulaire et les entêtes
         * @author jmestres
         */
        createDataSheetAndHeaderStructure: function (saisiesFormulaire) {
            let exportData = this.get('exportData');

            saisiesFormulaire.forEach(saisie => {
                let form_id = saisie.get('formulaire_version.formulaire.id');

                if (!Ember.isPresent(exportData[form_id])) {
                    exportData[form_id] = this.getHeaderForFormulaire(saisie.get('formulaire_version.formulaire'));
                }
            });

            return exportData;
        },

        getHeaderForFormulaire: function (formulaire) {

            let last_version = formulaire.get('last_version');
            let header = Ember.A(["date de création", "date de validation", "date de l’événement", "complement saisie"]); //Les headers dans l'ordre les uns après les autre (pour génération Excel)
            let indexedHeader = {}; //Les headers avec comme index [nom_super_calque+nom_champ] de l'objet_formulaire associé (non utilsé par le generateur Excel, mais utile pour ordonner les données de nos saisies)
            let index = 4;

            for (const pageFormulaire of last_version.get('pages').toArray()) {

                for (const superCalqueFormulaire of pageFormulaire.get('super_calques').toArray()) {

                    for (const objetFormulaire of superCalqueFormulaire.get('objets').toArray()) {
                        let nom = objetFormulaire.get('nom');
                        header.pushObject(nom);
                        indexedHeader[superCalqueFormulaire.get('libelle') + objetFormulaire.get('nom')] = index++;
                    }

                    for (const calquesFormulaire of superCalqueFormulaire.get('calques').toArray()) {

                        for (const objetFormulaire of calquesFormulaire.get('objets').toArray()) {
                            let nom = objetFormulaire.get('nom');
                            header.pushObject(nom);
                            indexedHeader[superCalqueFormulaire.get('libelle') + objetFormulaire.get('nom')] = index++;
                        }
                    }
                }
            }

            return {
                name: formulaire.get('code'),
                indexedHeader,
                data: [header]
            };
        },

        exportSaisie: function (saisieFormulaire) {
            let form_id = saisieFormulaire.get('formulaire_version.formulaire.id');
            let formSheet = this.get('exportData')[form_id];

            let saisieDatas = Ember.A();

            saisieDatas[0] = (0, _moment.default)(saisieFormulaire.get('date_creation')).format('DD/MM/YYY');
            saisieDatas[1] = (0, _moment.default)(saisieFormulaire.get('date_mise_a_jour')).format('DD/MM/YYY');
            saisieDatas[2] = Ember.isEmpty(saisieFormulaire.get('date_intervention')) ? '' : (0, _moment.default)(saisieFormulaire.get('date_intervention')).format('DD/MM/YYY');
            saisieDatas[3] = saisieFormulaire.get('complement_saisie');

            //On parcours les données de la saisie
            for (const saisiePageFormulaire of saisieFormulaire.get('saisies_page_formulaire').toArray()) {

                for (const saisieSuperCalqueFormulaire of saisiePageFormulaire.get('saisies_super_calque_formulaire').toArray()) {

                    for (const saisieObjetFormulaire of saisieSuperCalqueFormulaire.get('saisies_objet_formulaire').toArray()) {

                        //Le nom utilisé pour gerder l'index (ordre excel) du champs
                        let search_index_name = saisieSuperCalqueFormulaire.get('super_calque_formulaire.libelle') + saisieObjetFormulaire.get('objet_formulaire.nom');

                        let objet_type = saisieObjetFormulaire.get('objet_formulaire.type');
                        let valeur = saisieObjetFormulaire.get('donnee_courte');

                        let indexObjet = formSheet.indexedHeader[search_index_name]; //L'index de l'objet dans les colone excel

                        if (objet_type == 'i_date') {
                            valeur = (0, _moment.default)(new Date(valeur)).format('DD/MM/YYYY');
                        }

                        saisieDatas[indexObjet] = valeur;
                    }
                }
            }

            formSheet.data.pushObject(saisieDatas);
        },

        exportTask: (0, _emberConcurrency.task)(function* () {

            this.createDataSheetAndHeaderStructure(this.get('saisieList'));

            let oSaisie;

            while (!Ember.isEmpty(oSaisie = this.get('saisieList').shiftObject())) {
                //Pour dev
                // yield timeout(5); //Histoire d'afficher un peu le super popup avec progress bar :p
                yield this.exportSaisie(oSaisie);
            }
        }).drop()

    });
});