define('ceag-lot3-front/mixins/pending-object', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        isPending: false,

        loaderSlider: Ember.inject.service('loading-slider'),

        setPending: function (state, index = '') {
            let name = 'isPending' + index;
            this.set(name, state === true);
        },

        /**
         * Change l'état de "isPending" tant que la promise "promise" passée en paramètre n'est pas résolue/échouée
         * @param  {Ember.RSVP.Promise} promise Promise définissant l'état du pending
         * @param  {mixed} index   l'index du pending à utiliser (Aucun par défaut)
         * @return {Ember.RSVP.Promise}         Promise
         */
        goPending: function (promise, index = '') {

            this.get('loaderSlider').startLoading();
            let name = 'isPending' + index;
            this.set(name, true);

            return promise.finally(() => {
                this.set(name, false);
                this.get('loaderSlider').endLoading();
            });
        }
    });
});