define('ceag-lot3-front/services/signature', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        objet: null,
        popupOpen: false,

        platform: Ember.inject.service('ember-cordova/platform'),

        openForObjet: function (objetFormulaireSignature) {
            this.set('objet', objetFormulaireSignature);
            /*if( this.get('platform.isCordova') ){
                window.screen.orientation.lock('landscape');
            }*/
            this.set('popupOpen', true);
        },

        actions: {
            closePopup: function () {
                this.set('popupOpen', false);
                /*if( this.get('platform.isCordova') ){
                    window.screen.orientation.unlock();
                }*/
            }
        }

    });
});