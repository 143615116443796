define('ceag-lot3-front/routes/in/formulaire/saisie/preview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        fileCache: Ember.inject.service('file-cache'),
        connectionStatus: Ember.inject.service('connection-status'),
        offlineTaskManager: Ember.inject.service('task-manager'),

        model: function () {

            this.get("offlineTaskManager").on("offlineSyncStopped", this._onOfflineSyncStopped.bind(this));

            if (this.get('connectionStatus.isOffline') || this.get('offlineTaskManager.syncPending')) {

                //arrivé ici après saisie forcé offline, on ré-active le on-line
                //qui va déclancher une synchro et la route sera mise à jour une fois la synchro terminé
                if (this.get('connectionStatus.isOfflineForce')) {
                    this.get('connectionStatus').desactiveOfflineForce();
                }

                return Ember.RSVP.hash({
                    'saisieFormulaire': this.modelFor('in.formulaire.saisie').saisieFormulaire
                });
            }

            let saisieFormulaire = this.modelFor('in.formulaire.saisie').saisieFormulaire;
            //On lance la génération du préview et on affiche
            return this.get('fileCache').processSaisieFormulairePreview(saisieFormulaire).then(pngDatasBlob => {

                let pngBlobUrl = window.URL.createObjectURL(pngDatasBlob);

                return Ember.RSVP.hash({
                    'saisieFormulaire': saisieFormulaire,
                    'pngBase64': pngBlobUrl
                });
            });
        },

        //On désactive la connexion offline forcée en entrant dans cette route
        // beforeModel : function(){
        //     this.get('connectionStatus').desactiveOfflineForce();
        // },
        // enableOfflineForce: on('activate', function(){
        //     this.get('connectionStatus').desactiveOfflineForce();
        // }),

        //Sortie de synchro, on refresh l'aperçu
        _onOfflineSyncStopped: function () {
            this.refresh();
        }

    });
});