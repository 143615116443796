define('ceag-lot3-front/misc/period', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        debut: [(0, _emberCpValidations.validator)('date'), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {

            let fin = model.get('debut');
            let deb = model.get('fin');

            if (fin && deb && fin.getTime() > deb.getTime()) {
                return "Période invalide.";
            }

            return true;
        }, {
            dependentKeys: ['model.fin', 'model.debut']
        })],
        fin: [(0, _emberCpValidations.validator)('date'), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {

            let fin = model.get('debut');
            let deb = model.get('fin');

            if (fin && deb && fin.getTime() > deb.getTime()) {
                return "Période invalide.";
            }

            return true;
        }, {
            dependentKeys: ['model.fin', 'model.debut']
        })]
    });
});