define('ceag-lot3-front/routes/in/formulaire/saisie', ['exports', 'ceag-lot3-front/helpers/version-widget', 'ceag-lot3-front/config/environment'], function (exports, _versionWidget, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        title: '',

        saisie: Ember.inject.service(),
        intl: Ember.inject.service(),
        fileCache: Ember.inject.service('file-cache'),

        afterModel(model, transition) {
            this._super(model, transition);

            let intl = this.get('intl');

            //Abo expire ?
            let isPack = !Ember.isEmpty(model.saisieProcessus);
            if (!isPack && !this.modelFor('in.formulaire').get('abonnements').filterBy('hasExpire', false).length) {
                this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
            } else if (isPack && !model.saisieProcessus.get('processus').get('abonnements').filterBy('hasExpire', false).length) {
                this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
            }

            //Saisie done ?
            if (_environment.default.environment != "development" && (model.saisieFormulaire.get('complete') || model.saisieFormulaire.get('etat_saisie') == 'done')) {
                this.get('confirmationService').alert(intl.t('saisie.saisie_done.titre'), intl.t('saisie.saisie_done.contenu')).then(() => {
                    this.transitionTo('in.formulaire.action', model.saisieFormulaire.get('formulaire'));
                });
            }
        },

        //Pour mobile on passe en mode offline pour la saisie
        connectionStatus: Ember.inject.service('connection-status'),
        disableOfflineForce: Ember.on('deactivate', function () {
            this.get('connectionStatus').desactiveOfflineForce();
        }),
        enableOfflineForce: Ember.on('activate', function () {
            this.get('connectionStatus').activeOfflineForce();
        }),

        // queryParams: {
        //     page: { refreshModel: true },
        //     super_calque: { refreshModel: true },
        // },

        model: async function (params) {

            //La saisie à different niveau
            let saisieFormulaire = null;
            let saisieFormulaireAutoCompletion = null;

            //Nouvelle saisie ou reprise ?
            if (params.saisie_id == 'new') {

                //On créer une nouvelle saisie et laisse le user choisir le complement et date/heure (et potentiellement l'éditeur si admin)
                saisieFormulaire = this.get('store').createRecord('saisie-formulaire', {
                    formulaire: this.modelFor('in.formulaire'),
                    formulaire_version: this.modelFor('in.formulaire').get('last_version'),
                    editeur: this.modelFor('in').utilisateur
                });
            } else {

                saisieFormulaire = this.get('store').peekRecord('saisie_formulaire', params.saisie_id);
                if (Ember.isEmpty(saisieFormulaire)) {
                    saisieFormulaire = await this.get('store').findRecord('saisie_formulaire', params.saisie_id, { reload: true });
                }

                //Dans le cas d'une saisie processus, la saisieFormulaire est vide (page, super_calque, objet non créé...)
                //On va créer avec autocompletion (si formulaire non fictif)...
                if (!Ember.isEmpty(saisieFormulaire.get('saisie_processus.id')) && !saisieFormulaire.get('formulaire.form_fictif') && saisieFormulaire.get('saisies_page_formulaire.length') == 0) {

                    //On créé un objet contenant les valeurs qui sont à auto-compléter.
                    let saisieProcessus = saisieFormulaire.get('saisie_processus');
                    let rubriquesAutoCompletion = this.get('saisie').createRubriquesAutoCompletionObjetFromSaisieProcessus(saisieProcessus);

                    await this.get('saisie').createSaisieFormulaireChild(saisieFormulaire, rubriquesAutoCompletion);
                }
            }

            //Création d'une saisie avec auto-completion (cas d'une nouvelle saisie, ou saisie existante avec formulaire fictif)
            if (params.autocomplete_from_saisie) {

                //Si saisie pas connue, on charge...
                saisieFormulaireAutoCompletion = this.get('store').peekRecord('saisie_formulaire', params.autocomplete_from_saisie);
                if (Ember.isEmpty(saisieFormulaireAutoCompletion)) {
                    saisieFormulaireAutoCompletion = this.get('store').findRecord('saisie_formulaire', params.autocomplete_from_saisie, { reload: true });
                }
            }

            //Titre de page avec version du formulaire
            this.set('title', Ember.String.htmlSafe(saisieFormulaire.get('formulaire.aboLibelle') + (0, _versionWidget.default)(saisieFormulaire.get('formulaire_version'))));

            return Ember.RSVP.hash({
                utilisateurs: this.modelFor('in').utilisateurs,
                is_new_saisie: params.saisie_id == 'new',

                saisieFormulaire: saisieFormulaire,
                saisieProcessus: saisieFormulaire.get('saisie_processus'),

                isFormFictif: saisieFormulaire.get('formulaire_version.formulaire.form_fictif'),
                questionnaire: saisieFormulaire.get('formulaire_version.questionnaire'),

                saisieFormulaireAutoCompletion: saisieFormulaireAutoCompletion
            });
        },

        actions: {

            /**
             * Sauvegarde la saisie, puis s'occupe de créer
             * et sauvegarder les "sous-éléments" de saisie (page, super-calque, objet)
             *
             * @return {[type]}
             * @author jmestres
             */
            onSubmitNewSaisie: async function () {
                try {

                    let { saisieFormulaire, saisieFormulaireAutoCompletion } = this.modelFor(this.routeName);

                    //On sauvegarde la saisie, puis on créer les saisie page/super_calque
                    if (!Ember.isEmpty(saisieFormulaireAutoCompletion)) {
                        saisieFormulaire.set('etat_saisie', 'pending');
                    }
                    await saisieFormulaire.save();

                    //On créer un tableau pour l'auto-completion si besoin
                    let rubriquesAutoCompletion = null;
                    let autocomplete_from_saisie = null;
                    if (!Ember.isEmpty(saisieFormulaireAutoCompletion)) {

                        //Si le formulaire pour lequel on créé la saisie est un fictif, on garde une référence à notre saisie à utiliser en auto-complétion.
                        //pemettra lors du choix, du formualire réél, l'auto-complétion
                        if (saisieFormulaire.get('formulaire.form_fictif')) {
                            autocomplete_from_saisie = saisieFormulaireAutoCompletion.get('id');
                        }
                        //Sinon, saisie sur formulaire classique, on auto-complète
                        else {
                                rubriquesAutoCompletion = this.get('saisie').createRubriquesAutoCompletionObjetFromSaisie(saisieFormulaireAutoCompletion);
                            }
                    }

                    await this.get('saisie').createSaisieFormulaireChild(saisieFormulaire, rubriquesAutoCompletion);

                    //Des données sont pré-saisies, on génré un aperçu
                    if (!Ember.isEmpty(saisieFormulaireAutoCompletion)) {
                        this.get('fileCache').processSaisieFormulairePreview(saisieFormulaire);
                    }

                    this.replaceWith('in.formulaire.saisie', saisieFormulaire.get('id'), { queryParams: { page: 0, super_calque: 0, autocomplete_from_saisie } });
                } catch (error) {
                    console.error('Erreur de sauvegarde du model saisieFormulaire', error);
                    this.get('messageService').error(this.get('intl').t('saisie.creation.erreur'));
                }
            },

            /**
             * Déclanher lors du choix d'un formulaire "précis" pour un formulair fictif lié à un questionnaire.
             * @return {RSVP.Promise}
             * @author jmestres
             */
            onSelectFormulaireForQuestionnaire: async function (formulaire) {

                let saisieFormulaire = this.modelFor(this.routeName).saisieFormulaire;

                let saisieProcessus = await saisieFormulaire.get('saisie_processus');
                let saisieFormulaireAutoCompletion = this.modelFor(this.routeName).saisieFormulaireAutoCompletion;

                let formulaire_version = formulaire.get('last_version');
                //Cas du offline
                if (Ember.isEmpty(formulaire_version)) {
                    formulaire = await this.get('store').findRecord('formulaire', formulaire.get('id'), { reload: true });
                    formulaire_version = formulaire.get('last_version');
                }

                //saisieFormulaire.set('etape_processus', null);
                //saisieFormulaire.set('formulaire', formulaire);
                saisieFormulaire.set('formulaire_version', formulaire.get('last_version'));

                //Si données pré-saisies, 'pending'
                if (!Ember.isEmpty(saisieFormulaireAutoCompletion) || !Ember.isEmpty(saisieProcessus)) {
                    saisieFormulaire.set('etat_saisie', 'pending');
                }

                await saisieFormulaire.save();

                //On créé un objet contenant les valeurs qui sont à auto-compléter si besoin (processus, libre ou ordoné)
                let rubriquesAutoCompletion = null;

                //Si processus, à partir des saisies formulaire précédente
                if (!Ember.isEmpty(saisieProcessus)) {
                    rubriquesAutoCompletion = this.get('saisie').createRubriquesAutoCompletionObjetFromSaisieProcessus(saisieProcessus);
                } else if (!Ember.isEmpty(saisieFormulaireAutoCompletion)) {
                    rubriquesAutoCompletion = this.get('saisie').createRubriquesAutoCompletionObjetFromSaisie(saisieFormulaireAutoCompletion);
                }

                await this.get('saisie').createSaisieFormulaireChild(saisieFormulaire, rubriquesAutoCompletion);

                //Des données sont pré-saisies, on génré un aperçu
                if (!Ember.isEmpty(saisieFormulaireAutoCompletion) || !Ember.isEmpty(saisieProcessus)) {
                    this.get('fileCache').processSaisieFormulairePreview(saisieFormulaire);
                }

                //Go saisie "réél"
                //On enlève le "autocomplete_from_saisie" si présent
                return this.replaceWith('in.formulaire.saisie', saisieFormulaire.get('id'), { queryParams: { page: 0, super_calque: 0, autocomplete_from_saisie: null } }).promise.then(() => {
                    return this.refresh().promise;
                });
            }

        }
    });
});