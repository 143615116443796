define('ceag-lot3-front/routes/in/utilisateurs', ['exports', 'ceag-lot3-front/misc/admin-route', 'ceag-lot3-front/mixins/online-route', 'ceag-lot3-front/mixins/custom-loading-slider'], function (exports, _adminRoute, _onlineRoute, _customLoadingSlider) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adminRoute.default.extend(_onlineRoute.default, _customLoadingSlider.default, {

        rolesAllowed: ['ROLE_ADMIN_PROFESSIONNEL'],

        model: function () {
            return this.store.findAll('utilisateur');
        }

    });
});