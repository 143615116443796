define('ceag-lot3-front/serializers/online/saisie-processus', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data', 'ceag-lot3-front/serializers/online/mixins/serialize-id'], function (exports, _application, _emberData, _serializeId) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, _serializeId.default, {
        // Pour Rappel default :
        //  BelongsTo: { serialize: 'id', deserialize: 'id' }
        //  HasMany: { serialize: false, deserialize: 'ids' }
        attrs: {

            processus: { serialize: 'id', deserialize: 'records' },
            processus_version: { serialize: 'id', deserialize: 'records' },

            saisies_formulaire: { serialize: false, deserialize: 'records' },

            date_mise_a_jour: {
                serialize: false,
                deserialize: 'records'
            },
            date_creation: {
                serialize: false,
                deserialize: 'records'
            },
            complete: {
                serialize: false,
                deserialize: 'records'
            },
            createur: {
                serialize: false,
                deserialize: 'records'
            },

            offlineSyncStatus: { serialize: false },
            offlineLastUpdate: { serialize: false }
        }
    });
});