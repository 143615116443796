define('ceag-lot3-front/authenticators/myjwt', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jwt.default.extend({

        connectionStatus: Ember.inject.service('connection-status'),
        session: Ember.inject.service(),

        //Si offline et demande de raffraichir, on simule, puis on essais de relancer un "refresh" dans xx seconde
        offlineScheduleRefreshTime: 900, //15 minutes


        //On surcharge car pour la requête refresh token, on doit passer le token dans le header et de base ce n'est pas géré par "ember-simple-auth-token"
        refreshAccessToken(token, headers) {

            if (this.get('connectionStatus.isOffline')) {

                Ember.Logger.warn('Demande de refresh Token en offline on différe...');
                return this.dealWithOfflineRefresh();
            }

            //On inject nous même le header
            headers = headers || {};
            headers['Authorization'] = 'Bearer ' + token;

            const data = this.makeRefreshData(token);

            return new Ember.RSVP.Promise((resolve, reject) => {
                this.makeRequest(this.serverTokenRefreshEndpoint, data, headers).then(response => {
                    Ember.run(() => {
                        try {
                            const sessionData = this.handleAuthResponse(response);

                            this.trigger('sessionDataUpdated', sessionData);
                            resolve(sessionData);
                        } catch (error) {
                            reject(error);
                        }
                    });
                }, (xhr, status, error) => {

                    //Offline ?
                    if (this.get('connectionStatus.isOffline') || xhr.status == 0) {

                        Ember.Logger.warn('Demande de refresh Token en offline on différe...');
                        return this.dealWithOfflineRefresh();
                    } else {

                        Ember.Logger.warn(`Access token could not be refreshed - server responded with ${error}.`);
                        this.handleTokenRefreshFail(xhr.status);
                        reject();
                    }
                });
            });
        },

        /**
         * Essai de simuler un refresh token avec les information de session connues
         * @author mestresj
         */
        dealWithOfflineRefresh: function () {
            return new Ember.RSVP.Promise(async (resolve, reject) => {

                let simulateRefreshData = this.get('session.data.authenticated');
                //Ici cas de la relance de l'appli mobile en offline (pas encore authentifié mais les infos de token son dans le "session-store", on va chercher)
                if (!this.get('session.isAuthenticated')) {
                    let datas = await this.get('session.store').restore();
                    simulateRefreshData = datas.authenticated;
                }

                //On décode le token (pour la date d'expiration)
                const token = Ember.get(simulateRefreshData, this.tokenPropertyName);
                const tokenData = this.getTokenData(token);
                const expiresAt = Ember.get(tokenData, this.tokenExpireName);

                //Token expiré ?
                if (this.getCurrentTime() > expiresAt) {
                    reject("Offline refresh: Token expiré");
                } else {

                    const sessionData = this.handleAuthResponse(simulateRefreshData);
                    this.trigger('sessionDataUpdated', sessionData);

                    //Enfin, vu que le token passé à une date d'expiration non valide, aucun refresh token ne sera déclanché
                    //On le gère manuellement (on simule la date d'expiration)
                    let refreshToken = simulateRefreshData[this.refreshTokenPropertyName];
                    let expiresAt = this.getCurrentTime() + this.refreshLeeway + this.offlineScheduleRefreshTime;
                    this.scheduleAccessTokenRefresh(expiresAt, refreshToken);

                    resolve(sessionData);
                }
            });
        }

    });
});