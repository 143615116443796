define('ceag-lot3-front/services/deviceid', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        platform: Ember.inject.service('ember-cordova/platform'),
        fingerprint: Ember.inject.service('fingerprint'),

        store: Ember.inject.service(''),

        getDeviceIDForSaisie: function () {

            if (this.get('platform.isCordova')) {
                return new Ember.RSVP.Promise((resolve /*, reject*/) => {
                    resolve(this.get('fingerprint.fingerprint'));
                });
            } else {

                if (_environment.default.environment == "development") {
                    return new Ember.RSVP.Promise((resolve /*, reject*/) => {
                        resolve("ID0123456789");
                    });
                }
                return this.get('store').adapterFor('application').ajaxCall(_environment.default.getPublicIPUrl, {}).then(data => {
                    return data.ip;
                });
            }
        }

    });
});