define('ceag-lot3-front/services/connection-status', ['exports', 'offline-store-sync/services/connection-status', 'ceag-lot3-front/config/environment'], function (exports, _connectionStatus, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _connectionStatus.default.extend({

        forceOffline: false,

        platform: Ember.inject.service('ember-cordova/platform'),

        //Etat "reel"
        stateReal: "offline",
        isRealOnline: Ember.computed.equal("stateReal", "online"),
        isRealOffline: Ember.computed.equal("stateReal", "offline"),

        init() {
            this._super(...arguments);
            this.set("stateReal", window.navigator.onLine ? "online" : "offline");
        },
        _onOfflineHandler() {
            this.set("stateReal", "offline");
            this.set("state", "offline");
        },

        _onOnlineHandler() {
            this.set("stateReal", "online");
            if (_environment.default['offline-store-sync'].enableOfflineStoreSync && this.get('forceOffline')) {
                return;
            }
            this.set("state", "online");
        },

        activeOfflineForce() {

            if (_environment.default['offline-store-sync'].enableOfflineStoreSync) {
                this.set('forceOffline', true);
                this.set("state", "offline");
            }
        },

        desactiveOfflineForce() {

            if (_environment.default['offline-store-sync'].enableOfflineStoreSync) {
                this.set("state", window.navigator.onLine ? "online" : "offline");
                this.set('forceOffline', false);

                //On doit lancer un event sur documnt pour déclancher la synchro
                this._fireOnlineEvent();
            }
        },

        isOfflineForce() {
            return this.set('forceOffline');
        },

        _fireOnlineEvent() {
            var event;
            var eventName = window.navigator.onLine ? "online" : "offline";
            if (document.createEvent) {
                event = document.createEvent("HTMLEvents");
                event.initEvent(eventName, true, true);
            } else {
                event = document.createEventObject();
                event.eventType = eventName;
            }
            event.eventName = eventName;

            if (document.createEvent) {
                document.dispatchEvent(event);
            } else {
                document.fireEvent("on" + event.eventType, event);
            }
        }

    });
});