define('ceag-lot3-front/mixins/filtered-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        /**
         * Le nom des données à filtrer (la clé)
         * ex: 'model.eleves'
         **/
        dataListKeyname: '',

        /**
         * Les filtres à gérer
         * listFilters : [
             {name :  'libelle',     type: 'string' },
             {name :  'isActive', type: 'boolean', dataKey : 'is_active' },
             {name :  'classe', type: 'model' },
             {name :  'niveau', type: 'model', dataKey : 'classe.niveau' },
           ]
         *
         * name : Nom du filtre (de la propriété)
         * type : string / boolean / model
         * dataKey [optionnelle] : Nom de la propriété dans la liste de donnée à "matcher" avec la valeur de filtre (si différente du nom de filtre )
         **/
        listFilters: [],

        /**
         * Quelles type de recherche pour les valeurs string ? "start"  "contain"
         */
        stringSearchType: 'start',

        /**
         * La liste filtrée
         **/
        filteredList: null,

        init: function () {
            this._super(...arguments);

            //On ajoute un observer sur chaque filtre pour la mise à jour des données de sortie
            this.get('listFilters').forEach(filter => {
                this.addObserver(filter.name, this, "updateFilteredList");
            });

            //Idem sur la liste de base à filtrer
            this.addObserver(this.get('dataListKeyname'), this, "updateFilteredList");

            //Puis on filtre une première fois à l'initialisation
            this.updateFilteredList();
        },

        updateFilteredList: function () {

            let list = this.get(this.get('dataListKeyname'));

            if (!Ember.isEmpty(list)) {

                this.get('listFilters').forEach(filter => {
                    let name = filter.name;
                    let type = filter.type;
                    let dataKey = filter.dataKey !== undefined ? filter.dataKey : filter.name;

                    let value = this.get(name);

                    if (type === 'boolean' && value !== undefined && value != null) {
                        list = list.filterBy(dataKey, value);
                    } else if (type === 'string' && value !== undefined && value != null && value !== '') {
                        list = list.filter((item /*, index, enumerable*/) => {

                            //Pour un filtre string on peux rechercher dans plusieur champ
                            if (Ember.isArray(dataKey)) {
                                let match = false;
                                dataKey.forEach(subDataKey => {

                                    let val = item.get(subDataKey);
                                    val = val == undefined ? "" : val.toUpperCase();

                                    if (this.stringSearchType === 'start' ? val.startsWith(value.toUpperCase()) : val.indexOf(value.toUpperCase()) !== -1) {
                                        match = true;
                                    }
                                });
                                return match;
                            } else {
                                let val = item.get(dataKey).toUpperCase();
                                return this.stringSearchType === 'start' ? val.startsWith(value.toUpperCase()) : val.indexOf(value.toUpperCase()) !== -1;
                            }
                        });
                    } else if (type === 'model' && value !== undefined && value != null) {
                        list = list.filterBy(dataKey + '.id', value.get('id'));
                    }
                });
            }

            this.set('filteredList', list);
        }

    });
});