define('ceag-lot3-front/routes/in', ['exports', 'ceag-lot3-front/config/ceag-config-lot1', 'ceag-lot3-front/config/environment', 'ceag-lot3-front/misc/promise-array', 'ceag-lot3-front/misc/file-reader', 'ceag-lot3-front/mixins/custom-loading-slider'], function (exports, _ceagConfigLot, _environment, _promiseArray, _fileReader, _customLoadingSlider) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_customLoadingSlider.default, {

        bubbleLoadingSlider: true,

        session: Ember.inject.service(),
        intl: Ember.inject.service(),
        fingerprint: Ember.inject.service('fingerprint'),

        platform: Ember.inject.service('ember-cordova/platform'),
        connectionStatus: Ember.inject.service('connection-status'),
        fileCache: Ember.inject.service('file-cache'),

        model: async function (param, transition) {

            let appModels = await Ember.RSVP.hash({
                abonnements: this.processAbonnements(transition),
                options: this.processOptions(),
                compteurs: this.get('store').findAll('compteur'),
                categories: this.get('store').findAll('categorie'), //Permet de charger en plus les sous-catégories et les rubriques
                utilisateur: this.processUtilisateur(),
                utilisateurs: this.get('store').findAll('utilisateur')
            });

            //Si application mobile on initialise les accès aux répertoires cache
            if (this.get('platform.isCordova')) {

                await this.get('fileCache').initDirectory();

                //Si mobile ET inline, on va récupérer les aperçus formulaire + stockage dans mémoire téléphone ?
                if (this.get('connectionStatus.isOnline')) {
                    await this.get('fileCache').processFormulairePreviewForAbonnements(appModels.abonnements.all);
                }
            }

            return appModels;
        },

        /**
         * S'occupe de récupérer les abonnement et les "spliter" en abonnement formulaire et pack
         * @return {[type]}
         * @author jmestres
         */
        processAbonnements: async function (transition) {
            try {

                let abonnements = await this.get('store').findAll('abonnement');

                let result = {
                    'formulaires': abonnements.filterBy('is_pack', false),
                    'processus': abonnements.filterBy('is_pack', true),
                    'all': abonnements
                };

                //Ici nous avons les données d'abonnement mais sans les détails des formulaire/processus
                //On lance le chargement "manuellement"
                await (0, _promiseArray.default)(result.formulaires, async aboFormulaire => {

                    let formulaire = await this.get('store').findRecord('formulaire', aboFormulaire.get('formulaire.id'), { reload: true });

                    //Cas particulier pour le offline, on force la synchro offline de certaines reation belongsTo
                    if (this.get('connectionStatus.isOnline') && this.get('platform.isCordova')) {

                        //Info pour formulaire dans un processus libre
                        await (0, _promiseArray.default)(formulaire.get('etapes'), async etape => {
                            let processus = await etape.get('version_processus.processus');
                            await this.get('store').processModelForOnline(processus, 'processus');
                        });

                        //Info pour un  formulaire fictif
                        if (formulaire.get('form_fictif')) {

                            let questionnaire = await formulaire.get('last_version.questionnaire');
                            await this.get('store').processModelForOnline(questionnaire, 'questionnaire');

                            await (0, _promiseArray.default)(questionnaire.get('last_version.choix_questionnaire'), async choix_questionnaire => {
                                let form = await choix_questionnaire.get('formulaire');
                                await this.get('store').processModelForOnline(form, 'formulaire');
                                //La logique voudrait qu'on puisse boucler au cas où le présent formulaire "form" soit fictif...
                            });
                        }
                    }
                });

                await (0, _promiseArray.default)(result.processus, async aboProcessus => {
                    let processus = await this.get('store').findRecord('processus', aboProcessus.get('processus.id'), { reload: true });

                    //Cas particulier pour le offline, on force la synchro offline de certaines reation belongsTo                //
                    if (this.get('connectionStatus.isOnline') && this.get('platform.isCordova')) {

                        await (0, _promiseArray.default)(processus.get('last_version.etapes'), async etape => {
                            let formulaire = await etape.get('formulaire');
                            await this.get('store').processModelForOnline(formulaire, 'formulaire');

                            if (formulaire.get('form_fictif')) {

                                let questionnaire = await formulaire.get('last_version.questionnaire');
                                await this.get('store').processModelForOnline(questionnaire, 'questionnaire');

                                await (0, _promiseArray.default)(questionnaire.get('last_version.choix_questionnaire'), async choix_questionnaire => {
                                    let form = await choix_questionnaire.get('formulaire');
                                    await this.get('store').processModelForOnline(form, 'formulaire');
                                    //La logique voudrait qu'on puisse boucler au cas où le présent formulaire "form" soit fictif...
                                });
                            }
                        });
                    }
                });

                return result;
            } catch (e) {

                //Erreur de device non autorisé!
                if (e.errors && e.errors[0] && e.errors[0].status == 403) {

                    let infos = { token: this.get('offlineInfosService.tokenDevice'), fingerprint: this.get('fingerprint.fingerprint') };
                    console.error('DEVICE NON AUTORISE', infos);

                    //On supprime le token existant si présent
                    this.get('offlineInfosService').updateDeviceToken(null);

                    transition.abort();

                    //On alert !
                    this.transitionTo('device');
                    this.get('confirmationService').alert(this.get('intl').t('misc.device_hs.titre'), this.get('intl').t('misc.device_hs.contenu'));

                    //Aucun droits !
                    return {
                        'abonnements': [],
                        'processus': [],
                        'all': []
                    };
                }

                throw e;
            }
        },

        /**
         * S'occupe de récupérer les options via l'api puis les faire "matcher" (ou pas) avec les options de notres applications
         * @type {[type]}
         */
        processOptions: async function () {

            let options = await this.get('store').findAll('option').then(datas => {
                return datas.toArray();
            });
            let applicationOptions = {
                all: Ember.A([])
            };

            //On fait la correspondance entre les options lot1 et lot3
            _ceagConfigLot.default.optionsLot1.forEach(opt => {

                if (!opt.enableForParticulier && this.get('session.data.authenticated.is_particulier')) {
                    return;
                }

                //Par défault options innactive
                opt.active = false;
                applicationOptions[opt.configName] = false;

                for (let i = 0; i < options.length; i++) {
                    if (options[i].get('reference') === opt.code) {
                        opt.active = true;
                        applicationOptions[opt.configName] = true;
                    }
                }

                applicationOptions.all.push(opt);
            });

            //On met à jour les options dans le service qui gère ça...
            this.get('offlineInfosService').initOptionsDatas(applicationOptions);

            return applicationOptions;
        },

        /**
         * Récupère les infos utilisateur (et prestashop), puis s'occupe de garder le logo en mémoire
         * @return {Promise}
         * @author jmestres
         */
        processUtilisateur: async function () {
            let user = await this.get('store').findRecord('utilisateur', this.get('session.data.authenticated.id_utilisateur'), { reload: true });

            //Si le user connecté a un logo, on essai de mettre à jour si connecté
            if (user.get('infos_prestashop.logo') && this.get('connectionStatus.isOnline')) {

                try {
                    let logoUrl = `${_environment.default.CEAG.urlECommerce}modules/ceaglogo/logos/get.php?file=${user.get('infos_prestashop.logo')}`;

                    let adapter = this.get('store').adapterFor('application');

                    //On lance la récup du logo en async
                    adapter.ajaxCall(logoUrl, {
                        xhrFields: { responseType: 'blob' },
                        dataType: 'binary'
                    }).then(async logoDatasBlob => {
                        let base64Logo = await (0, _fileReader.default)(logoDatasBlob);
                        this.get('offlineInfosService').updateLogo(base64Logo);
                    });
                } catch (e) {
                    console.error('Impossible de récupérer le logo client', e);
                }
            }

            return user;
        }

    });
});