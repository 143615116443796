define('ceag-lot3-front/services/saisie', ['exports', 'moment', 'ceag-lot3-front/misc/promise-array', 'ceag-lot3-front/config/ceag-config-lot1', 'ceag-lot3-front/config/ceag-config-lot2'], function (exports, _moment, _promiseArray, _ceagConfigLot, _ceagConfigLot2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        store: Ember.inject.service(),
        geolocation: Ember.inject.service(),
        eCommerceInfos: Ember.inject.service('ecommerce-infos'),
        deviceID: Ember.inject.service('deviceid'),

        /**
         * Créer l'arbre d'objet enfant (saisie formulaire, page et super_calque) d'une saisie pour un questionnaire
         * @param  {saisie_processus} saisieProcessus La saisie de processus pour laquelle on créer les enfants
         * @return {Promise}
         * @author jmestres
         */
        createSaisieProcessusChild: async function (saisieProcessus) {

            //On répercute les 3 infos sur les saisiesFormulaire "enfant"
            let editeur = saisieProcessus.get('editeur');
            let complement_saisie = saisieProcessus.get('complement_saisie');
            let date_intervention = saisieProcessus.get('date_intervention');

            let processusVersion = saisieProcessus.get('processus_version');

            //On va créer une saisieFormulaire par étapes (avec le formulaire lié à l'étape).
            //On ne tiens pas compte ici des formulaire fictifs (questionnaire) car de toutes façon il n'ont pas de page/super calque
            let etapes = processusVersion.get('etapes');
            await (0, _promiseArray.default)(etapes, async etape => {

                let saisieFormulaire = this.get('store').createRecord('saisie-formulaire', {

                    saisie_processus: saisieProcessus,
                    etape_processus: etape,

                    editeur: editeur,
                    complement_saisie: complement_saisie,
                    date_intervention: date_intervention,

                    formulaire: etape.get('formulaire'),
                    formulaire_version: etape.get('formulaire.last_version')
                });

                await saisieFormulaire.save();

                //Puis création des saisies page/super_calque pour la première saisie
                //les page/super_calque des autres saisies seront créés lors de l'entrée en saisie (avec auto-completion)
                if (etape.get('index') == 0) await this.createSaisieFormulaireChild(saisieFormulaire);
            });
        },

        /**
         * Créer l'arbre d'objet enfant (saisie page et super_calque) d'un saisie pour un formulaire
         * @param  {saisie_formulaire} saisieFormulaire La saisie de formulaire pour laquelle on créer les enfants
         * @param  {Object} rubriquesAutoCompletion Un objet avec laquelle on va auto-compléter la saisie à créer { id_rubrique => valeur, ... }
         * @return {Promise}
         * @author jmestres
         */
        createSaisieFormulaireChild: async function (saisieFormulaire, rubriquesAutoCompletion) {

            // Récupération des informations concernant le formulaire à saisir
            let formulaireVersion = saisieFormulaire.get('formulaire_version');

            // Récupération des pages du formulaire
            let pages = formulaireVersion.get('pages');

            // Pour chaque pages
            await (0, _promiseArray.default)(pages, async page => {

                // Création de l'enregistrement de 'saisie_page_formulaire' en lui passant
                // l'identifiant de la saisie_formulaire et de la page.
                let saisiePage = this.get('store').createRecord('saisie-page-formulaire', {
                    page_formulaire: page,
                    saisie_formulaire: saisieFormulaire
                });

                // Sauvegarde de la saisie_page en db
                await saisiePage.save();

                // Récupération de la liste des super calques de la page courante
                // et sauvegarde de ces derniers
                let super_calques = page.get('super_calques');
                await (0, _promiseArray.default)(super_calques, async super_calque => {

                    // Création de l'enregistrement du super calque
                    let saisieSuperCalque = this.get('store').createRecord('saisie-super-calque-formulaire', {
                        super_calque_formulaire: super_calque,
                        saisie_page_formulaire: saisiePage
                    });

                    // sauvegarde sur super calque vide en db
                    await saisieSuperCalque.save();

                    // Création des saise_objet_formulaire de ce super calque
                    await this.createSaisieSuperCalqueFormulaireChild(saisieSuperCalque, rubriquesAutoCompletion);
                });
            });

            //Si création d'une saisie à partir d'une autre, les valeurs des champs (saisie-objet-formulaire) sont rensigné
            //Mais les objets "saisie-co-contractant" ne sont pas remplis si des champs sont lié à des rubrique co-contractants
            //On traite ci-dessous
            if (!Ember.isEmpty(rubriquesAutoCompletion)) {

                let saisieCoContractantToSave = [];
                for (const saisiePageFormulaire of saisieFormulaire.get('saisies_page_formulaire_ordered').toArray()) {
                    for (const saisieSuperCalqueFormulaire of saisiePageFormulaire.get('saisies_super_calque_formulaire_ordered').toArray()) {
                        for (const saisieObjetFormulaire of saisieSuperCalqueFormulaire.get('saisies_objet_formulaire_ordered').toArray()) {
                            let saisieCoContractant = this.checkUpdateCoContractantForObjetFormulaire(saisieObjetFormulaire, saisieFormulaire);
                            if (saisieCoContractant) {
                                saisieCoContractantToSave.addObject(saisieCoContractant);
                            }
                        }
                    }
                }

                if (!Ember.isEmpty(saisieCoContractantToSave)) {
                    await (0, _promiseArray.default)(saisieCoContractantToSave, async saisieCoContractant => {
                        await saisieCoContractant.save();
                    });
                }
            }
        },

        /**
         * Créer les objets enfants (saisie-objet-formulaire) d'une saisie super calque
         * @param  {saisie_super_calque_formulaire} saisieSuperCalqueFormulaire La saisie super calque formulaire pour laquelle on créer les enfants
         * @param  {Object} rubriquesAutoCompletion Un objet avec laquelle on va auto-compléter la saisie à créer { id_rubrique => valeur, ... }
         * @return {Promise}
         * @author jmestres
         */
        createSaisieSuperCalqueFormulaireChild: async function (saisieSuperCalqueFormulaire, rubriquesAutoCompletion) {

            let superCalque = saisieSuperCalqueFormulaire.get('super_calque_formulaire');

            //On créer les saisie des objets enfant "direct" du super calque
            let objets = superCalque.get('objets');
            await this.createSaisiesForObjetFormulaireArray(objets, saisieSuperCalqueFormulaire, rubriquesAutoCompletion);

            //Idem avec les objets présents dans les calques du super_calque.
            let calques = superCalque.get('calques');

            await (0, _promiseArray.default)(calques, async calque => {

                let objetsCalque = calque.get('objets');
                await this.createSaisiesForObjetFormulaireArray(objetsCalque, saisieSuperCalqueFormulaire, rubriquesAutoCompletion);

                //Cas particulier, si calque de type co-contractant, on créer une saisieCoContractant
                if (calque.get('is_coo_contractant')) {

                    // Initialisation de la création 
                    let saisieCoContractant = this.get('store').createRecord('saisie-co-contractant', {
                        saisie_formulaire: saisieSuperCalqueFormulaire.get('saisie_page_formulaire.saisie_formulaire'),
                        calque_formulaire: calque,
                        libelle_calque: calque.get('libelle')
                    });

                    await saisieCoContractant.save();
                }
            });
        },

        /**
         * Créer des SaisiesObjetFormulaire à partir d'une liste d'objet pour la saisieSuperCalque donnée
         * @param  {Array} objetsFormulaire            Les objetFormulaire pour lesquels on doit créer des saisies
         * @param  {saisie_super_claque_formulaire} saisieSuperCalqueFormulaire La saisie superClaque à laquelle seront lié les saisieObjetFormulaire
         * @param  {Object} rubriquesAutoCompletion Un objet avec laquelle on va auto-compléter la saisie à créer { id_rubrique => valeur, ... }
         * @return {Promise}
         * @author jmestres
         */
        createSaisiesForObjetFormulaireArray: async function (objetsFormulaire, saisieSuperCalqueFormulaire, rubriquesAutoCompletion) {

            let saisieFormulaire = saisieSuperCalqueFormulaire.get('saisie_page_formulaire.saisie_formulaire');

            await (0, _promiseArray.default)(objetsFormulaire, async objetFormulaire => {

                // Si type de champ commence par "i_" ? Sinon, pas un objet de saisie ?
                if (objetFormulaire.get('type').startsWith('i_')) {

                    let donnee_courte = null,
                        donnee_longue = null;
                    let rubrique_id = objetFormulaire.get('rubrique.id');
                    let rubrique_libelle = objetFormulaire.get('rubrique.libelle');

                    //Auto-complétion d'une saisie (processus libre ou ordonné)
                    if (!Ember.isEmpty(rubriquesAutoCompletion) && !Ember.isEmpty(rubrique_id) && !Ember.isEmpty(rubriquesAutoCompletion[rubrique_id])) {
                        donnee_courte = rubriquesAutoCompletion[rubrique_id].donnee_courte;
                        donnee_longue = rubriquesAutoCompletion[rubrique_id].donnee_longue;
                    }
                    //Auto-completion d'une donnée e-commerce lot1
                    else if (!Ember.isEmpty(rubrique_libelle) && objetFormulaire.get('rubrique.libelle').startsWith('EC_')) {

                            if (!Ember.isEmpty(_ceagConfigLot.default.nomRubriqueLot1eCommerce[rubrique_libelle])) {

                                let infoPSFieldName = _ceagConfigLot.default.nomRubriqueLot1eCommerce[rubrique_libelle];
                                donnee_courte = this.get('eCommerceInfos').getInfoPrestashop(infoPSFieldName);
                            }
                        }

                    let saisieObjetFormulaire = this.get('store').createRecord('saisie-objet-formulaire', {

                        saisie_formulaire: saisieFormulaire,
                        saisie_super_calque_formulaire: saisieSuperCalqueFormulaire,
                        objet_formulaire: objetFormulaire,
                        donnee_courte: donnee_courte,
                        donnee_longue: donnee_longue

                    });

                    await saisieObjetFormulaire.save();
                }
            });
        },

        saveSaisieSuperCalque: async function (saisieSuperCalque) {

            let saisieFormulaire = saisieSuperCalque.get('saisie_page_formulaire.saisie_formulaire');

            // les saisie-objet-formulaire
            let saisiesObjetFormulaire = saisieSuperCalque.get('saisies_objet_formulaire');

            await (0, _promiseArray.default)(saisiesObjetFormulaire, async objetFormulaire => {

                // 2020-12-18 // 3941
                // http://redmine.thetys-france.fr/issues/3941
                // Une verrue de plus, sorry
                // Le fait que les checkox soient a 'false' génère un "true" a l'affichage
                // et comme je n'ai pas trouvé ou cela se faisait, je (Jocelyn Madray) gère ça ici 
                // C'est inéléguant au possible, et ça me fout en rogne
                // la plus grosse blague, c'est qu'en saisie, le formulaire est à true (boolean), mais quand on 
                // reviens dessus il passe à 'true' (string)

                // if (objetFormulaire.get('objet_formulaire').get('type') === 'i_checkbox') {
                // debugger
                // }

                if (objetFormulaire.get('objet_formulaire').get('type') === 'i_checkbox' && objetFormulaire.get('donnee_courte') != true) {
                    if (objetFormulaire.get('donnee_courte') === 'true') {
                        // si c'est la chaine de caractère 'true', on remet le boolean
                        objetFormulaire.set('donnee_courte', true);
                    } else {
                        objetFormulaire.set('donnee_courte', undefined);
                    }
                }

                // Pour les objets de type i_dession, l'outil ne détecte apparament pas les modifications apportées
                // et et hasDirtyAttributes reste a false. la solution n'est pas éléguente, mais regle le soucis
                // || objetFormulaire.get('objet_formulaire.type') == 'i_dessin'
                if (objetFormulaire.get('hasDirtyAttributes')) await objetFormulaire.save();
            });

            // On va parcourir les champs saisies, pour déterminer si des infos concernent des co-contractants et sont à répercuter sur la "saisie_co_contractant".
            let saisieCoContractantToSave = [];
            saisiesObjetFormulaire.forEach(saisieObjet => {
                let saisieCoContractant = this.checkUpdateCoContractantForObjetFormulaire(saisieObjet, saisieFormulaire);
                if (saisieCoContractant) {
                    saisieCoContractantToSave.addObject(saisieCoContractant);
                }
            });

            if (!Ember.isEmpty(saisieCoContractantToSave)) {
                await (0, _promiseArray.default)(saisieCoContractantToSave, async saisieCoContractant => {
                    if (saisieCoContractant.get('hasDirtyAttributes')) await saisieCoContractant.save();
                });
            }

            // Puis la saisie super_calque
            saisieSuperCalque.set('etat_saisie', 'done');
            if (saisieSuperCalque.get('hasDirtyAttributes')) {
                await saisieSuperCalque.save();
            }
        },

        /**
         * Vérifie si un "saisie-objet-formulaire" est lié à une rubrique Co-contractant et met à jour et retourne l'objet saisie-co-contractant si besoin
         * @author mestres
         * @param  {saisie-objet-formulaire} saisieObjet L'objet saisie mis à jour
         * @param  {saisie-formulaire} saisieFormulaire L'objet saisie formulaire lié à l'objet [optionnel]
         * @return {saisie-co-contractant}             la saisie-co-contractant mise à jour (ou null si l'objet passé n'est pas lié à un co-contractant)
         */
        checkUpdateCoContractantForObjetFormulaire: function (saisieObjet, saisieFormulaire = null) {

            if (Ember.isEmpty(saisieFormulaire)) {
                saisieFormulaire = saisieObjet.get('saisie_formulaire');
            }

            let objet_formulaire = saisieObjet.get('objet_formulaire');
            let libelle_rubrique = objet_formulaire.get('rubrique.libelle');

            if (objet_formulaire.get('calque.is_coo_contractant') && libelle_rubrique !== undefined && libelle_rubrique.startsWith(_ceagConfigLot2.default.nomRubriqueGeneriqueCoContractant)) {

                // Si oui, alors on alimente la "saisie-co-contractant" correspondantes ...
                // On extrait le "Nom" et l'index du co-contractant à partir du nom de la rubrique (ex "CC_Nom_002" => ['CC', 'Nom', '02'])
                let infosRubrique = libelle_rubrique.split('_');
                // let indexCoContractant = infosRubrique[2] - 1;

                // C'est ici que ça merde, la liste des saisies_co_contractant n'est pas triée du tout.
                // let coContractantsIndex = [];
                // let saisieCoContractant = saisieFormulaire.get('saisies_co_contractant').objectAt(indexCoContractant);
                let saisieCoContractant = this.getSaisieCoContractantFromSaisieObject(saisieObjet);

                if (!Ember.isEmpty(saisieCoContractant)) {
                    //Quel attribut doit on valeuriser ?
                    let attributCoContractant = _ceagConfigLot2.default.nomRubriqueCoContractant[infosRubrique[1]];
                    if (!Ember.isEmpty(attributCoContractant)) {
                        saisieCoContractant.set(attributCoContractant, saisieObjet.get('donnee_courte'));
                    }
                }
                return saisieCoContractant;
            }

            return null;
        },

        /**
         * Retourne un identifiant de saisie coContractant pour un object donné ou null s'il n'y en a pas
         * pour un index donnée. L'index correspondant à la position du co contractant dans une liste 
         * ordonnée. pas ideal mais fonctionnel, nécessite une bonne construction des formulaires
         * 
         * Toute cette méthode est lourde et executé un nombre trop important de fois, une solution serait de traiter
         * le soucis en amont pour s'éviter tout ce travail, mais un tel refactoring nécessite beaucoup de temps :(
         * 
         * @param {*} saisieObject 
         */
        getSaisieCoContractantFromSaisieObject: function (saisieObject) {

            // saisie_formulaire : le formulaire en cours de saisie
            let saisie_formulaire = saisieObject.get('saisie_formulaire');

            // saisie_co_contractants : la liste des sous contractants présents dans le formulaire, tout calque ou superCalque confondu
            let saisie_co_contractants = saisie_formulaire.get('saisies_co_contractant');

            // La recherche s'effectue de base sur l'index du coContractant, a savoir sa position dans une liste à l'ordre plus ou moins
            // aléatoire (id alpha random) donc on va le refaire.
            // Comme nous sommes ici, nous savons déjà que nous sommes avec un objet disposant d'une rubrique coContractant
            let libelleRubrique = saisieObject.get('objet_formulaire.rubrique.libelle');
            let infosRubrique = libelleRubrique.split('_');

            // Et nous récupérons le numéro du coContractant plutôt qu'un index arbitraire
            let numeroCoContractant = parseInt(infosRubrique[2]);

            // Nous faisons défiler les coContractants jusqu'à trouver le bon
            for (let i = 0; i < saisie_co_contractants.get('content.length'); i++) {
                let saisieCoContractant = saisie_co_contractants.objectAt(i);
                let libelleCoContractant = saisieCoContractant.get('calque_formulaire.libelle');
                let coContractantIndex = parseInt(libelleCoContractant.substr(_ceagConfigLot2.default.nomCalqueCoContractant.length + 1));

                if (numeroCoContractant == coContractantIndex) {
                    return saisieCoContractant;
                }
            }

            // pas de cocontractant : null
            return null;
        },

        /**
         * Retourne un objet de "saisie_objet_formulaire" correspondant  aux :
         *  CoContractant : signatures des co-contractant
         *  Autre : Les autres objets signature
         * @param  {saisie_formulaire} saisieFormulaire la saisie formulaire
         * @return {Array}
         * @author jmestres
         */
        getSaisiesObjetSignature: function (saisieFormulaire) {

            let saisiesObjetSignature = Ember.A();

            //On parcours tous les saisies_objet_formulaire (pages => super_calques => objets)
            //On refait un tri ici au lieu d'utiliser les CP "_ordered" de nos model, car elles n'inclut pas les sigantures
            //car elles sont utilisé juste pour le process de saisie classique
            saisieFormulaire.get('saisies_page_formulaire').sortBy('numero').forEach(saisiePageFormulaire => {
                saisiePageFormulaire.get('saisies_super_calque_formulaire').sortBy('ordre').forEach(saisieSuperCalqueFormulaire => {
                    saisieSuperCalqueFormulaire.get('saisies_objet_formulaire').sortBy('ordre', 'objet_formulaire.id:asc').forEach(saisieObjetFormulaire => {
                        if (saisieObjetFormulaire.get('isSignature')) {
                            saisiesObjetSignature.addObject(saisieObjetFormulaire);
                        } else {
                            let libelle_rubrique = saisieObjetFormulaire.get('objet_formulaire.rubrique.libelle');
                            if (libelle_rubrique !== undefined && libelle_rubrique.startsWith(_ceagConfigLot2.default.signatureRubriqueGeneriqueCoContractant)) {
                                saisiesObjetSignature.addObject(saisieObjetFormulaire);
                            }
                        }
                    });
                });
            });

            //On retourne les infos
            return saisiesObjetSignature;
        },

        /**
         * Retourne un tableau de "saisie_objet_formulaire" qui correspondent aux champ en remplissage auto
         * @param  {saisie_formulaire} saisieFormulaire la saisie formulaire
         * @return {Array}
         * @author jmestres
         */
        getSaisiesObjetAutomatique: function (saisieFormulaire) {

            let saisiesObjetAutomatique = Ember.A();

            //On parcours tous les saisies_objet_formulaire (pages => super_calques => objets)
            saisieFormulaire.get('saisies_page_formulaire').forEach(saisiePageFormulaire => {
                saisiePageFormulaire.get('saisies_super_calque_formulaire').forEach(saisieSuperCalqueFormulaire => {
                    saisieSuperCalqueFormulaire.get('saisies_objet_formulaire').forEach(saisieObjetFormulaire => {
                        if (saisieObjetFormulaire.get('isChampAutoRemplissage')) {
                            saisiesObjetAutomatique.addObject(saisieObjetFormulaire);
                        }
                    });
                });
            });

            return saisiesObjetAutomatique;
        },

        /**
         * Retourne la géolocalisation courante sous forme de string
         * @return {Promise}
         * @author jmestres
         */
        _getGeolocValue: async function () {
            let geoObject = await this.get('geolocation').getLocation();
            return `${geoObject.coords.latitude}, ${geoObject.coords.longitude}`;
        },

        /**
         * Retourne l'identifiant unique du device ou l'IP public pour la version WEB
         * @return {Promise}
         * @author mestresj
         */
        _getDeviceIDValue: async function () {
            let ret = this.get('deviceID').getDeviceIDForSaisie();
            return ret;
        },

        /**
         * S'occupe de remplir une "saisie_objet_formulaire" concernant un objet de type remplissage auto (num,date,geoloc)
         * @type {[type]}
         */
        _processSaisieObjetAutomatique: async function (saisieObjetAuto) {

            let type = saisieObjetAuto.get('objet_formulaire.type');
            let value = '';
            switch (type) {
                case 'i_uid':
                    value = (0, _moment.default)().format('YYMMDDHHmmss');
                    break;
                case 'i_date_auto':
                    value = (0, _moment.default)().format('DD/MM/YYYY HH:mm:ss');
                    break;
                case 'i_device_id':
                    value = await this._getDeviceIDValue();
                    break;
                case 'i_localisation':
                    try {
                        value = await this._getGeolocValue();
                    } catch (e) {

                        //Si champs obligatoire et pas de géo-loc, erreur ! On ne valide pas le formulaire
                        if (saisieObjetAuto.get('objet_formulaire.is_obligatoire')) {
                            throw "geoloc_hs_obligatoire";
                        }
                        value = '';
                    }
                    break;
            }

            saisieObjetAuto.set('donnee_courte', value);
            await saisieObjetAuto.save();
        },

        /**
         * Finalise une saisieFormulaire, lance le remplissage des champs automatique (num,date,geoloc), passe l'état en "done"
         * puis finalise la saisieProcessus parente si existante et terminée.
         * @param  {saisie_formulaire} saisieFormulaire la saisie à finaliser
         * @return {Promise}
         * @author jmestres
         */
        finaliseSaisieFormulaire: async function (saisieFormulaire) {

            //Objet automatique ? => Il faut les valeuriser avant la sauvegarde
            let saisiesObjetAutomatique = this.getSaisiesObjetAutomatique(saisieFormulaire);
            await (0, _promiseArray.default)(saisiesObjetAutomatique, saisieObjetAuto => {
                return this._processSaisieObjetAutomatique(saisieObjetAuto);
            });

            //puis save de la saisieFormulaire
            saisieFormulaire.set('etat_saisie', 'done');
            await saisieFormulaire.save();

            //passage "done" sur saisieProcessus si besoin.
            let saisiesFormulaire = saisieFormulaire.get('saisie_processus.saisies_formulaire');
            if (!Ember.isEmpty(saisiesFormulaire)) {

                let allDone = true;
                saisiesFormulaire.forEach(saisieForm => {
                    if (saisieForm.get('etat_saisie') != 'done') {
                        allDone = false;
                    }
                });

                if (allDone) {
                    let saisieProcessus = await saisieFormulaire.get('saisie_processus');
                    saisieProcessus.set('etat_saisie', 'done');
                    await saisieProcessus.save();
                }
            }
        },

        /**
         * Création d'un objet contenant les valeurs liées à une rubrique pour utilisation  en auto-completion
         * @param  {saisie_formulaire} saisieFormulaire   la Saisieformulaire à utiliser
         * @param  {Object} [rubriquesAutoCompletion={}]  Objet auto-completion existant à utilisé (surcharge, pour les saisie processus).
         * @return {Objet} objet contenant les valeurs d'auto-completion
         * @author jmestres
         */
        createRubriquesAutoCompletionObjetFromSaisie: function (saisieFormulaire, rubriquesAutoCompletion = {}) {

            saisieFormulaire.get('saisies_page_formulaire').forEach(saisiePageFormulaire => {
                saisiePageFormulaire.get('saisies_super_calque_formulaire').forEach(saisieSuperCalqueFormulaire => {
                    saisieSuperCalqueFormulaire.get('saisies_objet_formulaire').forEach(saisieObjetFormulaire => {

                        let donnee_courte = saisieObjetFormulaire.get('donnee_courte');
                        let donnee_longue = saisieObjetFormulaire.get('donnee_longue');
                        let rubrique_id = saisieObjetFormulaire.get('objet_formulaire.rubrique.id');
                        let rubrique_libelle = saisieObjetFormulaire.get('objet_formulaire.rubrique.libelle');

                        //Cas particulier de la signature co-contractant, on ignore.
                        if (!Ember.isEmpty(rubrique_libelle) && rubrique_libelle.startsWith(_ceagConfigLot2.default.signatureRubriqueGeneriqueCoContractant)) {
                            rubrique_id = null;
                        }

                        if (!Ember.isEmpty(rubrique_id) && (!Ember.isEmpty(donnee_courte) || !Ember.isEmpty(donnee_longue))) {
                            rubriquesAutoCompletion[rubrique_id] = {
                                donnee_courte: donnee_courte,
                                donnee_longue: donnee_longue
                            };
                        }
                    });
                });
            });

            return rubriquesAutoCompletion;
        },

        /**
         * Création d'un objet auto-completion à partir d'une saisie processus
         * @param  {saisie_processus} saisieFosaisieProcessusrmulaire   la saisieProcessus à utiliser
         * @return {Objet} objet contenant les valeurs d'auto-completion
         * @author jmestres
         */
        createRubriquesAutoCompletionObjetFromSaisieProcessus: function (saisieProcessus) {

            let rubriquesAutoCompletion = {};

            saisieProcessus.get('saisies_formulaire_ordered').forEach(saisieFormulaire => {

                if (saisieFormulaire.get('etat_saisie') == 'done' && !saisieFormulaire.get('formulaire.form_fictif')) {
                    this.createRubriquesAutoCompletionObjetFromSaisie(saisieFormulaire, rubriquesAutoCompletion);
                }
            });

            return rubriquesAutoCompletion;
        },

        /**
         * Calcule le nombre de jeton photo qui vont être consommé pour cette saisie.
         * @param  {saisie_formulaire} saisieFormulaire Saisie formulaire
         * @return {integer} nombre de jeton photo de la saisie
         * @author jmestres
         */
        calculeJetonPhotoSaisieFormulaire: function (saisieFormulaire) {

            let creditPhoto = 0;

            saisieFormulaire.get('saisies_page_formulaire').forEach(saisiePageFormulaire => {
                saisiePageFormulaire.get('saisies_super_calque_formulaire').forEach(saisieSuperCalqueFormulaire => {
                    saisieSuperCalqueFormulaire.get('saisies_objet_formulaire').forEach(saisieObjetFormulaire => {
                        if (saisieObjetFormulaire.get('objet_formulaire.type') == 'i_picture' && !Ember.isEmpty(saisieObjetFormulaire.get('donnee_longue'))) {
                            creditPhoto++;
                        }
                    });
                });
            });

            return creditPhoto;
        },

        /**
         * Retourne les index de reprise pour une saisie
         * @return {object} objet hash contenant les propriété "page" et "super_calque" de la 1ere "zone" à saisie (état 'new' ou 'pending')
         * @author jmestres
         */
        getFirstRepriseIndexes: function (saisieFormulaire) {
            let pages = saisieFormulaire.get('saisies_page_formulaire_ordered').toArray();
            for (const [ip, saisiePageFormulaire] of pages.entries()) {

                let super_calques = saisiePageFormulaire.get('saisies_super_calque_formulaire_ordered').toArray();
                for (const [isp, saisieSuperCalqueFormulaire] of super_calques.entries()) {

                    if (saisieSuperCalqueFormulaire.get('etat_saisie') != 'done') {
                        return {
                            page: ip,
                            super_calque: isp
                        };
                    }
                    //Si tous les calques sont OK, on reste sur le dernier
                    else if (ip == pages.length - 1 && isp == super_calques.length - 1 && saisieSuperCalqueFormulaire.get('etat_saisie') == 'done') {
                            return {
                                page: ip,
                                super_calque: isp
                            };
                        }
                }
            }

            return {
                page: 0,
                super_calque: 0
            };
        }

    });
});