define('ceag-lot3-front/routes/in/abonnement', ['exports', 'ceag-lot3-front/misc/admin-route'], function (exports, _adminRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adminRoute.default.extend({

        rolesAllowed: ['ROLE_PARTICULIER', 'ROLE_ADMIN_PROFESSIONNEL'],

        model: function () {
            return Ember.RSVP.hash({
                packs: this.modelFor('in').abonnements.processus,
                abonnements: this.modelFor('in').abonnements.formulaires,
                compteurs: this.modelFor('in').compteurs,
                options: this.modelFor('in').options.all
            });
        }
    });
});