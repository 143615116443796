define('ceag-lot3-front/components/formulaire/saisie/chemin-fer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        showCheminFerProcessus: Ember.computed.notEmpty('saisie.saisie_processus.id'),

        goToNotAllowed: function () {
            alert('non autorisé');
        },

        goToFormulaire: function () {
            throw "Veuillez implementer l'action \"goToFormulaire\"";
        },

        goToPage: function () {
            throw "Veuillez implementer l'action \"goToFormulaire\"";
        },

        goToSuperCalque: function () {
            throw "Veuillez implementer l'action \"goToFormulaire\"";
        }
    });
});