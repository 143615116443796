define('ceag-lot3-front/serializers/offline/choix-questionnaire', ['exports', 'ceag-lot3-front/serializers/offline/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            // formulaire:  { embedded: 'always' },
            formulaire: { serialize: 'id', deserialize: 'records' }
        }
    });
});