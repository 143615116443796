define('ceag-lot3-front/models/saisie-processus', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/saisie-processus', 'ceag-lot3-front/mixins/computed-etat-saisie', 'offline-store-sync/offline-model/offline-editable-model'], function (exports, _emberData, _saisieProcessus, _computedEtatSaisie, _offlineEditableModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend(_saisieProcessus.default, _computedEtatSaisie.default, {

        date_creation: _emberData.default.attr('date'),
        date_mise_a_jour: _emberData.default.attr('date'),

        createur: _emberData.default.belongsTo('utilisateur'),
        editeur: _emberData.default.belongsTo('utilisateur'),

        processus: _emberData.default.belongsTo('processus'),
        processus_version: _emberData.default.belongsTo('processus-version'),

        complement_saisie: _emberData.default.attr('string'),
        date_intervention: _emberData.default.attr('date'),

        complete: _emberData.default.attr('boolean', { defaultValue: false }),
        etat_saisie: _emberData.default.attr('string', { defaultValue: 'new' }),

        saisies_formulaire: _emberData.default.hasMany('saisie-formulaire', { inverse: 'saisie_processus' }),

        //Pour avoir les "étapes" de saisie toujours dans le bon ordre
        saisieSortin: ['index:asc'],
        saisies_formulaire_ordered: Ember.computed.sort('saisies_formulaire', 'saisieSortin'),

        //Dernière saisie non terminée
        saisies_formulaire_not_done: Ember.computed.filter('saisies_formulaire_ordered.@each.etat_saisie', function (saisie) {
            return saisie.get('etat_saisie') !== 'done';
        }),
        saisie_en_cours: Ember.computed.alias('saisies_formulaire_not_done.firstObject'),
        libelle: Ember.computed.alias('saisie_en_cours.libelle'),
        libelleClient: Ember.computed.alias('saisies_formulaire_ordered.firstObject.libelleClient'),

        canDelete: Ember.computed('saisie_en_cours', function () {
            let saisie_en_cours = this.get('saisie_en_cours');
            return this.get('saisies_formulaire_ordered.firstObject') === saisie_en_cours && saisie_en_cours.get('etat_saisie') != 'done';
        })
    });
});