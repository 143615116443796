define('ceag-lot3-front/components/saisie/saisie-formulaire-preview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        platform: Ember.inject.service('ember-cordova/platform'),
        fileCache: Ember.inject.service('file-cache'),
        store: Ember.inject.service(),
        connectionStatus: Ember.inject.service('connection-status'),

        isFictif: Ember.computed.alias('saisieFormulaire.formulaire_version.formulaire.form_fictif'),

        onHidden: function () {},

        isLoading: true,
        noPreview: false,
        srcFileUrl: null,

        didReceiveAttrs: function () {
            if (!this.get('isFictif')) {

                //Si saisie en état new, pas besoin d'aperçu (aucune données saisie) on prend le preview formulaire
                if (this.get('saisieFormulaire.etat_saisie') == 'new') {

                    let form_id = this.get('saisieFormulaire.formulaire_version.id');

                    if (this.get('platform.isCordova')) {

                        try {

                            this.get('fileCache').getFormPreviewUrl(form_id, 1).then(cacheURL => {
                                this.set('srcFileUrl', cacheURL);
                                this.set('isLoading', false);
                                this.set('noPreview', false);
                            }, e => {
                                console.error('Aperçu saisie non disponible', e);
                            });
                        } catch (e) {
                            this.set('srcFileUrl', null);
                            this.set('noPreview', true);
                            this.set('isLoading', false);
                        }
                    } else {
                        if (this.get('connectionStatus.isOnline')) {
                            let adapter = this.get('store').adapterFor('formulaire-version');
                            let url = adapter.getUrlForPagePreview(form_id, 1);

                            this.set('srcFileUrl', url);
                            this.set('isLoading', false);
                            this.set('noPreview', false);
                        } else {
                            this.set('srcFileUrl', null);
                            this.set('noPreview', true);
                            this.set('isLoading', false);
                        }
                    }
                } else {

                    if (this.get('platform.isCordova')) {
                        try {

                            let saisie_id = this.get('saisieFormulaire.id');
                            this.get('fileCache').getSaisiePreviewUrl(saisie_id).then(cacheURL => {
                                this.set('srcFileUrl', '');
                                this.set('srcFileUrl', cacheURL);

                                this.set('isLoading', false);
                                this.set('noPreview', false);
                            }, e => {
                                console.error('Aperçu saisie non disponible', e);
                            });
                        } catch (e) {
                            this.set('srcFileUrl', null);
                            this.set('noPreview', true);
                            this.set('isLoading', false);
                        }
                    }

                    if (this.get('connectionStatus.isOnline')) {

                        // Si nous sommes en ligne, nous en profitons pour ajouter la prévisualisation au cache
                        return this.get('fileCache').processSaisieFormulairePreview(this.get('saisieFormulaire')).then(previewDatasBlob => {

                            let previewBlobUrl = window.URL.createObjectURL(previewDatasBlob);

                            this.set('srcFileUrl', previewBlobUrl);
                            this.set('isLoading', false);
                            this.set('noPreview', false);
                        });
                    } else {
                        this.set('srcFileUrl', null);
                        this.set('noPreview', true);
                        this.set('isLoading', false);
                    }
                }
            } else {
                this.set('srcFileUrl', null);
                this.set('noPreview', true);
                this.set('isLoading', false);
            }
        }

    });
});