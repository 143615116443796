define('ceag-lot3-front/controllers/in/statistiques', ['exports', 'ember-parachute', 'ceag-lot3-front/misc/period'], function (exports, _emberParachute, _period) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.myQueryParams = undefined;
    const myQueryParams = exports.myQueryParams = new _emberParachute.default({
        debut: {
            defaultValue: null,
            refresh: false,
            replace: true,
            serialize(value) {
                return value == null ? '' : typeof value == "string" ? value : value.getTime();
            },
            deserialize(value = '') {
                return new Date(value / 1);
            }
        },
        fin: {
            defaultValue: null,
            refresh: false,
            replace: true,
            serialize(value) {
                return value == null ? '' : typeof value == "string" ? value : value.getTime();
            },
            deserialize(value = '') {
                return new Date(value / 1);
            }
        }
    });

    /* Pour validation des query params date */
    exports.default = Ember.Controller.extend(_period.default, myQueryParams.Mixin, {

        actions: {

            clearFilter: function () {
                if (!Ember.isEmpty(this.get('debut')) || !Ember.isEmpty(this.get('fin'))) {
                    this.set('debut', null);
                    this.set('fin', null);
                    this.send('refreshSearch');
                }
            },

            goSearch: function () {
                this.send('refreshSearch');
            }
        }
    });
});