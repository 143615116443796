define('ceag-lot3-front/components/formulaire/saisie/objets/i-textarea-objet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        onUpdate: function () {},

        updateInputSize: function () {
            let input = this.$('textarea')[0];
            // input.style.height = "";

            if (input.scrollHeight > input.clientHeight) input.style.height = input.scrollHeight + "px";
        },

        didInsertElement() {
            this._super(...arguments);
            this.updateInputSize();
        },
        actions: {

            updateInputSizeAction: function () {
                this.updateInputSize();
            }

        }
    });
});