define('ceag-lot3-front/controllers/in/processus/action', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        indexFormulaire: 0,
        currentFormulaire: Ember.computed('indexFormulaire', 'model.last_version', function () {
            //Dernière version du formulaire de l'etape processus selectionnée
            return this.get('model.last_version.etapes').objectAt(this.get('indexFormulaire')).get('formulaire.last_version');
        }),

        intl: Ember.inject.service(),

        connectionStatus: Ember.inject.service('connection-status'),
        isOffline: Ember.computed.alias('connectionStatus.isOffline'),

        actions: {

            creationAction: function () {

                let intl = this.get('intl');

                if (!this.get('model.abonnements').filterBy('hasExpire', false).length) {
                    return this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
                }

                if (this.get('model.autoRemplissage') === true) {

                    this.get('confirmationService').confirm(intl.t('saisie.use_archive.titre'), intl.t('saisie.use_archive.contenu'), {
                        okLabel: intl.t('global.oui'),
                        cancelLabel: intl.t('global.non')
                    })
                    //Oui
                    .then(() => {
                        alert('todo!');
                    })
                    //non
                    .catch(() => {
                        this.transitionToRoute('in.processus.saisie');
                    });
                } else {
                    this.transitionToRoute('in.processus.saisie');
                }
            }
        }

    });
});