define('ceag-lot3-front/controllers/in/formulaire/saisie/preview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        saisieProcessus: Ember.computed.alias('model.saisieFormulaire.saisie_processus'),
        isSaisieProcessus: Ember.computed.notEmpty('saisieProcessus.id'),
        isLastSaisie: Ember.computed('model.saisieFormulaire.id', 'saisieProcessus.saisies_formulaire_ordered.lastObject.id', function () {
            return this.get('model.saisieFormulaire.id') == this.get('saisieProcessus.saisies_formulaire_ordered.lastObject.id');
        }),
        haveNextSaisieFormulaire: Ember.computed.not('isLastSaisie'),

        //Est-ce qu'une saisieFormulaire suivante existe (pour des saisieFormulaire lié à une saisieProcessus)
        nextSaisieFormulaire: Ember.computed('model.saisieFormulaire.saisie_processus', function () {

            if (!this.get('isSaisieProcessus')) {
                return false;
            }

            //On determine si une saisie suivante existe
            let saisiesFormulaire = this.get('model.saisieFormulaire.saisie_processus.saisies_formulaire_ordered');
            let saisieCourante = this.get('model.saisieFormulaire');

            let indexCourante = saisiesFormulaire.indexOf(saisieCourante);
            if (indexCourante > saisiesFormulaire.get('length') - 1) {
                return false;
            }

            return saisiesFormulaire.objectAt(indexCourante + 1);
        }),

        connectionStatus: Ember.inject.service('connection-status'),
        isOnline: Ember.computed.alias('connectionStatus.isOnline'),
        isRealOnline: Ember.computed.alias('connectionStatus.isRealOnline')
    });
});