define("ceag-lot3-front/utils/intl/missing-message", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = missingMessage;
    function missingMessage(key, locales, options) {
        console.warn(`[ember-intl] Missing translation for key: "${key}" for locales: "${locales}"`, options);
        return key;
        //return key.split('.').pop();
    }
});