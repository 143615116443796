define('ceag-lot3-front/services/geolocation', ['exports', 'ember-cli-geo/services/geolocation', 'ceag-lot3-front/config/environment'], function (exports, _geolocation, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _geolocation.default.extend({

        platform: Ember.inject.service('ember-cordova/platform'),

        getLocation: function () {

            if (this.get('platform.isCordova')) {

                //On passe par Cordova pour les mobiles
                return new Ember.RSVP.Promise((resolve, reject) => {

                    //En dev on simule car Cordova fait comme une demande de localisation HTML classique et non autorisé car non HTTPS
                    if (_environment.default.environment == 'development' && this.get('platform.isAndroid')) {
                        resolve({
                            coords: {
                                latitude: 123.4567891011,
                                longitude: 123.4567891011
                            }
                        });
                    } else {
                        let options = { maximumAge: 30000, timeout: 5000, enableHighAccuracy: true };
                        navigator.geolocation.getCurrentPosition(resolve, reject, options);
                    }
                });
            } else {
                //Sinon on appèle la méthode de "ember-cli-geo".
                return this._super(...arguments);
            }
        }

    });
});