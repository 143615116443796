define('ceag-lot3-front/controllers/in/processus/saisie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        actions: {
            resetNewSaisie: function () {
                this.get('model.saisieProcessus').rollbackAttributes();
            }
        }
    });
});