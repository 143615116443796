define('ceag-lot3-front/initializers/offline-store-sync', ['exports', 'offline-store-sync/configuration', 'ceag-lot3-front/config/environment'], function (exports, _configuration, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'offline-store-sync',
    initialize(container) {
      _configuration.default.load(container, _environment.default || {});
    }
  };
});