define('ceag-lot3-front/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ceag-lot3-front/misc/login', 'ceag-lot3-front/misc/forgot-password'], function (exports, _unauthenticatedRouteMixin, _login, _forgotPassword) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

        model: function () {
            let log = _login.default.create(Ember.getOwner(this).ownerInjection());

            return Ember.RSVP.hash({
                //http://offirgolan.github.io/ember-cp-validations/docs/modules/Basic.html#a-note-on-testing--object-containers
                loginModel: log,
                forgotPasswordModel: _forgotPassword.default.create(Ember.getOwner(this).ownerInjection())
            });
        }
    });
});