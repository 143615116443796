define('ceag-lot3-front/components/formulaire/slider-formulaire', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        index: 0,

        /**
         * Le formulaire réél à afficher (1er formulaire du questionnaire dans le cas d'un fictif)
         * @return {[type]}
         * @author jmestres
         */
        real_formulaire_version: Ember.computed('formulaire', function () {
            let formulaire_version = this.get('formulaire');

            if (formulaire_version.get('formulaire.form_fictif')) {
                //Dernière version formulaire du 1er choix du questionnaire lié au formulaire courant
                return formulaire_version.get('questionnaire.last_version.choix_questionnaire.firstObject.formulaire.last_version');
            }

            return formulaire_version;
        })

    });
});