define('ceag-lot3-front/adapters/online/formulaire-version', ['exports', 'ceag-lot3-front/adapters/online/application', 'ceag-lot3-front/config/environment'], function (exports, _application, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({

        getUrlForPagePreview: function (formulaireVersionId, pageNumber) {
            return `${_environment.default.apiHost}/${_environment.default.apiNamespace}/formulaire_versions/${formulaireVersionId}/preview/${pageNumber}`;
        }

    });
});