define('ceag-lot3-front/components/formulaire/saisie/questionnaire-popup', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_pendingObject.default, {
        onSelect: function () {},
        actions: {
            selectFormAction: function (formulaire) {
                return this.goPending(this.get('onSelect')(formulaire));
            }
        }
    });
});