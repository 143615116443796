define('ceag-lot3-front/components/ui/icon-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: 'btn icon-btn',
        action: function () {},

        actions: {
            goAction: function () {
                this.get('action')();
            }
        }
    });
});