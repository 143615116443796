define('ceag-lot3-front/mixins/loading-slider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    loadingSlider: Ember.inject.service(),

    actions: {
      loading() {
        let loadingSliderService = this.get('loadingSlider');
        loadingSliderService.startLoading();
        if (Ember.isPresent(this._router)) {
          this._router.one('didTransition', function () {
            loadingSliderService.endLoading();
          });
        }
        if (this.get('bubbleLoadingSlider')) {
          return true;
        }
      },

      finished() {
        this.get('loadingSlider').endLoading();
      }
    }
  });
});