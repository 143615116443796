define('ceag-lot3-front/models/rubrique', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        libelle: _emberData.default.attr('string'),

        sous_categorie: _emberData.default.belongsTo('sous-categorie')
    });
});