define('ceag-lot3-front/serializers/online/utilisateur', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            infos_prestashop: {
                serialize: false,
                deserialize: 'records'
            },
            abonnements_pro: {
                serialize: 'ids',
                deserialize: 'ids'
            },

            role: {
                serialize: false,
                deserialize: true
            }

        }
    });
});