define('ceag-lot3-front/components/sync-pending', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['black-modal-backdrop'],

        offlineTaskManager: Ember.inject.service('task-manager'),

        totalTask: Ember.computed.alias('offlineTaskManager.initialeTaskSize'),
        countTask: Ember.computed.alias('offlineTaskManager.taskList.length'),
        doneTask: Ember.computed('totalTask', 'countTask', function () {
            return this.get('totalTask') - this.get('countTask');
        })

    });
});