define('ceag-lot3-front/components/statistiques/td-table-stats', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'td',
        classNames: ['stats-column'],

        currentStats: Ember.computed('statistiques', 'abonnement.{formulaire.id,processus.id,is_pack}', 'user.id', function () {
            let stats = this.get('statistiques');
            let user_id = this.get('user.id');

            let objet_id = this.get('abonnement.is_pack') ? this.get('abonnement.processus.id') : this.get('abonnement.formulaire.id');
            let abo_type = this.get('abonnement.is_pack') ? 'processus' : 'formulaire';

            if (!Ember.isEmpty(stats[abo_type][objet_id]) && !Ember.isEmpty(stats[abo_type][objet_id][user_id])) {
                return stats[abo_type][objet_id][user_id];
            }

            return null;
        }),

        nbSupprimee: Ember.computed.alias('currentStats.supprimee'),
        nbValidee: Ember.computed.alias('currentStats.validee'),
        nbTotal: Ember.computed('nbSupprimee', 'nbValidee', function () {
            return parseInt(this.get('nbSupprimee')) + parseInt(this.get('nbValidee'));
        })

    });
});