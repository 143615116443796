define('ceag-lot3-front/models/validations/utilisateur', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        mail: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],

        nom: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 3,
            max: 64 //TODO: totalement arbitrare pour le moment, à revoir
        })],
        prenom: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 3,
            max: 64 //TODO: totalement arbitrare pour le moment, à revoir
        })],

        trigramme: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 3,
            max: 3
        })],

        mdp: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isNew')
        }), (0, _emberCpValidations.validator)('length', {
            allowBlank: true,
            min: 8,
            max: 32 //TODO: totalement arbitrare pour le moment, à revoir
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/,
            allowBlank: true,
            message: 'Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial'
        })],
        mdp_conf: [(0, _emberCpValidations.validator)('confirmation', {
            on: 'mdp',
            allowBlank: true,
            message: 'La confirmation  ne correspond pas au mot de passe'
        })]

    });
});