define('ceag-lot3-front/models/etape-processus', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        index: _emberData.default.attr('number'),
        version_processus: _emberData.default.belongsTo('processus-version'),

        formulaire: _emberData.default.belongsTo('formulaire')
    });
});