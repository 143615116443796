define('ceag-lot3-front/models/validations/saisie-formulaire', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({

        editeur: [(0, _emberCpValidations.validator)('presence', true)],

        complement_saisie: [(0, _emberCpValidations.validator)('length', {
            max: 255
        })],
        date_intervention: [
            // validator('date', {
            // onOrAfter : 'now',
            // precision: 'day',
            // message : 'La date et heure de l’événement doivent être renseignés.',
            // allowBlank : true,
            // }),
        ]
    });
});