define('ceag-lot3-front/models/abonnement', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/abonnement', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _abonnement, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend(_abonnement.default, {

        actif: _emberData.default.attr('boolean'),

        formulaire: _emberData.default.belongsTo('formulaire'),
        processus: _emberData.default.belongsTo('processus'),

        from: _emberData.default.attr('date'),
        is_pack: _emberData.default.attr('boolean'),
        libelle: _emberData.default.attr('string'),
        mail_archive: _emberData.default.attr('string'),

        nb_device: _emberData.default.attr('number'),
        nb_jeton: _emberData.default.attr('number'),

        to: _emberData.default.attr('date'),

        //Liaison pour les droits
        // utilisateurs_pro : DS.hasMany('utilisateur', {inverse : 'abonnements_pro'}),
        // !! Ne pas activer sans modification du module de synchro offline.
        // si active, le module offline, va bouclé sur les 2 relations hasMany.
        // Il faudrait implémenter une fonctionnalité permettant d'exlure un des 2 côtés des relation N<=>N

        utilisateur: _emberData.default.belongsTo('utilisateur', { inverse: 'abonnements' }),

        hasExpire: Ember.computed(function () {
            return this.get('to') < new Date();
        }),

        auto: Ember.computed.alias('formulaire.autoRemplissage')
    });
});