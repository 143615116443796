define('ceag-lot3-front/components/ui/icon-button-pending', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_pendingObject.default, {

        classNames: 'btn icon-btn',
        pendingIcon: 'ellipsis-h',
        pendingAnimation: 'slideInUp',

        actions: {
            goAction: function () {
                this.goPending(this.get('action')());
            }
        }
    });
});