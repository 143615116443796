define('ceag-lot3-front/serializers/online/saisie-objet-formulaire', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data', 'ceag-lot3-front/serializers/online/mixins/serialize-id'], function (exports, _application, _emberData, _serializeId) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, _serializeId.default, {
        // Pour Rappel default :
        //  BelongsTo: { serialize: 'id', deserialize: 'id' }
        //  HasMany: { serialize: false, deserialize: 'ids' }
        attrs: {
            offlineSyncStatus: { serialize: false },
            offlineLastUpdate: { serialize: false }
        }
    });
});