define('ceag-lot3-front/misc/admin-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service(),
        intl: Ember.inject.service(),

        rolesAllowed: Ember.A(),
        rolesDisallowed: Ember.A(),

        userRolesIntersectAllowed: Ember.computed.intersect('session.data.authenticated.role', 'rolesAllowed'),
        userRolesIntersectDisallowed: Ember.computed.intersect('session.data.authenticated.role', 'rolesDisallowed'),

        userAllowed: Ember.computed.notEmpty('userRolesIntersectAllowed'),
        userDisallowed: Ember.computed.notEmpty('userRolesIntersectDisallowed'),

        beforeModel: function (transition) {

            //"Denied puis allow"
            let isAllow = !this.get('userDisallowed') && this.get('userAllowed');

            if (!isAllow) {

                transition.abort();

                this.get('confirmationService').alert(this.get('intl').t('misc.non_autorise.titre'), this.get('intl').t('misc.non_autorise.contenu'), { typeAlert: 'danger' });
            }

            return this._super(...arguments);
        }

    });
});