define('ceag-lot3-front/controllers/reset', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_pendingObject.default, {

        resetPasswordModel: Ember.computed.alias('model'),
        store: Ember.inject.service(),
        intl: Ember.inject.service(),

        actions: {
            reset: function () {

                let model = this.get('model');
                let datas = {
                    _token: model.get('token'),
                    _password: model.get('password'),
                    _username: model.get('mail')
                };

                const adapter = this.get('store').adapterFor('application');

                let resetPassPromise = adapter.apiCall('confirm_reset_password', {
                    data: datas,
                    method: 'POST'
                });

                resetPassPromise.then(() => {
                    this.transitionToRoute('login').then(() => {
                        this.get('messageService').success(this.get('intl').t('resetpwd.succes'));
                    });
                }).catch(error => {
                    console.error("reset-confirm failed !", error);
                    this.get('messageService').error(this.get('intl').t('resetpwd.erreur'));
                });

                this.goPending(resetPassPromise);
            }
        }
    });
});