define('ceag-lot3-front/controllers/in/formulaires', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function tableSortToComputed(sort) {
        if (sort && sort.charAt(0) != '-') {
            return [sort + ':asc'];
        } else if (sort) {
            return [sort.substring(1) + ':desc'];
        }

        return ['id:asc'];
    }

    exports.default = Ember.Controller.extend({

        session: Ember.inject.service(),

        titreTrad: Ember.computed('session.data.authenticated.role', function () {

            switch (this.get('session.data.authenticated.role.firstObject')) {

                case 'ROLE_ADMIN_PROFESSIONNEL':
                    return 'titre.formulaires.admin';
                case 'ROLE_PROFESSIONNEL':
                    return 'titre.formulaires.pro';
                case 'ROLE_PARTICULIER':
                    return 'titre.formulaires.par';
            }

            return 'titre.formulaires.par';
        }),

        queryParams: ['sortForm', 'sortProc'],

        sortForm: '',
        sortFormDefinition: Ember.computed('sortForm', function () {
            return tableSortToComputed(this.get('sortForm'));
        }),
        sortedAbonnement: Ember.computed.sort('model.abonnements.formulaires', 'sortFormDefinition'),

        sortProc: '',
        sortProcDefinition: Ember.computed('sortProc', function () {
            return tableSortToComputed(this.get('sortProc'));
        }),
        sortedProc: Ember.computed.sort('model.abonnements.processus', 'sortProcDefinition'),

        //On déduis la liste des formulaire à partir des abonnement
        formulaires: Ember.computed.mapBy('sortedAbonnement', 'formulaire'),
        processus: Ember.computed.mapBy('sortedProc', 'processus'),

        actions: {

            /**
             * Demande de saisie d'un formulaire
             * @param  {model} formulaire le formulaire
             * @author jmestres
             */
            actionFormulaire: function (formulaire) {
                this.transitionToRoute('in.formulaire.action', formulaire.get('id'));
            },

            /**
             * Demande de saisie d'un processus
             * @param  {model} processus le processus
             * @author jmestres
             */
            actionProcessus: function (processus) {
                this.transitionToRoute('in.processus.action', processus.get('id'));
            }
        }
    });
});