define('ceag-lot3-front/models/page-formulaire', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({
        fond_page: _emberData.default.attr('number'),
        numero: _emberData.default.attr('number'),

        formulaire_version: _emberData.default.belongsTo('formulaire-version'),

        super_calques: _emberData.default.hasMany('super-calque-formulaire')
        // objets : DS.hasMany('objet-formulaire'),
    });
});