define('ceag-lot3-front/misc/file-reader', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (blobData) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
            var reader = new FileReader();

            reader.onload = function (e) {
                resolve(e.target.result);
            };

            reader.onerror = function () {
                reject(this);
            };

            reader.readAsDataURL(blobData);
        });
    };
});