define('ceag-lot3-front/misc/promise-array', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (arrayObject, promiseFunction) {

        if (Ember.isEmpty(arrayObject)) {
            arrayObject = Ember.A([]);
        }

        return Ember.RSVP.Promise.all(arrayObject.map(promiseFunction));
    };
});