define('ceag-lot3-front/misc/reset-password', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Objet permettant de valider les information de login
     * @author jmestres
     */
    const Validations = (0, _emberCpValidations.buildValidations)({
        token: [(0, _emberCpValidations.validator)('presence', {
            presence: true
        })],
        password: [(0, _emberCpValidations.validator)('presence', {
            presence: true
        }), (0, _emberCpValidations.validator)('length', {
            allowBlank: true,
            min: 8,
            max: 32 //TODO: totalement arbitraire pour le moment, à revoir
        })],
        password_conf: [(0, _emberCpValidations.validator)('confirmation', {
            on: 'password'
        })]

    });

    exports.default = Ember.Object.extend(Validations, {
        token: null,
        password: null,
        password_conf: null,
        mail: null
    });
});