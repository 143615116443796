define('ceag-lot3-front/controllers/in/formulaire/saisie/index', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_pendingObject.default, {

        //Query param pour avancement de la saisie
        queryParams: ['page', 'super_calque', 'show_erreur'],
        page: 0,
        super_calque: 0,
        show_erreur: false,

        //Direction d'animation
        liquidFireDirection: 'toLeft',

        /**
         * Retourne la page courante.
         */
        saisiePageCourante: Ember.computed('model.saisieFormulaire', 'page', function () {
            let saisieFormulaire = this.get('model.saisieFormulaire');
            if (!saisieFormulaire.get('formulaire_version.formulaire.form_fictif')) {
                try {
                    let pageCourante = saisieFormulaire.get('saisies_page_formulaire_ordered').objectAt(this.get('page'));
                    return pageCourante;
                } catch (e) {
                    alert('No page!');
                    throw 'Erreur, page non trouvée ou non présente.';
                }
            }
            return null;
        }),

        /**
         * Retourne le super calque courant
         */
        saisieSuperCalqueCourant: Ember.computed('saisiePageCourante', 'super_calque', function () {

            let saisiePageCourante = this.get('saisiePageCourante');
            if (!Ember.isEmpty(saisiePageCourante)) {
                try {
                    let SuperCalqueCourant = saisiePageCourante.get('saisies_super_calque_formulaire_ordered').objectAt(this.get('super_calque'));
                    return SuperCalqueCourant;
                } catch (e) {
                    alert('No super layer!');
                    throw 'Erreur,  super-calque non trouvé ou non présent (page vide ?)';
                }
            }
            return null;
        }),

        saisie: Ember.inject.service(),
        fileCache: Ember.inject.service('file-cache'),
        intl: Ember.inject.service(),

        // Les éléments de saisie groupés pour les radio (seul cas particulier ?)
        objets_saisie_grouped: Ember.computed('saisieSuperCalqueCourant.saisies_objet_formulaire_ordered', function () {

            let objets_saisie = this.get('saisieSuperCalqueCourant.saisies_objet_formulaire_ordered').toArray();
            let objet = null,
                groupes_objects = Ember.A([]);

            while (objet = objets_saisie.shift()) {

                if (objet.get('objet_formulaire.type') === 'i_radio') {
                    let groupe = objet.get('objet_formulaire.proprietes.groupe');

                    //On crée un tableau regroupant les radio du même groupe
                    let radio_object = Ember.A([objet]);

                    //Puis on enlève du tableau initiale les objet qui ont le même groupe pour les mettre dans notre tableau "radio"
                    let other_in_group = objets_saisie.filterBy('objet_formulaire.proprietes.groupe', groupe);
                    objets_saisie.removeObjects(other_in_group);
                    radio_object.pushObjects(other_in_group);

                    groupes_objects.pushObject(radio_object);
                } else {
                    groupes_objects.pushObject(objet);
                }
            }

            return groupes_objects;
        }),

        isFirstPage: Ember.computed.equal('page', 0),
        isFirstSuperCalque: Ember.computed.equal('super_calque', 0),
        isFirstSaisie: Ember.computed.and('isFirstPage', 'isFirstSuperCalque'),

        isLastPage: Ember.computed('saisiePageCourante', function () {
            return this.get('saisiePageCourante.saisie_formulaire.saisies_page_formulaire_ordered.lastObject.id') === this.get('saisiePageCourante.id');
        }),

        isLastSuperCalque: Ember.computed('saisiePageCourante', 'saisieSuperCalqueCourant', function () {
            return this.get('saisiePageCourante.saisies_super_calque_formulaire_ordered.lastObject.id') === this.get('saisieSuperCalqueCourant.id');
        }),

        isLastSaisie: Ember.computed.and('isLastPage', 'isLastSuperCalque'),

        isSaisieProcessus: Ember.computed.notEmpty('model.saisieFormulaire.saisie_processus.id'),

        formWasSubmit: false, //Cette valeur est "reset" à chaque changement de model par la route


        checkErrorOnCurrentSuperCalque: function () {

            // Déclancher les validation des "enfants"
            this.get('saisieSuperCalqueCourant.saisies_objet_formulaire').forEach(saisieObjetFormulaire => {
                saisieObjetFormulaire.validate();
            });

            // Affichage "forcé" des erreurs sur les form "enfants"
            this.set('formWasSubmit', true);

            // On retourne une promise résolut si aucune erreur, rejetée sinon
            return new Ember.RSVP.Promise((resolve, reject) => {

                let hasError = false;
                this.get('saisieSuperCalqueCourant.saisies_objet_formulaire').forEach(saisieObjetFormulaire => {
                    if (!saisieObjetFormulaire.get('validations.isValid') && !saisieObjetFormulaire.get('isSignature')) {
                        hasError = true;
                    }
                });

                hasError ? reject() : resolve();
            });
        },

        saveCurrentSuperCalque: async function () {

            let saisieSuperCalqueCourant = this.get('saisieSuperCalqueCourant');

            await this.get('saisie').saveSaisieSuperCalque(saisieSuperCalqueCourant);

            // On sauvegarde aussi la saisie Page vu que son état à surement changé en "pending"
            await this.checkAndSaveSaisiePage();

            // Idem pour le formulaire
            // if( this.get('model.saisieFormulaire.hasDirtyAttributes')) //ATTENTION, la sauvegarde systèmatique est volontaire (Voir commit n°257)
            await this.get('model.saisieFormulaire').save();

            // On lance la génération + mise en cache du preview saisie
            await this.get('fileCache').processSaisieFormulairePreview(this.get('model.saisieFormulaire'));
        },

        actions: {

            goToFormulaireAction: function () /*idx*/{
                console.error('pas possible !!!');
            },

            goToPageAction: function (idx) {
                let page = this.get('page');
                if (idx != page) {
                    //Avant de naviguer, on check les erreurs et on sauvegarde
                    this.checkErrorOnCurrentSuperCalque().then(async () => {
                        this.saveCurrentSuperCalque();
                        this.set('liquidFireDirection', idx > page ? 'toLeft' : 'toRight');
                        this.set('page', idx);
                        this.set('super_calque', 0);
                    }, () => {
                        this.get('confirmationService').alert(this.get('intl').t('saisie.erreur_nav.titre'), this.get('intl').t('saisie.erreur_nav.contenu'));
                    });
                }
            },

            goToSuperCalqueAction: function (idx) {

                let super_calque = this.get('super_calque');
                if (idx != super_calque) {

                    //Avant de naviguer, on check les erreurs et on sauvegarde
                    this.checkErrorOnCurrentSuperCalque().then(async () => {

                        this.saveCurrentSuperCalque();

                        this.set('liquidFireDirection', idx > super_calque ? 'toLeft' : 'toRight');
                        this.set('super_calque', idx);
                    }, () => {
                        this.get('confirmationService').alert(this.get('intl').t('saisie.erreur_nav.titre'), this.get('intl').t('saisie.erreur_nav.contenu'));
                    });
                }
            },

            retourSaisieAction: function () {

                this.get('confirmationService').confirm(this.get('intl').t('saisie.retour.titre'), Ember.String.htmlSafe(this.get('intl').t('saisie.retour.contenu')), {
                    okLabel: this.get('intl').t('global.oui'),
                    cancelLabel: this.get('intl').t('global.non')
                })
                //Oui
                .then(() => {

                    if (this.get('isSaisieProcessus')) {
                        this.transitionToRoute('in.processus.action', this.get('model.saisieFormulaire.saisie_processus.processus'));
                    } else {
                        this.transitionToRoute('in.formulaire.action');
                    }
                });
            },

            previousStepSaisieAction: function () {

                //Avant de naviguer, on check les erreurs et on sauvegarde
                this.checkErrorOnCurrentSuperCalque().then(async () => {

                    await this.saveCurrentSuperCalque();
                    let idx_page = this.get('page');
                    let idx_super_calque = this.get('super_calque');

                    if (this.get('isFirstSuperCalque')) {
                        idx_page--;
                        idx_super_calque = this.get('saisiePageCourante.saisie_formulaire.saisies_page_formulaire_ordered').objectAt(idx_page).get('saisies_super_calque_formulaire_ordered.length') - 1;
                    } else {
                        idx_super_calque--;
                    }

                    this.set('formWasSubmit', false);
                    this.set('liquidFireDirection', 'toRight');
                    this.transitionToRoute('in.formulaire.saisie.index', { queryParams: { page: idx_page, super_calque: idx_super_calque, show_erreur: false } });
                });
            },

            nextStepSaisieAction: function () {

                // On sauvegarde la saisie avant toute opération
                this.goPending(this.saveCurrentSuperCalque().then(async () => {

                    let idx_page = this.get('page');
                    let idx_super_calque = this.get('super_calque');

                    // Plus de saisie à venir, on passe aux signatures ?
                    if (this.get('isLastSaisie')) {
                        let erreurIndexes = this.getFirstErreurIndexes();
                        if (Ember.isEmpty(erreurIndexes)) {
                            this.transitionToRoute('in.formulaire.saisie.signature');
                        } else {
                            this.get('confirmationService').alert(this.get('intl').t('saisie.erreur_nav.titre'), this.get('intl').t('saisie.erreur_nav.contenu'), { typeAlert: 'danger' }).then(() => {
                                this.transitionToRoute('in.formulaire.saisie.index', { queryParams: { page: erreurIndexes.page, super_calque: erreurIndexes.super_calque, show_erreur: true } });
                            });
                        }
                    } else {
                        if (this.get('isLastSuperCalque')) {
                            idx_page++;
                            idx_super_calque = 0;
                        } else {
                            idx_super_calque++;
                        }

                        this.set('formWasSubmit', false);
                        this.set('liquidFireDirection', 'toLeft');
                        this.transitionToRoute('in.formulaire.saisie.index', { queryParams: { page: idx_page, super_calque: idx_super_calque, show_erreur: false } });
                    }
                }));
            },

            quitterSaisieAction: async function () {

                //On sort, le "willTransition" sur la route fera le job si saisie non sauvegardée
                if (this.get('isSaisieProcessus')) {
                    return this.transitionToRoute('in.processus.action', this.get('model.saisieFormulaire.saisie_processus.processus'));
                } else {
                    return this.transitionToRoute('in.formulaire.action');
                }
            },

            onUpdateSaisieObjetAction: function (saisieObjet /*, value*/) {

                //A partir du moment ou un champ change, la saisie passe en 'pending'...
                let saisieSuperCalque = saisieObjet.get('saisie_super_calque_formulaire');
                if (saisieSuperCalque.get('etat_saisie') === 'new') {
                    saisieSuperCalque.set('etat_saisie', 'pending');
                }
                let saisiePage = saisieSuperCalque.get('saisie_page_formulaire');
                if (saisiePage.get('etat_saisie') === 'new') {
                    saisiePage.set('etat_saisie', 'pending');
                }
                let saisieFormulaire = saisiePage.get('saisie_formulaire');
                if (saisieFormulaire.get('etat_saisie') === 'new') {
                    saisieFormulaire.set('etat_saisie', 'pending');
                }
            },

            /**
             * Avant de valider le "submit" du form, on fait une validation "manuelle" des objets de saisie.
             * @return {RSVP.Promise}
             * @author jmestres
             */
            beforeFormSubmitAction: function () {
                return this.checkErrorOnCurrentSuperCalque();
            }

        },

        /**
         * Sauvegarde la saisiePage courante et change son état si besoin
         * @return {[type]}
         * @author jmestres
         */
        checkAndSaveSaisiePage: async function () {

            //La saisie de la page est-elle terminée ?
            let allDone = true;
            this.get('saisiePageCourante.saisies_super_calque_formulaire_not_hidden').forEach(saisieSuperCalque => {
                if (saisieSuperCalque.get('etat_saisie') != 'done') allDone = false;
            });

            if (allDone) {
                this.get('saisiePageCourante').set('etat_saisie', 'done');
            }

            if (this.get('saisiePageCourante.hasDirtyAttributes')) await this.get('saisiePageCourante').save();
        },

        /**
         * Permet de tester la validité de tous les objets saisieFormulaire de la saisieCourante
         * et retourne l'index de la page/super_calque de la 1ere saisieObjetFormulaire en erreur ou null sinon
         * @return {Objet}
         * @author jmestres
         */
        getFirstErreurIndexes: function () {

            let saisieFormulaire = this.get('model.saisieFormulaire');

            for (const [ip, saisiePageFormulaire] of saisieFormulaire.get('saisies_page_formulaire_ordered').toArray().entries()) {

                for (const [isp, saisieSuperCalqueFormulaire] of saisiePageFormulaire.get('saisies_super_calque_formulaire_ordered').toArray().entries()) {

                    for (let saisieObjetFormulaire of saisieSuperCalqueFormulaire.get('saisies_objet_formulaire_ordered').toArray()) {

                        saisieObjetFormulaire.validate();
                        if (!saisieObjetFormulaire.get('validations.isValid')) {

                            return {
                                page: ip,
                                super_calque: isp
                            };
                        }
                    }
                }
            }

            // Identique au code ci-dessus, je juste voulais tester le for( ... of ) ;-)
            //
            // let saisiesPageFormulaire = saisieFormulaire.get('saisies_page_formulaire_ordered').toArray();
            // for( let ip= 0; ip < saisiesPageFormulaire.length; ip++ ){
            //
            //     let saisiesSuperCalqueFormulaire = saisiesPageFormulaire[ip].get('saisies_super_calque_formulaire_ordered').toArray();
            //     for( let isp= 0; isp < saisiesSuperCalqueFormulaire.length; isp++ ){
            //
            //         let saisiesObjetFormulaire = saisiesSuperCalqueFormulaire[isp].get('saisies_objet_formulaire_ordered').toArray();
            //         for( let io= 0; io < saisiesObjetFormulaire.length; io++ ){
            //
            //             let saisieObjetFormulaire = saisiesObjetFormulaire[io];
            //
            //             saisieObjetFormulaire.validate();
            //             if( ! saisieObjetFormulaire.get('validations.isValid')){
            //
            //                 return {
            //                     page : ip,
            //                     super_calque : isp,
            //                 };
            //             }
            //         }
            //     }
            // }

            return null;
        }

    });
});