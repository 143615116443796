define('ceag-lot3-front/adapters/offline/saisie-formulaire', ['exports', 'ceag-lot3-front/adapters/offline/application', 'moment'], function (exports, _application, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        namespace: 'saisie_formulaire',

        /**
         * On ne sauvegarde en offline que les saisies non terminée ou agée de moins d'1 mois
         */
        saveOfflineCondition: function (model) {
            let date = Ember.isEmpty(model.get('date_mise_a_jour')) ? model.get('date_creation') : model.get('date_mise_a_jour');
            let age = (0, _moment.default)().diff(date, 'days');
            return model.get('etat_saisie') != 'done' || age < 32;
        },

        /**
         * Surcharge pour gérer les cas particulier des filtres de recherche offline
         * @author jmestres
         */
        modelPropertyMatchQueryValue(record, queryProperty, queryValue) {

            if (queryProperty == 'updated_from') {
                return new Date(record['date_mise_a_jour']).getTime() >= queryValue * 1000;
            } else if (queryProperty == 'updated_to') {
                return new Date(record['date_mise_a_jour']).getTime() <= queryValue * 1000;
            } else if (queryProperty == 'created_from') {
                return new Date(record['date_creation']).getTime() >= queryValue * 1000;
            } else if (queryProperty == 'created_to') {
                return new Date(record['date_creation']).getTime() <= queryValue * 1000;
            } else if (queryProperty == 'intervention_from') {
                return new Date(record['date_intervention']).getTime() >= queryValue * 1000;
            } else if (queryProperty == 'intervention_to') {
                return new Date(record['date_intervention']).getTime() <= queryValue * 1000;
            }

            return this._super(record, queryProperty, queryValue);
        }
    });
});