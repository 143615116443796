define('ceag-lot3-front/controllers/application', ['exports', 'ceag-lot3-front/misc/promise-array'], function (exports, _promiseArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        navbarOpen: false,
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        offlineStore: Ember.inject.service('offline-store'),

        signatureService: Ember.inject.service('signature'),

        offlineTaskManager: Ember.inject.service('task-manager'),
        syncPending: Ember.computed.alias('offlineTaskManager.syncPending'),

        isLoading: false,
        loaderColors: ['#7aa4dc', '#19428a', '#a0d785', '#ce112d', '#f98e2c', '#f5b335', '#ffce00'],

        actions: {

            toggleNavBar: function () {
                this.toggleProperty('navbarOpen');
            },

            alertSyncError: async function () {

                let syncErrors = await this.get('offlineStore').findAll('sync-task-error');
                let syncErrorsSaisie = await this.get('offlineStore').query('sync-task-error', { modelName: "saisie-formulaire" });
                let saisieCount = syncErrorsSaisie.get('length');

                let message = Ember.String.htmlSafe(`Il y'a ${saisieCount} saisie(s) en erreur.<br><br>Ces saisies n'ont pas été envoyées, veuillez <b>vérifier vos droits et vos compteurs</b> et lancer de nouveau la sychronisation.`);

                return this.get('confirmationService').confirm("Erreur(s) de synchronisation détectée(s)", message, {
                    okLabel: "Synchroniser de nouveau",
                    okType: 'success',
                    cancelLabel: 'Annuler'
                })
                //Oui
                .then(async () => {
                    try {
                        await (0, _promiseArray.default)(syncErrors, async taskError => {
                            await this.get('offlineTaskManager').runErrorTask(taskError);
                        });

                        this.get('confirmationService').alert("Synchronisation terminée", "Vos saisies ont été synchronisées avec succès.", { typeAlert: 'success' });
                    } catch (e) {
                        this.get('confirmationService').alert("Echec de la synchronisation", Ember.String.htmlSafe("Une erreur a été détectée pendant la synchronisation.<br><br>Vous pouvez essayer de nouveau ultérieurement.<br><br><small>Si le problème persiste, veuillez contacter DMForms.</small>"), { typeAlert: 'danger' });
                    }
                });
            }

        },

        //Ferme le menu (si ouvert) en cas de click sur le document
        handleDocumentClick: function () /*event*/{
            this.set('navbarOpen', false);
        },

        init: function () {
            //Click sur le document pour fermeture du menu.
            Ember.$(document).on('click', this.handleDocumentClick.bind(this));
            return this._super(...arguments);
        }

    });
});