define('ceag-lot3-front/routes/in/formulaires', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            return Ember.RSVP.hash({
                abonnements: this.modelFor('in').abonnements
            });
        }
    });
});