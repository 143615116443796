define('ceag-lot3-front/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ceag-lot3-front/config/environment', 'ceag-lot3-front/models/saisie-formulaire'], function (exports, _applicationRouteMixin, _environment, _saisieFormulaire) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

        session: Ember.inject.service('session'),
        splashScreenService: Ember.inject.service('ember-cordova/splash'),
        connectionStatus: Ember.inject.service('connection-status'),
        moment: Ember.inject.service(),
        intl: Ember.inject.service(),
        platform: Ember.inject.service('ember-cordova/platform'),
        offlineTaskManager: Ember.inject.service('task-manager'),
        fileCache: Ember.inject.service('file-cache'),
        fingerprint: Ember.inject.service(),

        init() {
            this._super(...arguments);
            const offlineTaskManager = this.get("offlineTaskManager");

            //On veux être notifié des actions de fin de synchro et à chaque fin de tâche (génération des aperçu à la syncho)
            offlineTaskManager.on("offlineSyncTaskDone", this._onOfflineSyncTaskDone.bind(this));
            offlineTaskManager.on("offlineSyncStopped", this._onOfflineSyncStopped.bind(this));
        },

        /**
         * On s'assure que l'UUID du device est prêt avant d'entrer dans l'application
         * @return {Promise} PRomise résolue lorsqque l'UUID est prêt
         */
        model() {
            return this.get('fingerprint.fingerprintPromise');
        },

        afterModel() {
            this.get('splashScreenService').hide();
        },

        title: function () {

            let profil = "";
            if (this.get('session.isAuthenticated')) {

                if (this.get('session.data.authenticated.is_particulier')) {
                    profil = this.get('intl').t('titre.application.par');
                } else {
                    let user = this.get('intl').t(this.get('session.data.authenticated.is_admin') ? 'titre.application.admin' : 'titre.application.user');
                    profil = this.get('intl').t('titre.application.pro', { user });
                }
            }
            return this.get('intl').t('titre.application.base', { profil });
        },

        beforeModel(transition) {

            //On définit la locale
            this.get('moment').setLocale('fr');
            this.get('intl').setLocale('fr');

            //Ici gestion "manuelle" de la redirection en cas d'utilisateur non loggé
            // => car il existe 2 route qui peuvent être appelées direct en étant non loggé (login et reset)
            if (this.get('session.isAuthenticated') === false && !(transition.targetName == 'reset' || transition.targetName == 'login')) {
                return this.transitionTo('login');
            }

            if (_environment.default.environment !== 'development' && this.get('session.isAuthenticated') && transition.targetName == 'index') {
                return this.transitionTo('in.index');
            }
        },

        /**
         * Tableau de saisie formulaire "fraîchement" synchronisée pour génération des aperçus
         * @type {Array<saisie_formulaire>}
         */
        saisiesFormulaireSync: Ember.A(),

        _onOfflineSyncTaskDone: function (record) {

            //On sauvegarde notre saisie dans le tableau
            if (record instanceof _saisieFormulaire.default) {
                this.get('saisiesFormulaireSync').addObject(record);
                // on màj l'aperçu
                this.get('fileCache').processSaisieFormulairePreview(record);
            }
        },

        _onOfflineSyncStopped: function () {

            if (this.get('connectionStatus.isOnline')) {

                let saisiesFormulaireSync = this.get('saisiesFormulaireSync');
                let saisieFormulaire = null;

                //Pour chaque saisie, on va génrer l'aperçu
                while (!Ember.isEmpty(saisieFormulaire = saisiesFormulaireSync.shiftObject())) {

                    if (!saisieFormulaire.get('formulaire.form_fictif')) {
                        this.get('fileCache').processSaisieFormulairePreview(saisieFormulaire);
                    }
                }
            }
        },

        actions: {

            invalidateSession: function () {

                //On supprime les credentials stockées si besoin avant déconnexion
                let removeCredential = Ember.RSVP.resolve();
                if (this.get('platform.isCordova')) {
                    removeCredential = this.get('offlineInfosService').removeCredential();
                }

                removeCredential.finally(() => {
                    this.get('session').invalidate();
                });
            },

            //A chaque changement de route on "reset" le message d'erreur global
            //TODO: est-ce vraiment la bonne façon d'implémenter ça ?
            willTransition: function (transition) {
                this.get('messageService').reset();

                let controller = this.controllerFor(this.routeName);
                controller.set('isLoading', true);
                // alert('go');
                transition.finally(function () {
                    controller.set('isLoading', false);
                    // alert('end');
                });
            },

            error: function (error, transition) {
                let intl = this.get('intl');

                this.get('confirmationService').confirm(intl.t('misc.error.titre'), intl.t('misc.error.contenu'), {
                    okLabel: intl.t('misc.error.ok'),
                    cancelLabel: intl.t('misc.error.cancel'),

                    okType: 'primary',
                    cancelType: 'warning',

                    typeAlert: 'danger'
                }).then(() => {
                    this.transitionTo('in.index');
                }, () => {
                    transition.retry();
                });
            }

        }
    });
});