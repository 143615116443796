define('ceag-lot3-front/controllers/in/abonnement', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function tableSortToComputed(sort) {
        if (sort && sort.charAt(0) != '-') {
            return [sort + ':asc'];
        } else if (sort) {
            return [sort.substring(1) + ':desc'];
        }

        return ['id:asc'];
    }

    exports.default = Ember.Controller.extend({

        queryParams: ['sortForm', 'sortProc', 'sortCompteur', 'sortOption'],

        sortForm: '',
        sortFormDefinition: Ember.computed('sortForm', function () {
            return tableSortToComputed(this.get('sortForm'));
        }),
        sortedAbonnemnet: Ember.computed.sort('model.abonnements', 'sortFormDefinition'),

        sortProc: '',
        sortProcDefinition: Ember.computed('sortProc', function () {
            return tableSortToComputed(this.get('sortProc'));
        }),
        sortedProc: Ember.computed.sort('model.packs', 'sortProcDefinition'),

        sortCompteur: '',
        sortCompteurDefinition: Ember.computed('sortCompteur', function () {
            return tableSortToComputed(this.get('sortCompteur'));
        }),
        sortedCompteur: Ember.computed.sort('model.compteurs', 'sortCompteurDefinition'),

        sortOption: '',
        sortOptionDefinition: Ember.computed('sortOption', function () {
            return tableSortToComputed(this.get('sortOption'));
        }),
        sortedOption: Ember.computed.sort('model.options', 'sortOptionDefinition'),

        session: Ember.inject.service(),
        platform: Ember.inject.service('ember-cordova/platform'),

        isIOS: Ember.computed.and('platform.isIOS', 'platform.isCordova'),

        urlECommerce: _environment.default.CEAG.urlECommerce,

        //Les options sont valides tant qu'un abonnement est valide (donc date max abo)
        echeancesAbonnement: Ember.computed.mapBy('model.abonnements', 'to'),
        dateValiditeOption: Ember.computed.max('echeancesAbonnement')
    });
});