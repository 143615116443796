define('ceag-lot3-front/models/calque-formulaire', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        code: _emberData.default.attr('string'),

        is_coo_contractant: _emberData.default.attr('boolean'),
        libelle: _emberData.default.attr('string'),

        date_creation: _emberData.default.attr('date'),
        date_mise_a_jour: _emberData.default.attr('date'),

        objets: _emberData.default.hasMany('objet-formulaire'),
        super_calque: _emberData.default.belongsTo('super-calque-formulaire')
    });
});