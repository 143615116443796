define('ceag-lot3-front/transitions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {

        /**
         * Gestion du menu principal (monté ou déscente en fonction de la position du menu)
         */
        this.transition(this.toRoute('in.index'), this.fromRoute(['in.abonnement', 'in.utilisateurs', 'in.formulaires', 'in.archives', 'in.statistiques', 'in.parametrage']), this.use('toDown'), this.reverse('toUp'));

        this.transition(this.toRoute('in.abonnement'), this.fromRoute(['in.utilisateurs', 'in.formulaires', 'in.archives', 'in.statistiques', 'in.parametrage']), this.use('toDown'), this.reverse('toUp'));
        this.transition(this.toRoute('in.utilisateurs'), this.fromRoute(['in.formulaires', 'in.archives', 'in.statistiques', 'in.parametrage']), this.use('toDown'), this.reverse('toUp'));
        this.transition(this.toRoute('in.formulaires'), this.fromRoute(['in.archives', 'in.statistiques', 'in.parametrage']), this.use('toDown'), this.reverse('toUp'));
        this.transition(this.toRoute('in.archives'), this.fromRoute(['in.statistiques', 'in.parametrage']), this.use('toDown'), this.reverse('toUp'));
        this.transition(this.toRoute('in.statistiques'), this.fromRoute(['in.parametrage']), this.use('toDown'), this.reverse('toUp'));

        /**
         * Détails ou saisies (rechercher) => Simple Fade
         */
        this.transition(this.toRoute(['in.formulaire', 'in.saisies']), this.use('fade'), this.reverse('fade'));

        /**
         * Navigation dans saisie
         */

        //info : Les transition entre page et super_calques ainsi qu'entre signature co-cContractant sont géré dans les template/controller

        this.transition(this.fromRoute('in.formulaire.saisie.index'), this.toRoute('in.formulaire.saisie.signature'), this.use('toLeft'), this.reverse('toRight'));

        this.transition(this.fromRoute('in.formulaire.saisie.signature'), this.toRoute('in.formulaire.saisie.envoi'), this.use('toLeft'), this.reverse('toRight'));
        this.transition(this.fromRoute('in.formulaire.saisie.envoi'), this.toRoute('in.formulaire.saisie.preview'), this.use('toLeft'));
    };
});