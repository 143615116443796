define('ceag-lot3-front/models/formulaire', ['exports', 'ember-data', 'ceag-lot3-front/mixins/computed-last-version', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _computedLastVersion, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend(_computedLastVersion.default, {
        code: _emberData.default.attr('string'),
        libelle: _emberData.default.attr('string'),
        orientation: _emberData.default.attr('string'),

        form_fictif: _emberData.default.attr('boolean'),

        abonnements: _emberData.default.hasMany('abonnement'),
        aboLibelle: Ember.computed.alias('abonnements.firstObject.libelle'),

        versions: _emberData.default.hasMany('formulaire-version'),

        etapes: _emberData.default.hasMany('etape-processus'),

        /* Si lié à une étapes de processus, autoRemplissage:1 */
        autoRemplissage: Ember.computed.gt('etapes.length', 0),
        /* FIXME : Correcte ? Si un formlaire est lié à plusieurs processus libre ? */
        autoRemplissageProcessusId: Ember.computed.alias('etapes.firstObject.version_processus.processus.id')
    });
});