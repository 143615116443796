define("ceag-lot3-front/transforms/ceag-object-props", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({

        serialize: function (value) {
            return JSON.stringify(value);
        },

        deserialize: function (value) {
            try {
                return JSON.parse(typeof value == "string" ? value : "{}");
            } catch (e) {
                return JSON.parse("{}");
            }
        }
    });
});