define('ceag-lot3-front/mixins/computed-etat-saisie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        etat: Ember.computed('etat_saisie', 'offlineSyncStatus', function () {
            let etat_saisie = this.get('etat_saisie');

            if (etat_saisie == 'new' || etat_saisie == 'pending') {
                return etat_saisie;
            }

            let etat_sync = this.get('offlineSyncStatus');

            if (etat_saisie == 'done' && etat_sync == 'sync') {
                return 'sync';
            } else if (etat_saisie == 'done' && (etat_sync == 'offline' || etat_sync == 'pending')) {
                return 'valid';
            }

            // return 'unknow';
            return 'sync';
        }),

        isSync: Ember.computed('offlineSyncStatus', function () {
            let etat_sync = this.get('offlineSyncStatus');
            return etat_sync !== 'offline' && etat_sync !== 'pending';
        })
    });
});