define('ceag-lot3-front/controllers/in/utilisateurs/edit', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_pendingObject.default, {

        saveError: '',

        actions: {

            saveModel: function () {

                let wasNew = this.get('model.utilisateur.isNew');

                this.goPending(this.get('model.utilisateur').save().then(() => {

                    this.transitionToRoute('in.utilisateurs').then(() => {
                        this.get('messageService').success('Utilisateur ' + (wasNew ? 'créé' : 'modifié') + ' avec succès.');
                    });
                }).catch(error => {

                    console.error('Erreur de sauvegarde du model user', error);
                    if (error.errors && error.errors.mail) {
                        this.get('messageService').error('Cette adresse email est déjà utilisée pour un autre compte DMFORMS.');
                    } else if (error.errors && error.errors.trigramme) {
                        this.get('messageService').error('Ce trigramme est utilisé pour un autre utilisateur.');
                    } else {
                        this.get('messageService').error('Erreur de sauvegarde.');
                    }
                }));
            },

            resetModel: function () {
                this.get('model.utilisateur').rollbackAttributes();
                this.get('model.utilisateur').set('abonnements_pro', this.get('model.utilisateurDroits'));
            },

            /**
             * Ajout ou supprime un abonnement de la liste des abonnements pour l'utilisateur
             * @param  {abonnement} abo L'abonnement
             */
            toggleAbonnement: function (abo) {

                if (this.get('model.utilisateur.isAdmin')) {
                    this.get('confirmationService').alert('Non autorié', "Les droits de l'administrateur (compte principal) ne peuvent pas être modifiés.");
                } else {

                    let user_abo_pro = this.get('model.utilisateur.abonnements_pro');

                    if (user_abo_pro.findBy('id', abo.get('id'))) {
                        user_abo_pro.removeObject(abo);
                    } else {
                        user_abo_pro.addObject(abo);
                    }
                }
            }

        }
    });
});