define('ceag-lot3-front/models/utilisateur-prestashop-informations', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        prestashop_id: _emberData.default.attr('number'),

        societe: _emberData.default.attr('string'),
        statut_juridique: _emberData.default.attr('string'),

        civilite: _emberData.default.attr('string'),
        nom: _emberData.default.attr('string'),
        prenom: _emberData.default.attr('string'),

        pro_ape: _emberData.default.attr('string'),
        pro_tva: _emberData.default.attr('string'),
        siret: _emberData.default.attr('string'),

        mail: _emberData.default.attr('string'),

        pro_phone_mobile: _emberData.default.attr('string'),
        pro_fax: _emberData.default.attr('string'),
        pro_phone: _emberData.default.attr('string'),

        numero_appartement: _emberData.default.attr('string'),
        batiment: _emberData.default.attr('string'),
        adresse: _emberData.default.attr('string'),
        boite_postale: _emberData.default.attr('string'),
        code_postal: _emberData.default.attr('string'),
        localite: _emberData.default.attr('string'),

        logo: _emberData.default.attr('string'),

        utilisateur: _emberData.default.hasMany('utilisateur')
    });
});