define('ceag-lot3-front/models/inflector/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inflector = _emberInflector.default.inflector;

  //Sinon, lors du "déserialize", Ember va chercher un modelName "etape-processu"
  inflector.uncountable('etape-processus');
  // inflector.uncountable('processus');


  //Pluriel custom pour les "saisies-xxxxxx"
  inflector.irregular('saisie-processus', 'saisies-processus');
  inflector.irregular('saisie-formulaire', 'saisies-formulaire');
  inflector.irregular('saisie-super-calque-formulaire', 'saisies-super-calque-formulaire');
  inflector.irregular('saisie-page-formulaire', 'saisies-page-formulaire');
  inflector.irregular('saisie-objet-formulaire', 'saisies-objet-formulaire');
  inflector.irregular('saisie-co-contractant', 'saisies-co-contractant');

  inflector.irregular('reedition-saisie', 'reeditions-saisie');

  exports.default = {};
});