define('ceag-lot3-front/models/sous-categorie', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({
        code: _emberData.default.attr('string'),
        libelle: _emberData.default.attr('string'),

        categories: _emberData.default.belongsTo('categorie'),

        rubriques: _emberData.default.hasMany('rubrique')
    });
});