define('ceag-lot3-front/components/ui/bs-button-pending', ['exports', 'ceag-lot3-front/mixins/pending-object'], function (exports, _pendingObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_pendingObject.default, {

        tagName: 'span',
        pendingIcon: 'ellipsis-h',
        pendingAnimation: 'slideInUp',

        actions: {
            internalOnClick: function () {
                this.goPending(this.get('onClick')());
            }
        }
    });
});