define('ceag-lot3-front/initializers/ember-parachute', ['exports', 'ember-parachute/initializers/ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberParachute.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberParachute.initialize;
    }
  });
});