define('ceag-lot3-front/routes/in/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

        session: Ember.inject.service(),

        beforeModel(transition) {

            //Si particulier, redirection vers abonnement ("mes droits")
            if (this.get('session.data.authenticated.is_particulier') && transition.targetName == 'in.index') {
                return this.transitionTo('in.abonnement');
            }
        }
    });
});