define('ceag-lot3-front/mixins/computed-last-version', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        //Pour avoir les version de formulaire triées
        versionSortin: ['version:asc'],
        versions_ordered: Ember.computed.sort('versions', 'versionSortin'),

        //La dernière version
        last_version: Ember.computed.alias('versions_ordered.lastObject')
    });
});