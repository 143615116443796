define('ceag-lot3-front/controllers/in/formulaire/saisie/envoi', ['exports', 'ceag-lot3-front/misc/promise-array'], function (exports, _promiseArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        saisie: Ember.inject.service(),
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        intl: Ember.inject.service(),

        connectionStatus: Ember.inject.service('connection-status'),
        isOnline: Ember.computed.alias('connectionStatus.isOnline'),

        confirmValidateSaisie: async function (jetonInfos) {

            let intl = this.get('intl');

            //On sauvegarde les saisieCoContractant
            let saisiesCoContractant = this.get('model.saisieFormulaire.saisies_co_contractant');
            await (0, _promiseArray.default)(saisiesCoContractant, async saisieCoContractant => {
                await saisieCoContractant.save();
            });

            //On finalise la saisie (+ objet en remplissage auto, + saisie processus, etc...)
            let saisieFormulaire = this.get('model.saisieFormulaire');
            try {
                await this.get('saisie').finaliseSaisieFormulaire(saisieFormulaire);

                //Mise à jour des compteur (et abonnement) après envoi de la saisie
                if (this.get('isOnline')) {

                    await this.get('store').findAll('compteur', { reload: true });
                    if (this.get('session.data.authenticated.is_particulier')) {
                        await this.get('store').findAll('abonnement', { reload: true });
                    }
                }
                //On décrémente temporairement en offline.
                else {
                        await this.decrementeOfflineCompteurs(jetonInfos);
                    }

                //On alert sur le nombre de jetons restant
                let resumeCompteurs = this.getResumeCompteurs();

                let message = intl.t('saisie.envoi.compteur_resum.base');
                if (this.get('session.data.authenticated.is_particulier')) {
                    message += intl.t('saisie.envoi.compteur_resum.conso_par', { num: resumeCompteurs.jetonsEdition });
                } else {
                    message += intl.t('saisie.envoi.compteur_resum.conso_pro', { num: resumeCompteurs.jetonsEmail });
                }

                message += intl.t('saisie.envoi.compteur_resum.conso_mod', { num: resumeCompteurs.jetonsMOD });
                message += intl.t('saisie.envoi.compteur_resum.conso_jeton', { num: resumeCompteurs.jetonsPhoto });

                return this.get('confirmationService').alert(intl.t('saisie.envoi.compteur_resum.titre'), Ember.String.htmlSafe(message)).then(() => {
                    this.transitionToRoute('in.formulaire.saisie.preview');
                });
            } catch (e) {

                let msg = intl.t('saisie.envoi.erreur.contenu_base');

                //Une demande de géo-loc pour un champ auto à échouée, on ne valide pas la saisie !
                if (e == 'geoloc_hs_obligatoire') {
                    msg = Ember.String.htmlSafe(intl.t('saisie.envoi.erreur.contenu_comp_geoloc'));
                }
                if (!Ember.isEmpty(e.errors) && !Ember.isEmpty(e.errors['droits_insuffisant'])) {
                    msg = Ember.String.htmlSafe(intl.t('saisie.envoi.erreur.contenu_comp_droit', { erreur: e.errors.droits_insuffisant }));
                }
                this.get('confirmationService').alert(intl.t('saisie.envoi.erreur.titre'), msg, { typeAlert: 'danger' });
                console.error(e);
            }
        },

        checkCompteur: function (nbToTest, compteurId) {

            if (nbToTest) {
                let compteur = this.get('store').peekRecord('compteur', compteurId);
                let restant = compteur.get('restant');
                if (nbToTest > restant) {

                    let titre = this.get('intl').t('saisie.envoi.compteur_alert.titre', { libelle: compteur.get('libelle') });
                    let contenu = Ember.String.htmlSafe(this.get('intl').t('saisie.envoi.compteur_alert.contenu', { libelle: compteur.get('libelle'), restant, num: nbToTest }));

                    return this.get('confirmationService').alert(titre, contenu);
                }
            }

            return true;
        },

        getAboParticulier: function () {
            let refAbo = '';
            //On décompe l'abonnement lié au formulaire
            if (Ember.isEmpty(this.get('model.saisieFormulaire.saisie_processus.id'))) {
                refAbo = this.get('model.saisieFormulaire.formulaire.code');
            }
            //Ou au processus
            else {
                    refAbo = this.get('model.saisieFormulaire.saisie_processus.processus.code');
                }

            return this.get('store').peekRecord('abonnement', refAbo);
        },

        decrementeOfflineCompteurs: async function (jetonInfos) {

            if (this.get('session.data.authenticated.is_particulier')) {

                //L'abonnement
                if (jetonInfos.decompteEdition) {
                    let abonnement = this.getAboParticulier();
                    abonnement.set('nb_jeton', abonnement.get('nb_jeton') - 1);
                    await abonnement.save();
                }
            } else if (jetonInfos.compteurJetonNonMOD) {
                let compteur = this.get('store').peekRecord('compteur', 'jeton');
                compteur.set('use', compteur.get('use') + jetonInfos.compteurJetonNonMOD);
                compteur.set('restant', compteur.get('restant') - jetonInfos.compteurJetonNonMOD);
                await compteur.save();
            }

            if (jetonInfos.compteurJetonMOD) {
                let compteur = this.get('store').peekRecord('compteur', 'envoi');
                compteur.set('use', compteur.get('use') + jetonInfos.compteurJetonMOD);
                compteur.set('restant', compteur.get('restant') - jetonInfos.compteurJetonMOD);
                await compteur.save();
            }

            if (jetonInfos.compteurJetonPhoto) {
                let compteur = this.get('store').peekRecord('compteur', 'photo');
                compteur.set('use', compteur.get('use') + jetonInfos.compteurJetonPhoto);
                compteur.set('restant', compteur.get('restant') - jetonInfos.compteurJetonPhoto);
                await compteur.save();
            }
        },

        getResumeCompteurs: function () {

            let resume = {
                jetonsEmail: 0, //Pro
                jetonsEdition: 0, //Par
                jetonsMOD: 0,
                jetonsPhoto: 0
            };

            if (this.get('session.data.authenticated.is_particulier')) {

                //L'abonnement
                let abonnement = this.getAboParticulier();
                resume.jetonsEdition = abonnement.get('nb_jeton');
            } else {
                let compteur = this.get('store').peekRecord('compteur', 'jeton');
                resume.jetonsEmail = compteur.get('restant');
            }

            let compteur = this.get('store').peekRecord('compteur', 'envoi');
            resume.jetonsMOD = compteur.get('restant');

            compteur = this.get('store').peekRecord('compteur', 'photo');
            resume.jetonsPhoto = compteur.get('restant');

            return resume;
        },

        checkCompteurAndAlert: function () {
            let compteurJetonNonMOD = 0;
            let compteurJetonMOD = 0;

            //On controle le(s) mode(s) d'envoi
            let saisiesCoContractant = this.get('model.saisieFormulaire.saisies_co_contractant');
            let flagNonMod = false;

            // Le compteur d'envoi MOD se fait par destinataire
            // le compteur d'envoi non MOD est 0 si aucun destinataire, 1 si 1 ou plus destinaires
            saisiesCoContractant.forEach(saisieCoContractant => {
                if (saisieCoContractant.get('envoi_m_o_d')) {
                    compteurJetonMOD++;
                }
                if (saisieCoContractant.get('envoi_non_m_o_d')) {
                    flagNonMod = true;
                }
                // if (saisieCoContractant.get('envoi_one_non_m_o_d'))  { flagNonMod = true; }
            });

            // Le compteur d'envoi non MOD n'est décrémenté que de 1 à partir du moment ou au moins un destinatair est trouvé
            if (flagNonMod) {
                compteurJetonNonMOD++;
            }

            let ret = this.checkCompteur(compteurJetonMOD, 'envoi');
            //Pour les particulier on s'arrête au compteur Non MOD
            if (ret !== true || this.get('session.data.authenticated.is_particulier')) {
                return ret;
            }

            ret = this.checkCompteur(compteurJetonNonMOD, 'jeton');
            if (ret !== true) {
                return ret;
            }

            return true;
        },

        actions: {

            checkCompteurAndAlert: async function (saisieCoContractant = null, isMOD = false) {
                var ret = this.checkCompteurAndAlert();

                if (ret !== true) {
                    saisieCoContractant.set(isMOD ? 'envoi_m_o_d' : 'envoi_non_m_o_d', false);

                    //Si erreur de validation, ret = promise retourné par confirmationService et résolut au click sur OK
                    await ret;
                    //Donc on force le retour d'un promise rejetée pour ne pas aller plus loin
                    return Ember.RSVP.reject();
                }

                return ret;
            },

            checkDataAndAlert: function (saisieCoContractant = null, isMOD = false) {

                let ok = false;
                let type_envoi = '';

                if (!isMOD) {
                    ok = !Ember.isEmpty(saisieCoContractant.get('mail'));
                } else {
                    ok = !Ember.isEmpty(saisieCoContractant.get('adresse1')) && !Ember.isEmpty(saisieCoContractant.get('ville')) && !Ember.isEmpty(saisieCoContractant.get('code_postal'));
                }

                // Aucun mail client trouvé
                if (ok !== true) {
                    type_envoi = 'envoi_non_m_o_d';

                    saisieCoContractant.set(isMOD ? 'envoi_m_o_d' : type_envoi, false);

                    let titre = this.get('intl').t('saisie.envoi.' + (isMOD ? 'envoi_m_o_d' : type_envoi) + '.titre');
                    let contenu = Ember.String.htmlSafe(this.get('intl').t('saisie.envoi.' + (isMOD ? 'envoi_m_o_d' : type_envoi) + '.contenu'));

                    return this.get('confirmationService').alert(titre, contenu);
                }
            },

            // Validation du formulaire de saisie.
            validateSaisieFormulaire: function () {

                let intl = this.get('intl');

                let isParticulier = this.get('session.data.authenticated.is_particulier');

                let compteurJetonNonMOD = 0;
                let compteurJetonMOD = 0;

                // Abo expire ?
                let isPack = !Ember.isEmpty(this.get('model.saisieFormulaire.saisie_processus.id'));
                if (!isPack && !this.get('model.saisieFormulaire.formulaire').get('abonnements').filterBy('hasExpire', false).length) {
                    return this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
                } else if (isPack && !this.get('model.saisieFormulaire.saisie_processus.processus').get('abonnements').filterBy('hasExpire', false).length) {
                    return this.get('confirmationService').alert(intl.t('saisie.abo_exp.titre'), intl.t('saisie.abo_exp.contenu'));
                }

                // On controle le(s) mode(s) d'envoi
                let allOK = true,
                    messageListeErreur = '';
                let saisiesCoContractant = this.get('model.saisieFormulaire.saisies_co_contractant');
                let flagNonMod = false; // Si 1 ou pluiseurs destinataires non MOD le décompte d'envoi non MOD n'est que de 1 (sinon 0)

                // Traitement des cocontractants
                saisiesCoContractant.forEach(saisieCoContractant => {

                    if (saisieCoContractant.get('envoi_m_o_d')) {
                        compteurJetonMOD++;
                    }
                    if (saisieCoContractant.get('envoi_non_m_o_d')) {
                        flagNonMod = true;
                    }

                    // Si pour ce co contractant aucun moyen d'envoi n'est sélectionné
                    // Et que le co-contractant dispose d'une raison social (définie et non nulle)
                    // alors afficher un message d'erreur

                    if (!saisieCoContractant.get('envoi_m_o_d') // absence d'envoi par courrier
                    && !(typeof saisieCoContractant.get('nom') === "undefined") // absence de raison sociale définie
                    && !(saisieCoContractant.get('nom') === null) // absence de raison sociale nulle
                    && !(saisieCoContractant.get('nom').trim() === '') && !saisieCoContractant.get('envoi_non_m_o_d')) {
                        // absence d'envoi par mail)
                        // absence de raison sociale non renseignée

                        allOK = false;

                        // On affiche le nom de la personne qui génère l'erreur                 
                        messageListeErreur += `<li>${saisieCoContractant.get('nom')}`;
                    }
                });

                // application du décompte d'envoi non MOD
                if (flagNonMod) {
                    compteurJetonNonMOD++;
                }

                //On calcule le nombre de jeton photo de la saisie
                let compteurJetonPhoto = this.get('saisie').calculeJetonPhotoSaisieFormulaire(this.get('model.saisieFormulaire'));

                //On check les compteur connu à l'instant
                let ret = this.checkCompteur(compteurJetonPhoto, 'photo');
                if (ret !== true) {
                    return ret;
                }

                //On check les compteur connu à l'instant
                ret = this.checkCompteurAndAlert();
                if (ret !== true) {
                    return ret;
                }

                let decompteEdition = isParticulier && (Ember.isEmpty(this.get('model.saisieFormulaire.saisie_processus')) || this.get('model.saisieFormulaire') == this.get('model.saisieFormulaire.saisie_processus.saisies_formulaire_ordered.firstObject'));

                let confirmation_titre = '';
                let confirmation_contenu = '';

                // Si tout n'est pas OK alors quitter avec message d'erreur
                if (!allOK) {

                    confirmation_titre = 'saisie.envoi.manque_mode.titre';
                    confirmation_contenu = 'saisie.envoi.manque_mode.contenu';
                } else {

                    confirmation_titre = 'saisie.envoi.validation.titre';
                    confirmation_contenu = 'saisie.envoi.validation.contenu';
                }

                return this.get('confirmationService').confirm(intl.t(confirmation_titre), Ember.String.htmlSafe(intl.t(confirmation_contenu, { message: messageListeErreur })), {
                    okLabel: intl.t('saisie.envoi.validation.ok'),
                    okType: 'success',
                    cancelLabel: intl.t('saisie.envoi.validation.cancel')
                })
                //Oui
                .then(() => {
                    let jetonInfos = {
                        compteurJetonNonMOD,
                        decompteEdition,
                        compteurJetonMOD,
                        compteurJetonPhoto
                    };

                    return this.confirmValidateSaisie(jetonInfos);
                });
            }
        }
    });
});