define('ceag-lot3-front/routes/index', ['exports', 'ceag-lot3-front/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),
        platform: Ember.inject.service('ember-cordova/platform'),
        connectionStatus: Ember.inject.service('connection-status'),

        beforeModel() {

            //Si mobile et non connecté et online et que des infos de connexion existe, on essais un login auto
            if (!this.get('session.isAuthenticated') && this.get('platform.isCordova') && this.get('connectionStatus.isOnline')) {

                return this.get('offlineInfosService').getCredential().then(credentials => {

                    if (!Ember.isEmpty(credentials.identification) && !Ember.isEmpty(credentials.pasword)) {

                        return this.get('session').authenticate('authenticator:myjwt', credentials).then(() => {

                            this.transitionTo('in.index');
                        }, () => {
                            this.transitionTo('login');
                        });
                    } else {
                        this.transitionTo('login');
                    }
                });
            }

            if (this.get('session.isAuthenticated') === false) {
                return this.transitionTo('login');
            }

            if (_environment.default.environment !== 'development' && this.get('session.isAuthenticated')) {
                return this.transitionTo('in.index');
            }
        }

    });
});