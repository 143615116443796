define('ceag-lot3-front/serializers/offline/saisie-formulaire', ['exports', 'ceag-lot3-front/serializers/offline/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        // Pour Rappel default :
        //  BelongsTo: { serialize: 'id', deserialize: 'id' }
        //  HasMany: { serialize: false, deserialize: 'ids' }
        attrs: {
            formulaire: { serialize: 'id', deserialize: 'records' },
            formulaire_version: { serialize: 'id', deserialize: 'records' },

            saisies_page_formulaire: { serialize: false, deserialize: 'records' },
            saisies_co_contractant: { serialize: false, deserialize: 'records' },

            saisie_processus: { serialize: 'id', deserialize: 'records' },

            etape_processus: { serialize: 'id', deserialize: 'records' },

            // date_mise_a_jour: {
            //     serialize: false,
            //     deserialize: 'records'
            // },
            // date_creation: {
            //     serialize: false,
            //     deserialize: 'records'
            // },
            complete: {
                serialize: false,
                deserialize: 'records'
            },
            createur: {
                serialize: false,
                deserialize: 'records'
            }
        }
    });
});