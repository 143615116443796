define('ceag-lot3-front/services/confirmation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        confirmationPopupOpen: false,
        title: '',
        content: '',
        type: null,

        OKPending: false,
        cancelPending: false,

        options: null,

        //Options par défaut
        defaultOptions: {
            okLabel: 'OK',
            cancelLabel: 'Annuler',

            okType: 'primary',
            cancelType: 'default',

            typeAlert: null,

            autoClose: true
        },

        //
        deferred: null,
        promise: Ember.computed.alias('deferred.promise'),

        /**
         * Mode confirm (Boutons OK/annuler) ou alert (Boutton OK)
         * @type {Boolean}
         */
        isConfirm: true,

        confirm: function (title, content, options = null) {

            //On merge les options par défaut
            let _options = Object.assign({}, this.get('defaultOptions'), options);
            this.set('options', _options);

            this.set('title', title);
            this.set('content', content);

            this.set('isConfirm', true);

            this.set('confirmationPopupOpen', true);
            this.set('OKPending', false);
            this.set('cancelPending', false);

            //Création d'une promise qui sera resolved/rejected selon le retour de l'utilisateur
            let deferred = Ember.RSVP.defer();
            deferred.promise.finally(() => {

                if (this.get('options.autoClose')) {
                    this.set('confirmationPopupOpen', false);
                }
            });
            this.set('deferred', deferred);
            return deferred.promise;
        },

        alert: function (title, content, options = null) {

            //On merge les options par défaut
            let _options = Object.assign({}, this.get('defaultOptions'), options);
            this.set('options', _options);

            this.set('title', title);
            this.set('content', content);

            this.set('isConfirm', false);

            this.set('confirmationPopupOpen', true);
            this.set('OKPending', false);
            this.set('cancelPending', false);

            //Création d'une promise qui sera resolved/rejected selon le retour de l'utilisateur
            let deferred = Ember.RSVP.defer();
            deferred.promise.finally(() => {

                if (this.get('options.autoClose')) {
                    this.set('confirmationPopupOpen', false);
                }
            });
            this.set('deferred', deferred);
            return deferred.promise;
        },

        resolvePromise: function () {
            this.get('deferred').resolve();
        },

        rejectPromise: function () {
            this.get('deferred').reject();
        },

        close: function () {
            this.set('confirmationPopupOpen', false);
        },

        setOKPending: function (pending = true) {
            this.set('OKPending', pending);
        },

        setCancelPending: function (pending = true) {
            this.set('cancelPending', pending);
        }

    });
});