define('ceag-lot3-front/routes/in/utilisateurs/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {

            let utilisateur;
            if (params.utilisateur_id !== 'new') {
                utilisateur = this.get('store').findRecord('utilisateur', params.utilisateur_id);
            } else {
                utilisateur = this.get('store').createRecord('utilisateur');
            }

            return Ember.RSVP.hash({
                utilisateur: utilisateur,
                abonnements: this.modelFor('in').abonnements.all
            }).then(hash => {
                //On garde une trace des droits initiaux pour reset si sortie de l'édition via annulé
                hash['utilisateurDroits'] = hash.utilisateur.get('abonnements_pro').toArray();
                return hash;
            });
        }
    });
});