define('ceag-lot3-front/models/objet-formulaire', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend({

        nom: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),

        is_constituant_filigranne: _emberData.default.attr('boolean'),
        is_obligatoire: _emberData.default.attr('boolean'),

        niveau: _emberData.default.attr('number'),
        ordre: _emberData.default.attr('number'),

        proprietes: _emberData.default.attr('ceag-object-props'),
        width: _emberData.default.attr('number'),
        height: _emberData.default.attr('number'),

        rubrique: _emberData.default.belongsTo('rubrique'),

        // page : DS.belongsTo('page-formulaire'),
        super_calque: _emberData.default.belongsTo('super-calque-formulaire'),
        calque: _emberData.default.belongsTo('calque-formulaire')
    });
});