define('ceag-lot3-front/serializers/online/super-calque-formulaire', ['exports', 'ceag-lot3-front/serializers/online/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            objets: { embedded: 'always' },
            calques: { embedded: 'always' }
        }
    });
});