define('ceag-lot3-front/models/questionnaire', ['exports', 'ember-data', 'ceag-lot3-front/mixins/computed-last-version', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _computedLastVersion, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend(_computedLastVersion.default, {

        code: _emberData.default.attr('string'),
        libelle: _emberData.default.attr('string'),

        versions: _emberData.default.hasMany('questionnaire-version')
    });
});