define('ceag-lot3-front/routes/reset', ['exports', 'ceag-lot3-front/misc/reset-password', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _resetPassword, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

        model: function (params) {
            return _resetPassword.default.create(Ember.getOwner(this).ownerInjection(), //http://offirgolan.github.io/ember-cp-validations/docs/modules/Basic.html#a-note-on-testing--object-containers
            {
                token: params.token,
                mail: params.mail
            });
        }
    });
});