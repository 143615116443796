define('ceag-lot3-front/models/utilisateur', ['exports', 'ember-data', 'ceag-lot3-front/models/validations/utilisateur', 'offline-store-sync/offline-model/offline-model'], function (exports, _emberData, _utilisateur, _offlineModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineModel.default.extend(_utilisateur.default, {

        mail: _emberData.default.attr('string'),
        nom: _emberData.default.attr('string'),
        prenom: _emberData.default.attr('string'),
        trigramme: _emberData.default.attr('string'),
        mdp: _emberData.default.attr('string'),

        role: _emberData.default.attr('string'),
        isAdmin: Ember.computed.equal('role', 'ROLE_ADMIN_PROFESSIONNEL'),

        infos_prestashop: _emberData.default.belongsTo('utilisateur-prestashop-informations'),

        abonnements: _emberData.default.hasMany('abonnement', { inverse: 'utilisateur' }),

        //Ne pas activer le "inverse", voir hasMany côté abonnement
        abonnements_pro: _emberData.default.hasMany('abonnement' /*, {inverse : 'utilisateurs_pro'}*/),

        mdp_conf: '', //Pas une donnée échangée avec l'API

        nom_prenom: Ember.computed('nom', 'prenom', function () {
            return `${this.get('prenom')} ${this.get('nom')}`;
        }),

        libelle: Ember.computed('trigramme', 'nom_prenom', function () {
            return `${this.get('trigramme')} - ${this.get('nom_prenom')}`;
        })
    });
});