define('ceag-lot3-front/components/formulaire/saisie/objets/i-navigable-objet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        keyPress(e) {

            let code = e.keyCode || e.which;
            if (code === 13) {
                e.preventDefault();
                this.nextInput();
                return false;
            }
        },

        nextInput: function () {
            //Mode bourrin, on va focus le champs suivant

            //Le form courant
            let currentForm = this.$('form')[0];

            //La liste des formulaire (chaque form représente un element de saisie)
            let forms = this.$().closest('form').find('form');
            for (let i = 0; i < forms.length; i++) {
                if (forms[i] == currentForm) {
                    if (i < forms.length - 1) {
                        forms[i + 1][0].focus();

                        //Sur mobile, vrais champs date, pour que le "picker" natif soit afficher on simule un click
                        if (forms[i + 1][0].type == "date") {
                            forms[i + 1][0].click();
                        }
                        //Idem pour select
                        else if (forms[i + 1][0].nodeName == 'SELECT') {
                                var event = document.createEvent('MouseEvents');
                                event.initMouseEvent('mousedown', true, true, window);
                                forms[i + 1][0].dispatchEvent(event);
                            }
                    } else {
                        //Btn next
                        this.$().closest('form').submit();
                    }
                }
            }
        },

        actions: {

            onSubmit: function () {
                this.nextInput();
            }
        }

    });
});