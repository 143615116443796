define('ceag-lot3-front/models/saisie-co-contractant', ['exports', 'ember-data', 'offline-store-sync/offline-model/offline-editable-model', 'ceag-lot3-front/config/ceag-config-lot2'], function (exports, _emberData, _offlineEditableModel, _ceagConfigLot) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _offlineEditableModel.default.extend({

        saisie_formulaire: _emberData.default.belongsTo('saisie-formulaire'),

        calque_formulaire: _emberData.default.belongsTo('calque-formulaire'),

        envoi_non_m_o_d: _emberData.default.attr('boolean', { defaultValue: false }),
        envoi_m_o_d: _emberData.default.attr('boolean', { defaultValue: false }),

        mail: _emberData.default.attr('string'),
        civilite: _emberData.default.attr('string'),
        nom: _emberData.default.attr('string'),
        prenom: _emberData.default.attr('string'),
        societe: _emberData.default.attr('string'),
        siret: _emberData.default.attr('string'),
        adresse1: _emberData.default.attr('string'),
        adresse2: _emberData.default.attr('string'),
        adresse3: _emberData.default.attr('string'),
        adresse4: _emberData.default.attr('string'),
        code_postal: _emberData.default.attr('string'),
        ville: _emberData.default.attr('string'),

        // ajout d'un champs contenant le libellé du calque parent, util pour l'ordre des sous contractants
        libelle_calque: _emberData.default.attr('string'),

        numero_client: _emberData.default.attr('string'),
        telephone1: _emberData.default.attr('string'),
        telephone2: _emberData.default.attr('string'),

        libelleClient: Ember.computed('civilite', 'nom', 'prenom', function () {
            if (Ember.isEmpty(this.get('civilite')) && Ember.isEmpty(this.get('nom')) && Ember.isEmpty(this.get('prenom'))) {
                return '';
            }
            let lib = Ember.isEmpty(this.get('civilite')) ? '' : this.get('civilite');
            lib += Ember.isEmpty(this.get('nom')) ? '' : ' ' + this.get('nom');
            lib += Ember.isEmpty(this.get('prenom')) ? '' : ' ' + this.get('prenom');
            return lib;
        }),

        libelleCCFull: Ember.computed('libelleClient', 'adresse', function () {
            let full = '';
            full += this.get('libelleClient');
            full += this.get('adresse');
            return Ember.String.htmlSafe(full);
        }),

        adresse: Ember.computed('adresse1', 'adresse2', 'adresse3', 'adresse4', 'code_postal', 'ville', function () {
            let adr = '';
            adr += Ember.isEmpty(this.get('adresse1')) ? '' : this.get('adresse1');
            adr += Ember.isEmpty(this.get('adresse2')) ? '' : '<br>' + this.get('adresse2');
            adr += Ember.isEmpty(this.get('adresse3')) ? '' : '<br>' + this.get('adresse3');
            adr += Ember.isEmpty(this.get('adresse4')) ? '' : '<br>' + this.get('adresse4');

            if (!Ember.isEmpty(this.get('code_postal')) || !Ember.isEmpty(this.get('ville'))) {
                adr += '<br>';
                adr += Ember.isEmpty(this.get('code_postal')) ? '' : this.get('code_postal');
                adr += Ember.isEmpty(this.get('ville')) ? '' : this.get('ville');
            }

            return Ember.String.htmlSafe(adr);
        }),

        index_calque: Ember.computed('libelle_calque', function () {

            // Par défaut la valeur est 99
            let value = 99;
            if (!Ember.isNone(this.get('libelle_calque'))) {
                let newValue = parseInt(this.get('libelle_calque').substr(_ceagConfigLot.default.nomCalqueCoContractant.length));
                value = !isNaN(newValue) ? newValue : value;
            }
            return value;
        }),

        /**
         * Consturction de la vignette libellé + adresse client
         */
        vignette_client: Ember.computed('libelleClient', 'adresse', function () {

            let vignette = '';
            let libelle = this.get('libelleClient');
            let adresse = this.get('adresse');

            if (Ember.isEmpty(libelle) && (Ember.isEmpty(adresse) || Ember.isEmpty(adresse.String))) {
                vignette = 'Non renseigné';
            } else {
                vignette += Ember.isEmpty(libelle) ? '' : libelle;
                vignette += Ember.isEmpty(adresse) ? '' : '<br>' + adresse;
            }

            return Ember.String.htmlSafe(vignette);
        })

    });
});