define('ceag-lot3-front/routes/in/statistiques', ['exports', 'ceag-lot3-front/misc/admin-route', 'ceag-lot3-front/mixins/online-route'], function (exports, _adminRoute, _onlineRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adminRoute.default.extend(_onlineRoute.default, {

        rolesAllowed: ['ROLE_PARTICULIER', 'ROLE_ADMIN_PROFESSIONNEL'],

        model: function (params) {
            let query = {};
            let goSearch = false;

            if (!Ember.isEmpty(params.debut) && !Ember.isEmpty(params.fin)) {
                query['from'] = parseInt(params.debut.getTime() / 1000);
                query['to'] = parseInt(params.fin.getTime() / 1000);
                goSearch = true;
            }

            let resultatStats = null;
            if (goSearch) {
                let adapter = this.get('store').adapterFor('application');
                let apiCallOptions = {
                    method: 'GET',
                    data: query
                };
                resultatStats = adapter.apiCall('statistiques', apiCallOptions);
            }

            return Ember.RSVP.hash({
                statistiques: resultatStats,
                goSearch: goSearch,
                abonnements: this.modelFor('in').abonnements.all,
                utilisateurs: this.modelFor('in').utilisateurs

            });
        },

        actions: {
            refreshSearch: function () {
                this.refresh();
            }
        }

    });
});