define('ceag-lot3-front/serializers/offline/abonnement', ['exports', 'ceag-lot3-front/serializers/offline/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        // attrs: {
        //         formulaire: {
        //             serialize: false,
        //             deserialize: false,
        //         },
        //         processus: {
        //             serialize: false,
        //             deserialize: false,
        //         },
        //         utilisateur: {
        //             serialize: false,
        //             deserialize: false,
        //         },
        // }
    });
});