define('ceag-lot3-front/serializers/online/mixins/serialize-id', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        serialize(snapshot, options) {
            let json = this._super(snapshot, options);

            var id = snapshot.id;
            if (id) {
                json[Ember.get(this, 'primaryKey')] = id;
            }

            return json;
        }

    });
});