define('ceag-lot3-front/routes/in/formulaire/saisie/signature', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        saisie: Ember.inject.service(),
        intl: Ember.inject.service(),

        platform: Ember.inject.service('ember-cordova/platform'),

        // activate: function(){
        //     if( this.get('platform.isCordova') ){
        //         window.screen.orientation.lock('landscape');
        //     }
        // },
        //
        // deactivate: function(){
        //     if( this.get('platform.isCordova') ){
        //         window.screen.orientation.unlock();
        //     }
        // },

        // beforeModel() {
        //     this._super(...arguments);
        //
        //     if( this.modelFor('in.formulaire.saisie').saisieFormulaire.get('saisies_co_contractant.length') == 0 ){
        //         alert('Aucun co-contractant pour ce formulaire !');
        //         this.transitionTo('in.formulaire.saisie.envoi');
        //     }
        // },
        //
        actions: {
            willTransition: function (transition) {
                // if( transition.targetName !== 'in.formulaire.saisie.signature'){

                const controller = this.controllerFor(this.routeName);

                const hasDirtyAttributes = item => item.get('hasDirtyAttributes') == true;
                let signatures = controller.get('signaturesAll').filter(hasDirtyAttributes);

                if (!Ember.isEmpty(signatures)) {
                    transition.abort();
                    let msg = this.get('intl').t('saisie.unsaved.contenu');
                    let opt = {
                        okLabel: this.get('intl').t('saisie.unsaved.ok'),
                        okType: 'success',
                        cancelLabel: this.get('intl').t('saisie.unsaved.cancel'),
                        cancelType: 'danger',
                        autoClose: false //On gère manuellement un état pending sur notre popup de conf
                    };

                    // merci : https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
                    const asyncForEach = async function (array, callback) {
                        for (let index = 0; index < array.length; index++) {
                            await callback(array[index], index, array);
                        }
                    };

                    this.get('confirmationService').confirm(this.get('intl').t('saisie.unsaved.titre'), msg, opt).then(() => {

                        const start = async () => {
                            this.get('confirmationService').setOKPending(true);
                            await asyncForEach(signatures, async signature => {
                                await signature.save();
                                // on doit récupérer le content de la promise pour avoir le model (soupir!)
                                await signature.get('saisie_formulaire.content').save();
                            });

                            this.get('confirmationService').close();
                            transition.retry();
                        };

                        start();
                    }, () => {
                        //Rollback
                        signatures.forEach(signature => {
                            signature.rollbackAttributes();
                        });

                        this.get('confirmationService').close();
                        transition.retry();
                    });
                }
            }
        },

        model: function () {

            let saisieFormulaire = this.modelFor('in.formulaire.saisie').saisieFormulaire;

            return Ember.RSVP.hash({
                saisies_co_contractant: saisieFormulaire.get('saisies_co_contractant'),
                //On va chercher une "fois pour toutes" les objets de saisies i_signatures dans l'ordre de la saisie
                saisies_objet_formulaire_signature: this.get('saisie').getSaisiesObjetSignature(saisieFormulaire)
            });
        },

        setupController(controller, model) {
            this._super(controller, model);
            this.controllerFor(this.routeName).updateSignatureData();
        }
    });
});