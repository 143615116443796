define('ceag-lot3-front/helpers/eq-int', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const eq = params => parseInt(params[0]) === parseInt(params[1]);
  exports.default = Ember.Helper.helper(eq);
});