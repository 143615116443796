define('ceag-lot3-front/components/formulaire/saisie/objets/i-text-objet', ['exports', 'ceag-lot3-front/components/formulaire/saisie/objets/i-navigable-objet'], function (exports, _iNavigableObjet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _iNavigableObjet.default.extend({

        onUpdate: function () {}

    });
});