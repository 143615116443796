define('ceag-lot3-front/components/formulaire/saisie/objets/i-signature-objet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        signatureService: Ember.inject.service('signature'),

        actions: {

            showSignaturePopup: function () {
                this.get('signatureService').openForObjet(this.get('objet'));
            }

        }

    });
});