define('ceag-lot3-front/initializers/message-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  /**
   * Inject le service de message dans les routes, controller et composant de l'application
   * @author jmestres
   */

  function initialize(application) {
    application.inject('route', 'messageService', 'service:message');
    application.inject('controller', 'messageService', 'service:message');
    application.inject('component', 'messageService', 'service:message');
  }

  exports.default = {
    name: 'message-service',
    initialize: initialize
  };
});